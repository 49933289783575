import {apolloClient } from '@/apollo/apolloClient'
import { singleLanguageFormatter } from '@/store/formatters'
import { EDIT_LANGUAGE, CREATE_LANGUAGE } from '@/apollo/user/language/user.language.mutations'
export default {
    async deleteLanguage(state, id){
        try {
            await apolloClient.mutate({
                mutation: EDIT_LANGUAGE,
                variables: { input: { id, delete:true }}
            }) 
            state.commit("removeLanguageById", id)
        } catch (e) {
            console.error(e)
        }
    },

    async saveLanguages(state){
        const languages = state.getters.getUserLanguages
        for(let i = 0; i < languages.length; i++){
            let lang = languages[i]
            
            if(!lang.language) continue
            if(lang.id === undefined){
                state.dispatch("createLanguage", lang)
            } else {
                state.dispatch("editLanguage", lang)
            }
        }
    },

    async createLanguage(state, params){
        try {
            const res = await apolloClient.mutate({
                mutation: CREATE_LANGUAGE,
                variables: { input: {...params, profileId:state.getters.getId}}
            })
            state.commit("updateLanguageByName", singleLanguageFormatter(res.data.profileLanguageCreateMutation.language))
        } catch (e) {
            console.error(e)
        }
    },

    async editLanguage(state, params){
        try {
            const res = await apolloClient.mutate({
                mutation: EDIT_LANGUAGE,
                variables: { input: params}
            }) 
            state.commit("updateLanguageByName", singleLanguageFormatter(res.data.profileLanguageEditMutation.language))
        } catch (e) {
            console.error(e)
        } 
    }

}
