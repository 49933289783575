export default {
    setUserPrimaryJob(state, data){
        state.primaryJob = data
    },
    setUserSecondaryJobs(state, data){
        state.secondaryJobs = data
    },
    addUserSecondaryJob(state, job){
        state.secondaryJobs.push(job)
    },
    removeUserSecondaryJobByTitle(state, title){
        state.secondaryJobs = state.secondaryJobs.filter(el => el.title !== title)
    },
    refreshUserSecondaryJobPrimaries(state, ids){
        for(let i = 0; i < state.secondaryJobs.length; i++){
            let job = state.secondaryJobs[i]
            if(ids.includes(job.id)){
                job.isPrimary = true;
            } else {
                job.isPrimary = false;
            }
        }
    }    
}
