import { createRouter, createWebHistory } from 'vue-router'
import {store} from "@/store";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: { fullWidth: true},
    scrollBehavior() {
      window.scrollTo(0,0);
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    meta: { fullWidth: true}
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: { fullWidth: true}
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue'),
    meta: { fullWidth: true}
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "forgotpassword" */ '../views/ForgotPassword.vue'),
    meta: { fullWidth: true}
  },
  {
  path: '/send_reset/reset/:token',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "resetpassword" */ '../views/ResetPassword.vue'),
    meta: { fullWidth: true}
  },
  {
  path: '/send_activation/activate/:token',
    name: 'EmailVerification',
    component: () => import(/* webpackChunkName: "emailverification" */ '../views/EmailVerification.vue'),
    meta: { fullWidth: true}
  },
  {
    path: '/people',
    name: 'People',
    component: () => import(/* webpackChunkName: "people" */ '../views/People.vue'),
    meta: { fullWidth: true}
  },
  {
    path: '/places',
    name: 'Places',
    //component: () => import(/* webpackChunkName: "places" */ '../views/Places.vue'),
    component: () => import(/* webpackChunkName: "places" */ '../views/Places.vue'),
    meta: { fullWidth: true }
  },
  {
    path: '/placesPreview',
    name: 'Places Preview',
    component: () => import(/* webpackChunkName: "places" */ '../views/Places.vue'),
    meta: { fullWidth: true }
  },
  {
    path: '/spaces',
    name: 'Spaces',
    component: () => import(/* webpackChunkName: "Spaces" */ '../views/Spaces.vue'),
    meta: { fullWidth: true}
  },
  {
    path: '/settings/users/:id',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "places" */ '../components/profile/components/profileSettings/Users'),
    meta: { fullWidth: true},
    beforeEnter: (to, from, next) => {
      store.dispatch('fetchProfile', to.params.id)
       next()
    },
  },
  {
    path: '/settings/privacy/:id',
    name: 'SettingPrivacy',
    component: () => import(/* webpackChunkName: "places" */ '../components/profile/components/profileSettings/Privacy'),
    meta: { fullWidth: true},
    beforeEnter: (to, from, next) => {
      store.dispatch('fetchProfile', to.params.id)
       next()
    },
  },
  {
    path: '/settings/notification/:id',
    name: 'SettingNotification',
    component: () => import(/* webpackChunkName: "places" */ '../components/profile/components/profileSettings/Notifications'),
    meta: { fullWidth: true},
    beforeEnter: (to, from, next) => {
      store.dispatch('fetchProfile', to.params.id)
       next()
    },
  },
  {
    path: '/settings/security/:id',
    name: 'SettingSecurity',
    component: () => import(/* webpackChunkName: "places" */ '../components/profile/components/profileSettings/SecurityLogin'),
    meta: { fullWidth: true},
    beforeEnter: (to, from, next) => {
      store.dispatch('fetchProfile', to.params.id)
       next()
    },
  },
  {
    path: '/settings/subscription/:id',
    name: 'SettingSubscription',
    component: () => import(/* webpackChunkName: "places" */ '../components/profile/components/profileSettings/Subscription'),
    meta: { fullWidth: true},
    beforeEnter: (to, from, next) => {
      store.dispatch('fetchProfile', to.params.id)
       next()
    },
  },
  {
    path: '/settings/regional/:id',
    name: 'SettingRegional',
    component: () => import(/* webpackChunkName: "places" */ '../components/profile/components/profileSettings/RegionalSettings'),
    meta: { fullWidth: true},
    beforeEnter: (to, from, next) => {
      store.dispatch('fetchProfile', to.params.id)
       next()
    },
  },
  {
    path: '/settings/Navigation/:id',
    name: 'SettingNavigation',
    component: () => import(/* webpackChunkName: "places" */ '../components/profile/components/profileSettings/Navigation'),
    meta: { fullWidth: true},
    beforeEnter: (to, from, next) => {
      store.dispatch('fetchProfile', to.params.id)
       next()
    },
  },
  {
    path: '/venue/:id',
    name: 'Venue',
    component: () => import(/* webpackChunkName: "venue" */ '../views/Venue.vue'),
    // beforeEnter: (to, from, next) => {
    //   store.dispatch('getVenue', to.params.id)
    //   next()
    // },
    meta: { fullWidth: true}
  },
  {
    path: '/thing/:id',
    name: 'Thing',
    component: () => import(/* webpackChunkName: "thing" */ '../views/Thing.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('fetchThing', to.params.id)
      next()
    },
    meta: { fullWidth: true, hasTopBar: false }
  },
  {
    path: '/editThing/:id',
    name:'EditThing',
    component: () => import(/* webpackChunkName: "editThing" */ '../views/EditThing'),
    beforeEnter: (to, from, next) => {
      if(!store.getters.isLoggedIn) {
        router.push({name: 'Things'})
      } else {
        next()
      }
    },
    meta: { fullWidth: true}
  },
  {
    path: '/testCatTable',
    name: '',
    component: () => import(/* webpackChunkName: "thing" */ '../components/widgets/categoryTable/ExampleCategoryTableController.vue'),
    meta: { fullWidth: true, hasTopBar: false }
  },
  {
    path: '/things/:thingPath*',
    name: 'Things',
    component: () => import(/* webpackChunkName: "places" */ '../views/CommingSoon.vue'),
    //component: () => import(/* webpackChunkName: "things" */ '../views/Things'),
    meta: { fullWidth: true, hasTopBar: false }
  },

  {
    path: '/thingsPreview/:thingPath*',
    name: 'Things Preview',
    component: () => import(/* webpackChunkName: "things" */ '../views/Things'),
    meta: { fullWidth: true, hasTopBar: false }
  },
  {
    path: '/companies',
    name: 'Companies',
    component: () => import(/* webpackChunkName: "companies" */ '../views/Companies.vue'),
    meta: { fullWidth: true},
  },
  {
    path: '/company/:id',
    name: 'Company',
    component: () => import(/* webpackChunkName: "company" */ '../views/Company.vue'),
    meta: { fullWidth: true}
  },
  {
    path: '/addCompany',
    name:'AddCompany',
    component: () => import(/* webpackChunkName: "addCompany" */ '../views/AddCompany'),
    beforeEnter: (to, from, next) => {
      if(!store.getters.isLoggedIn) {
        router.push({ name: 'Companies'})
      } else {
        store.commit('resetCompany')
        store.dispatch('fetchCompanyTypes')
        store.dispatch('fetchCompanyLevels')
        next()
      }
    },
    meta: { fullWidth: true}
  },
  {
    path: '/editCompany/:id',
    name:'EditCompany',
    component: () => import(/* webpackChunkName: "editCompany" */ '../views/EditCompany'),
    beforeEnter: (to, from, next) => {
      if(!store.getters.isLoggedIn) {
        router.push({ name: 'Companies'})
      } else {
        store.commit('resetCompany')
        store.dispatch('fetchCompanyTypes')
        store.dispatch('fetchCompanyLevels')
        next()
      }
    },
    meta: { fullWidth: true}
  },

  {
    path: '/editVenue/:id',
    name:'EditVenue',
    component: () => import(/* webpackChunkName: "editVenue" */ '../views/EditVenue'),
    beforeEnter: (to, from, next) => {
      if(!store.getters.isLoggedIn) {
        router.push({name: 'Places'})
      } else {
        store.commit('resetVenue')
        store.dispatch('getVenueTypes')
        next()
      }
    },
    meta: { fullWidth: true}
  },
  {
    path: '/editSeason/:id',
    name: 'EditSeason',
    component: () => import(/* webpackChunkName: "editSeason" */ '../views/EditSeason'),
    meta: { fullWidth: true}
  },
  {
    path: '/addSeason',
    name: 'AddSeason',
    component: () => import(/* webpackChunkName: "addSeason" */ '../views/AddSeason'),
    meta: { fullWidth: true}
  },
  {
    path: '/works',
    name: 'Works',
    component: () => import(/* webpackChunkName: "places" */ '../views/CommingSoon.vue'),
    //component: () => import(/* webpackChunkName: "works" */ '../views/Works'),
    meta: { fullWidth: true}
  },
  {
    path: '/productions',
    name: 'Productions',
    component: () => import(/* webpackChunkName: "places" */ '../views/CommingSoon.vue'),
    //component: () => import(/* webpackChunkName: "productions" */ '../views/Productions'),
    meta: { fullWidth: true}
  },
  {
    path: '/production/:id',
    name: 'Production',
    component: () => import(/* webpackChunkName: "production" */ '../views/Production'),
    meta: { fullWidth: true},
  },
  {
    path: '/addProduction',
    name: "AddProduction",
    component: () => import(/* webpackChunkName: "addproduction" */ '../views/AddProduction'),
    meta: { fullWidth: true}
  },
  {
    path: '/editProduction',
    name: 'EditProduction',
    component: () => import(/* webpackChunkName: "editproduction" */ '../views/EditProduction'),
    meta: { fullWidth: true}
  },
  {
    path: '/editProfile',
    name: 'EditProfile',
    component: () => import(/* webpackChunkName: "editProfile" */ '../views/EditProfile'),
    beforeEnter: (to, from, next) => {
      store.dispatch('Authenticate')
      store.dispatch('fetchProfile', to.params.id)
      store.dispatch('fetchProfileFilters');
      store.dispatch('fetchPhysicalInfo')
      store.dispatch('fetchDiversityOptions');
      store.dispatch('fetchUserDiversity');
      next()
    },
    meta: { fullWidth: true}
  },
  {
    path: '/addPlace',
    name: 'AddPlace',
    component: () => import(/* webpackChunkName: "addPlace" */ '../views/AddPlace.vue'),
    beforeEnter: (to, from, next) => {
      if(!store.getters.isLoggedIn) {
        router.push({ name: 'Places'})
      } else {
        store.commit('resetVenue')
        store.dispatch('getVenueTypes')
        next()
      }
    },
    meta: { fullWidth: true}
  },
  {
    path: '/addSpace',
    name: 'AddSpace',
    component: () => import(/* webpackChunkName: "addSpace" */ '../views/AddSpace.vue'),
    beforeEnter: (to, from, next) => {
      if(!store.getters.isLoggedIn) {
        router.push({ name: 'Spaces'})
      } else {
        store.commit('resetSpace')
        store.dispatch('getSpaceTypes')
        next()
      }
    },
    meta: { fullWidth: true}
  },
  {
    path: '/addStorage',
    name: 'AddStorage',
    component: () => import(/* webpackChunkName: "addSpace" */ '../views/AddStorage.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        router.push({ name: 'Spaces' })
      } else {
        store.commit('resetStorage')
        // store.dispatch('getStorageTypes')
        next()
      }
    },
    meta: { fullWidth: true }
  },
  {
    path: '/addThing',
    name: 'AddThing',
    component: () => import(/* webpackChunkName: "addSpace" */ '../views/AddThing.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        router.push({ name: 'Things' })
      } else {
        next()
      }
    },
    meta: { fullWidth: true, hasTopBar: true }
  },

  {
    path: '/profile/:id',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile'),
    meta: { fullWidth: true}

  },
  {
    path: '/profile/contact/:id',
    name: 'editProfileContact',
    component: () => import(/* webpackChunkName: "profile" */ '../components/profile/edit/pages/General'),
    beforeEnter: (to, from, next) => {
      store.dispatch('fetchProfile', to.params.id)
      store.dispatch("fetchPhysicalInfo");
      store.dispatch("fetchUserProgrammeBiog")

      next()
    },
    meta: { fullWidth: true}

  },
  {
    path: '/profile/skills/:id',
    name: 'editProfileSkill',
    component: () => import(/* webpackChunkName: "profile" */ '../components/profile/edit/pages/Skills'),
    meta: { fullWidth: true},
    beforeEnter: (to, from, next) => {
      store.dispatch('fetchProfile', to.params.id)
      store.dispatch("fetchPhysicalInfo");
      store.dispatch("fetchUserProgrammeBiog")

      next()
    },

  },
  {
    path: '/profile/physical/:id',
    name: 'editProfilePhysical',
    component: () => import(/* webpackChunkName: "profile" */ '../components/profile/edit/pages/Physical'),
    meta: { fullWidth: true},
    beforeEnter: (to, from, next) => {
      store.dispatch('fetchProfile', to.params.id)
      store.dispatch("fetchPhysicalInfo");
      store.dispatch("fetchUserProgrammeBiog")
      next()
    },
   },

  {
    path: '/profile/diversity/:id',
    name: 'editProfileDiversity',
    component: () => import(/* webpackChunkName: "profile" */ '../components/profile/edit/pages/Diversity'),
    meta: { fullWidth: true},
    beforeEnter: (to, from, next) => {
      store.dispatch('fetchProfile', to.params.id)
      store.dispatch("fetchPhysicalInfo");
      store.dispatch("fetchUserProgrammeBiog")
      next()
    },
   },
  {
    path: '/profile/medical/:id',
    name: 'editProfileMedical',
    component: () => import(/* webpackChunkName: "profile" */ '../components/profile/edit/pages/Medical'),
    meta: { fullWidth: true},
    beforeEnter: (to, from, next) => {
      store.dispatch('fetchProfile', to.params.id)
      store.dispatch("fetchPhysicalInfo");
      store.dispatch("fetchUserProgrammeBiog")
      next()
    },
   },
  {
    path: '/profile/travel/:id',
    name: 'editProfileTravel',
    component: () => import(/* webpackChunkName: "profile" */ '../components/profile/edit/pages/Travel'),
    meta: { fullWidth: true},
    beforeEnter: (to, from, next) => {
      store.dispatch('fetchProfile', to.params.id)
      store.dispatch("fetchPhysicalInfo");
      store.dispatch("fetchUserProgrammeBiog")
      next()
    },
    },
  {
    path: '/profile/programme/:id',
    name: 'editProfileProgramme',
    component: () => import(/* webpackChunkName: "profile" */ '../components/profile/edit/pages/ProgrammeBio'),
    meta: { fullWidth: true},
    beforeEnter: (to, from, next) => {
      store.dispatch('fetchProfile', to.params.id)
      store.dispatch("fetchPhysicalInfo");
      store.dispatch("fetchUserProgrammeBiog")
      next()
    },
    },
  {
    path: '/profile/awards/:id',
    name: 'editProfileAwards',
    component: () => import(/* webpackChunkName: "profile" */ '../components/profile/edit/pages/Awards'),
    meta: { fullWidth: true},
    beforeEnter: (to, from, next) => {
      store.dispatch('fetchProfile', to.params.id)
      store.dispatch("fetchPhysicalInfo");
      store.dispatch("fetchUserProgrammeBiog")
      next()
    },
   },
   {
    path: '/profile/agents/:id',
    name: 'editAgents',
    component: () => import(/* webpackChunkName: "profile" */ '../components/profile/edit/pages/Agents'),
    meta: { fullWidth: true},
     },
     {
      path: '/profile/work-education/:id',
      name: 'editWorkEducation',
      component: () => import(/* webpackChunkName: "profile" */ '../components/profile/edit/pages/WorkEducation'),
      meta: { fullWidth: true},
      beforeEnter: (to, from, next) => {
        store.dispatch('fetchProfile', to.params.id)
         next()
      },
       },
   {
    path: '/profile/measurements/:id',
    name: 'editMeasurements',
    component: () => import(/* webpackChunkName: "profile" */ '../components/profile/edit/pages/Measurements'),
    meta: { fullWidth: true},
     },
   {
    path: '/create-album/',
    name: 'createAlbums',
    component: () => import(/* webpackChunkName: "profile" */ '../components/profile/edit/pages/Albums'),
    meta: { fullWidth: true},
   
   },
   {
    path: '/edit-album/:id',
    name: 'editAlbums',
    component: () => import(/* webpackChunkName: "profile" */ '../components/profile/edit/pages/editAlbums'),
    meta: { fullWidth: true},
   
   },
  {
    path: '/seasons/:id',
    name: 'Seasons',
    component: () => import(/* webpackChunkName: "seasons" */ '../views/Seasons'),
    meta: { fullWidth: true}
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { fullWidth: true}
  },

  {
    path: '/pricing',
    name: 'Pricing',
    component: () => import(/* webpackChunkName: "pricing" */ '../views/Pricing.vue'),
    meta: { fullWidth: true}
  },

  {
    path: '/blog',
    name: 'Blog',
    component: () => import(/* webpackChunkName: "home" */ '../views/Blog.vue'),
    meta: { fullWidth: true}
  },
  {
    path: '/error-404',
    name: 'error-404',
    meta: { fullWidth: true },
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
  },
  {
    path: '/:any',
    redirect: 'error-404',
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
}
})

export default router
