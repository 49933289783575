import localStorage from 'store2'

export default {
    setLoading (state, data) {
        state.loading = data
    },
    setCountries(state, data) {
        state.countries = data
    },
    setSocialTypes(state, data) {
        state.socialTypes = data
    },
    setDarkMode(state, data) {
        state.darkMode = data
        localStorage.set("darkMode", data)
    },
    setDocumentTypes(state, data) {
        state.documentTypes = data
    },
    setDepartmentTypes(state, data) {
        state.departmentTypes = data
    },
    setProfileJobs(state, data){
        state.profileJobs = data
    },
    setProfileSkills(state, data){
        state.profileSkills = data
    },
    setDiversityOptions(state, data){
        state.sexes = data.sexes
        state.ethnicities = data.ethnicities
        state.genders = data.genders
        state.religions = data.religions
        state.sexualities = data.sexualities
        state.disabilities = data.disabilities
        state.caringResponsibilities = data.caringResponsibilities
    },
    setSearchBoxState(state){
        state.searchBox = !state.searchBox
    },
    setShouldOpenSearchBox(state, data){
        state.shouldOpenSearchBox = data
    }
}
