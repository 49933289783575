<template>
    <div class="w-screen h-screen bg-white flex justify-center items-center dark:bg-primaryDark">
        <LoadingSpinner />
    </div>
</template>

<script>
import LoadingSpinner from '@/components/widgets/LoadingSpinner'
export default {
    name: "Loading",
    components: { LoadingSpinner }
  
}
</script>

