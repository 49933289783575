import { apolloClient } from '@/apollo/apolloClient'
import { EDIT_PROFILE_JOB, SET_SECONDARY_JOBS_PRIMARY, CREATE_PRIMARY_JOB, CREATE_SECONDARY_JOB } from '@/apollo/user/job/user.job.mutations'
import { jobFormatter, singleSecondaryJobFormatter } from "@/store/formatters";

export default {
    async saveUserPrimaryJob(state){
        const job = state.getters.getUserPrimaryJob
        if(!job.id) return state.dispatch('createPrimaryJob', job)
        await apolloClient.mutate({
            mutation: EDIT_PROFILE_JOB,
            variables: { input: {...job} }
        })
    },

    async editPrimaryJob(state, params){
        try {
            await apolloClient.mutate({
                mutation: EDIT_PROFILE_JOB,
                variables: { input: {...params}}
            })
        } catch (e) {
            console.error(e)
        }
    },

    async createPrimaryJob(state, job) {
        try {
            const res = await apolloClient.mutate({
                mutation: CREATE_PRIMARY_JOB,
                variables: { input: { ...job, id:state.getters.getId}}
            })

            state.commit("setUserPrimaryJob", jobFormatter(res.data.primaryJobCreate.job))
        } catch (e) {
            console.error(e)
        }
    },

  async createSecondaryJob(state, title){
      try {
          const res = await apolloClient.mutate({
              mutation: CREATE_SECONDARY_JOB,
              variables: { input: { title, profileId:state.getters.getId }}
          })
          state.commit("addUserSecondaryJob",singleSecondaryJobFormatter(res.data.secondaryJobCreate.job))
      } catch (e) {
          console.error(e)
      }
  },
    
  async deleteSecondaryJob(state, title){
      try {
          await apolloClient.mutate({
              mutation: CREATE_SECONDARY_JOB,
              variables: { input: { title, profileId:state.getters.getId, delete:true}}
          })
          state.commit('removeUserSecondaryJobByTitle', title)
      } catch (e) {
          console.error(e)
      }
  },

    async setSecondaryJobPrimary(state, jobIds){
        try {
            const res = await apolloClient.mutate({
                mutation: SET_SECONDARY_JOBS_PRIMARY,
                variables: { input: {profileId:state.getters.getId, jobIds} }
            })
            const jobs = res.data.secondaryJobSetPrimaryMutation.jobs
            if(jobs){
                const ids = jobs.map(j => singleSecondaryJobFormatter(j)).map(j => j.id)
                state.commit("refreshUserSecondaryJobPrimaries", ids)
            }
        } catch (e) {
            console.error(e)
        }
    }
}
