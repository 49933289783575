import gql from "graphql-tag";
import { RecursiveParentStorageFragment } from "../places/places.fragments"

export const FULL_CATEGORY_FRAGMENT = gql`
    fragment FULL_CATEGORY_FRAGMENT on ThingCategoryType {
        id
        label { 
            name
        }
        parent {
            id
        }
        children {
            edges {
                node {
                    id
                    parent {
                        id
                    }
                    label {
                        name
                    }
                }
            }
        }
    }
`

export const PROPERTY_CATEGORY_FRAGMENT = gql`
    fragment PROPERTY_CATEGORY_FRAGMENT on ThingCategoryType {
        allProperties
    }
`

export const REGULAR_THING_FRAGMENT = gql`
    fragment REGULAR_THING_FRAGMENT on ThingType {
        id
        name
        description
        status
        weight
        material
        value
        colour
        modificationsAllowed
        acquisition
        tags {
            edges {
                node {
                    label {
                        title
                    }
                }
            }
        }
    }
`

export const THING_SMALL_IMAGES = gql`
    fragment THING_SMALL_IMAGES on ThingType {
        medialibrary {
            images {
                edges {
                    node {
                        id
                        image(size: "255x255")
                    }
                }
            }
        }
    }
`

export const THING_BIG_IMAGES = gql`
    fragment THING_BIG_IMAGES on ThingType {
        medialibrary {
            images {
                edges {
                    node {
                        id
                        uuid
                        image(size: "1080x1080")
                    }
                }
            }
        }
    }
`

export const THING_OWNER_FRAGMENT = gql`
    fragment THING_OWNER_FRAGMENT on ThingType {
        owner {
            profile {
                id
                firstName
                middleName
                surname
            }
            company {
                id
                name
            }
        }
    }
`

export const THING_LOCATION_FRAGMENT = gql`
    fragment THING_LOCATION_FRAGMENT on ThingType {
        storage {
            id
            name
            space {
                id
                name
                venue {
                    id
                    name
                    country
                    city
                }
            }
            ...RecursiveParentStorageFragment
        }
    }

    ${RecursiveParentStorageFragment}
`

export const PROPERTIES_THING_FRAGMENT = gql`
    fragment PROPERTIES_THING_FRAGMENT on ThingType {
        properties
    }
`

export const CATEGORIES_THING_FRAGMENT = gql`
    fragment CATEGORIES_THING_FRAGMENT on ThingType {
        categories {
            edges {
                node {
                    label {
                        name
                    }
                    branch
                }
            }
        }
    }
`

