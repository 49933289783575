import {apolloClient} from "@/apollo/apolloClient"
import RemoveFalsy from '@/utils/RemoveFalsy'
import {EDIT_PROFILE_PRODUCTION, CREATE_PROFILE_PRODUCTION } from "@/apollo/user/profileProduction/user.profileProduction.mutations"
import { singleProfileProductionFormatter } from "@/store/formatters"
export default {
       async createProfileProduction(state, data){
            try{
                data = RemoveFalsy(data)
                const res = await apolloClient.mutate({
                        mutation: CREATE_PROFILE_PRODUCTION,
                        variables: { input: {...data, profileId:state.getters.getId }}
                   }) 
                state.commit("addUserProfileProduction",singleProfileProductionFormatter(res.data.profileProductionCreateMutation.production))
            } catch (e) {
                console.error(e)
            }
       },

       async editProfileProduction(state, prod){
            try {
                prod = RemoveFalsy(prod)
                const res = await apolloClient.mutate({
                    mutation: EDIT_PROFILE_PRODUCTION,
                    variables: { input: prod }
                })
                state.commit("replaceUserProfileProductionById", singleProfileProductionFormatter(res.data.profileProductionEditMutation.production))
            } catch (e) {
                console.error(e)
            }
       },

       async deleteProfileProduction(state, id){
            try {
                await apolloClient.mutate({
                    mutation: EDIT_PROFILE_PRODUCTION,
                    variables: { input: {id, delete:true}}
                })
                state.commit("deleteUserProfileProductionById", id)
            } catch (e) {
                console.error(e)
            }
       },

}
