export default {
    setPhoneNumbers(state, data){
        state.phoneNumbers = data
    },
    addNewPhoneNumber(state){
        state.phoneNumbers.push({label: '', phoneNumber: ''})
    },
    appendPhoneNumber(state, phone){
        state.phoneNumbers.push(phone)
    },
    removePhoneNumber(state, id){
        state.phoneNumbers = state.phoneNumbers.filter(number => number.id !== id)
    },
    editPhoneByIdx(state, {value, key, idx}){
        const phone = state.phoneNumbers[idx]
        phone[key] = value
    },
    removePhoneByIdx(state, idx) {
        state.phoneNumbers = state.phoneNumbers.filter((phone, i) => idx !== i)
    },
    refreshPhonePrimaries(state, primaryIds) {
        for(let i=0; i < state.phoneNumbers.length; i++) {
            let phone = state.phoneNumbers[i]
            if(primaryIds.includes(phone.id)){
                phone.isPrimary = true
            } else {
                phone.isPrimary = false
            }
        }
    },
    setAllPhonePrimaryToFalse(state){
        for (let i = 0; i < state.phoneNumbers.length; i++) {
            state.phoneNumbers[i].isPrimary = false;
        }
    }

}
