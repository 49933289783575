import basicState from './basic/index'
import emailState from './email/index'
import addressState from './address/index'
import jobState from './job/index'
import mediaState from './media/index'
import phoneState from './phone/index'
import websiteState from './website/index'
import socialsState from './social/index'
import physicalState from './physical/index'
import diversityState from './diversity/index'
import medicalState from './medical/index'
import skillState from './skill/index'
import profileProductionState from './profileProduction/index'
import educationState from './education/index'
import workExperienceState from './workExperience/index'
import languageState from './language/index'
import profileEditState from './profileEdit'
import programmeBiogState from './programmeBio'
import awardState from './award'

export default {
    modules: {
        basicState,
        emailState,
        addressState,
        jobState,
        mediaState,
        phoneState,
        websiteState,
        socialsState,
        physicalState,
        diversityState,
        medicalState,
        skillState,
        profileProductionState,
        educationState,
        workExperienceState,
        languageState,
        profileEditState,
        programmeBiogState,
        awardState
    },
}
