import gql from 'graphql-tag';


export const ReviewFragment = gql`
    fragment ReviewFragment on ReviewType {
        id
        author
        content
        link
        stars
    }
`

export const ProductionMemberFragment = gql`
    fragment ProductionMemberFragment on ProductionMemberType {
         id
         isPrimary
         department{
             name
         }
         
          profile{
            id
            firstName
            middleName
            surname
          }
          job{
            id
            title
          }
    }
`

export const ProductionFragment = gql`
    fragment ProductionFragment on ProductionType {
        id
		name
		productionType
		company
		companyType
		role
		director
		venue
		space
		startDate
		endDate       
    }
    
`
