 import { apolloClient } from '@/apollo/apolloClient'
import { GET_PEOPLE, GET_RANDOM_PEOPLE, GET_PROFILE_WORK_EXPERIENCE, GET_PROFILE, GET_PROFILE_FILTER_OPTIONS, GET_PROFILE_HEADER } from '@/apollo/entities/people/people.queries'
import { peopleFormatter, singleProfileFormatter, workExperienceFormatter } from "@/store/formatters";
 import {GET_PROFILE_EDUCATION} from "../../../apollo/entities/people/people.queries";
 import {educationFormatter} from "../../formatters";

export default {

    async fetchRandomPeople(state, amount) {
        const res = await apolloClient.query({
            query: GET_RANDOM_PEOPLE,
            variables: {
                first: amount,
                after: "",
                before: ""
            }
        })
        state.commit('setPeople', peopleFormatter(res.data.randProfiles.edges))
        return { ...res.data.randProfiles.pageInfo, totalCount: res.data.randProfiles.totalCount }
    },

    async getPeople(_, params) {
        const res = await apolloClient.query({
            query: GET_PEOPLE,
            variables: params
        })
        return { people: peopleFormatter(res.data.profiles.edges), pageInfo: res.data.profiles.pageInfo }
    },

    async fetchPeople(state, params) {
        let { people } = await state.dispatch("getPeople", params)
        state.commit('setPeople', people)
    },

    async fetchProfile(state, id){
        if(id === null) return;
        try {
            const res = await apolloClient.query({
                query: GET_PROFILE,
                fetchPolicy: 'no-cache',
                variables: {id:id}
            })
            state.commit('setProfile', singleProfileFormatter(res.data.profile))
        } catch (e) {
            console.error(e)
        }
    },

    async fetchProfileFilters(state){
        try{
            const res = await apolloClient.query({
                query: GET_PROFILE_FILTER_OPTIONS
            })
			console.log(res.data)
            state.commit('setPeopleFilters', res.data)
        } catch (e){
            console.error(e)
        }

    },
    async fetchProfileHeader(state, id){
        try {
            const res = await apolloClient.query({
                query: GET_PROFILE_HEADER,
                fetchPolicy: 'no-cache',
                variables: { id }
            })
            state.commit('setProfileHeader', res.data.profile.header)
        } catch (e){
            console.error(e)
        }
    },

    async fetchProfileWorkExperience(state, id){
        try {
            const res = await apolloClient.query({
                query: GET_PROFILE_WORK_EXPERIENCE,
                fetchPolicy: 'no-cache',
                variables: { id }
            })
            state.commit('setProfileWorkExperience', educationFormatter(res.data.profile.education))
        } catch (e){
            console.error(e)
        }
    },

    async fetchProfileEducation(state, id){
        try {
            const res = await apolloClient.query({
                query: GET_PROFILE_EDUCATION,
                fetchPolicy: 'no-cache',
                variables: { id }
            })
            state.commit('setProfileEducation', workExperienceFormatter(res.data.profile.profileWorkExperience.edges))
        } catch (e){
            console.error(e)
        }
    },

    async peoplePageNext(state){
        await state.commit('setNextPage')
        await state.dispatch('dynamicTableLoadPeople')
    },

    async peoplePagePrevious(state){
        await state.commit('setPreviousPage')
        await state.dispatch('dynamicTableLoadPeople')
    },
}

