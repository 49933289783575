<template>
    <div class="left-0 right-0 z-20">
        <div
            class="flex flex-row  sm:overflow-y-hidden justify-start sm:justify-center bg-white dark:bg-primaryDark border-t-2 dark:border-backgroundDark border-background text-gray-500 focus:text-gray-700 text-sm font-medium dark:text-primary overflow-y-scroll">
            <p class="w-min p-2 hover:text-gray-700 dark-hover:text-gray-300 cursor-pointer">Props</p>
            <p class="w-min p-2 hover:text-gray-700 dark-hover:text-gray-300 cursor-pointer">Costume</p>
            <p class="w-min p-2 hover:text-gray-700 dark-hover:text-gray-300 cursor-pointer">Stage</p>
            <p class="w-min p-2 hover:text-gray-700 dark-hover:text-gray-300 cursor-pointer">Lighting</p>
            <p class="w-min p-2 hover:text-gray-700 dark-hover:text-gray-300 cursor-pointer">Sound</p>
            <p class="w-min p-2 hover:text-gray-700 dark-hover:text-gray-300 cursor-pointer">Video</p>
        </div>
    </div>
</template>
