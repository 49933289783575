<template>
  <div class=" ">
    <div class="mb-4">
      <strong>What is Showdeck?</strong>
      <p>
        Showdeck will help you create, organise and preserve any information you
        might need in order to produce and preserve your live performance. Less
        confusion, less missed opportunities, less lost information and more
        effective work.
      </p>
    </div>
    <div class="mb-4">
      <strong>
        Who is it for?
      </strong>
      <p>
        If your work is in any way related to live performance, Showdeck is for
        you. Whether performers, directors, designers, managers, technicians,
        marketing, photographers, venue operators, freelance or casual staff
        etc., we aim to make your work simpler and easier.
      </p>
    </div>
    <div class="mb-4">
      <strong>
        How?
      </strong>
      <p>
        Showdeck gives you general and specific tools, similar to the services
        you may already be using to produce your work. By specialising in
        performance and keeping everything in one place we achieve automation
        and synergy. Like with most things, the more you put in the more you get
        out.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "OnboardStep1",
};
</script>
