<template>
<div class=" flex justify-center items-center">
  <div ref="spinner" 
        class="animate-spin rounded-full  border-b-4 border-gray-400"
        :class="[size === 'small' ? 'h-10 w-10' : 'h-28 w-28']"
        ></div>
</div>
</template>

<script>
export default {
    name: "LoadingSpinner",
    props: {
        size: {
            type: String,
            required: false
        }
    }
}
</script>

