import {apolloClient} from "@/apollo/apolloClient";
import {EDIT_USER_DIVERSITY} from "@/apollo/user/diversity/diversity.mutations";
import {diversityFormatter} from "@/store/formatters";
import {GET_USER_DIVERSITY} from "@/apollo/user/diversity/diversity.queries";

export default {
    async editUserDiversity(state, params){
        try {
            const res = await apolloClient.mutate({
                mutation: EDIT_USER_DIVERSITY,
                variables: { input: {...params, profileId: state.getters.getId} }
            })
            state.commit('setUserDiversity', diversityFormatter(res.data.profileDiversityEdit.diversity))
            state.commit("refreshUserAbout", params)
        } catch (e) {
            console.error(e)
        }
    },

    async fetchUserDiversity(state){
        try {
            const res = await apolloClient.query({ query: GET_USER_DIVERSITY})
            if(res.data.me && res.data.me.profile.diversity){
                state.commit('setUserDiversity', diversityFormatter(res.data.me.profile.diversity))
            }
        } catch (e) {
            console.error(e);
        }
    }
}
