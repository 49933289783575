import gql from "graphql-tag";

export const MedicalContactFragment = gql`
    fragment MedicalContactFragment on ProfileMedicalContactType {
        id
        relationship
        name
        phoneNumber
        country
    }
`
export const MedicalConditionFragment = gql`
    fragment MedicalConditionFragment on ProfileMedicalConditionType {
        id
        name
        severity
        medication 
        doseSize
        frequencyOfUse
    }
`