import gql from "graphql-tag";
import { PerformerInformationFragment } from "./user.physical.fragments";

export const EDIT_PHYSICAL = gql`
    mutation performerInfoEdit($input: PerformerInformationInput!) {
        performerInfoEdit(input: $input) {
            performerInfo{
            ...PerformerInformationFragment
            }
            height{
                value
            }
            weight{
                value
            }
            shoeSize{
                value
            }
        }
    }${PerformerInformationFragment}
`
