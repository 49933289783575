<template>
  <header
    class="bg-primary w-full z-10 h-20 md:h-auto dark:bg-primaryDark dark:text-white border-b-2 border-neutral-50">
    <Disclosure as="nav" class="" v-slot="{ open }">
   <div>
    <div class="px-7">
      <div class="flex justify-between items-center h-20">

        <div class="-ml-2  flex items-center lg:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 dark:text-white">
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <MenuIcon v-else @click="openClose = true" class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div class="flex-shrink-0 flex items-center">
          <!-- NOTE:  The line: 'md:pl-24' is needed below to center the logo
                        on screen sizes between 640px and 768px
            -->
          <div @click="goHome"
            class="text-3xl flex-1 lg:px-2 lg:pr-6 md:pl-24 font-bold text-gray-800 cursor-pointer dark:text-white border-none">
            <img class="w-40"
              :src="[darkModeOn ? 'https://s3.eu-west-1.amazonaws.com/showdeck.io/static/assets/img/Showdeck-logo-white.png' : 'https://s3.eu-west-1.amazonaws.com/showdeck.io/static/assets/img/Showdeck-logo-black.png']" />
          </div>
        </div>
        <div class="hidden flex-1 lg:flex lg:justify-start justify-evenly h-full main_header">
          <router-link v-for="item in navigation" v-bind:key="item.id" :to="{ path: item.router }" href="#"
            class="router-link text-gray-500 dark:text-white w-24 inline-flex justify-center items-center px-4 pt-1 h-full dark:text-primary">
            <p class=" text-sm font-semibold ">{{ item.name }}</p>
          </router-link>
        </div>

        <!-- <div class=" flex justify-end">
              <SearchIcon
                  @click="toggleSearchBox"
                  class="h-6 w-6 text-gray-400 cursor-pointer" />
          </div> -->

        <div class="flex items-center justify-end align-baseline">
          <div class=" flex justify-end">
            <SearchIcon @click="toggleSearchBox" class="h-6 w-6 text-gray-400 dark:text-white cursor-pointer" />
          </div>
          <div class="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
            <div v-if="!isLoggedIn" class="ml-10 space-x-4">
              <router-link to="/login" type="button"
                class="sign_btn relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-700 shadow-sm 0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 ">
                <LoginIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                <span>Sign in</span>
              </router-link>
              <router-link to="/register" type="button"
                class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <UserAddIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                <span>Sign up</span>
              </router-link>
            </div>
            <div v-else class="mr-10 space-x-4">
              <div class="inline-flex items-center space-x-4">
                <!--<BellIcon class="h-5 w-5 inline-block text-gray-400 cursor-pointer"/> -->
                <UserDropdown />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="oldSearch">
        <div v-if="mobileSearchActive" class="w-full h-12  bg-primary dark:bg-primaryDark">
          <MobileSearchBar @onClose="mobileSearchActive = false" />
        </div>
      </div>
      <div v-else>
        <CommandPaletteSearchBar v-if="isSearchBoxOpen" :open="true"></CommandPaletteSearchBar>
      </div>
    </div>

    <DisclosurePanel v-if="openClose" class="lg:hidden shadow-2xl fixed z-10 custom-header-scroll bg-white dark:bg-primaryDark">
      <div class="">
        <div class="cursor-pointer cursor-pointer text-right bg-white dark:bg-primaryDark dark:text-white py-2 pr-2 text-xl" @click="openClose=!openClose"><font-awesome-icon :icon="['fas', 'xmark']" /></div>
        <!-- Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" -->
        <router-link @click="openClose = !openClose" to="/people"
          class=" text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3	text-base font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700">People</router-link>
        <router-link @click="openClose = !openClose" to="/places"
          class="text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3 text-base font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700">Places</router-link>
        <router-link @click="openClose = !openClose" to="/things"
          class="text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3 text-base font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700">Things</router-link>
        <router-link @click="openClose = !openClose" to="/companies"
          class="text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3 text-base font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700">Companies</router-link>
        <router-link @click="openClose = !openClose" to="/works"
          class="text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3 text-base font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700">Works</router-link>
        <router-link @click="openClose = !openClose" to="/productions"
          class="text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3 text-base font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700">Productions</router-link>
      </div>
      <div class="lg:hidden  bg-white dark:bg-primaryDark dark:text-white">
        <SwitchGroup as="div" class="dark:border-gray-700 bg-white  dark:bg-primaryDark lg:hidden flex items-center space-x-4 pl-3 pr-4 py-3 sm:pl-5 sm:pr-6"
          @click="onDarkModeToggle">
          <SwitchLabel
            class="text-gray-500 bg-white block pr-4 text-base font-medium pl-0 lg:pl-6 lg:pr-6 dark:bg-primaryDark dark:text-white w-full ">
            Dark Mode</SwitchLabel>
          <Switch as="button" v-model="darkModeEnabled"
            class="ml-auto flex-shrink-0 inline-flex  h-6 transition-colors duration-400 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline"
            :class="darkModeEnabled ? 'bg-indigo-500' : 'bg-gray-200'" v-slot="{ checked }">
            <div class="justidy-end ">

              <span class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
                :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }">
                <SunIcon v-if="!darkModeEnabled" class="w-5 self-center" />
                <MoonIcon v-if="darkModeEnabled" class="w-5 self-center dark:text-black" />
              </span>
            </div>
          </Switch>
        </SwitchGroup>




        <div v-if="isLoggedIn" class="items-center">

          <app-accordion class="mt-0 custom-switch ">
            <template v-slot:title>
              <div class="flex-shrink-0 m-0">
                <img class="h-10 w-10 rounded-full" :src="profilePhoto" alt="" />
              </div>
            </template>
            <template v-slot:content>
              <div v-if="isLoggedIn" class="mt-0 ">
                <div class="flex text-gray-500 bg-grey-500  block pl-3 pr-4 py-2  pt-0 text-base font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white">
                  <div class="">
                    <div class="text-base font-medium text-gray-800 dark:text-white">{{ firstName }}</div>
                    <div class="text-sm font-medium text-gray-500 dark:text-gray-400">{{ email }}</div>
                  </div>
                  <button
                    class="ml-auto flex-shrink-0 bg-white p-1 rounded-full text-gray-400  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-primaryDark dark:text-white">
                    <span class="sr-only">View notifications</span>
                    <BellIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
    
    
                <router-link @click="openClose = !openClose" :to="{ name: 'Profile', params: { id: id } }"
                  class=" text-gray-500 bg-grey-500 hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3 sm:pl-5 sm:pr-6   text-base font-medium dark:bg-primaryDark dark:text-white">
                  Your Profile 
                </router-link>
                <router-link @click="openClose = !openClose" :to="{ name: 'editProfileContact', params: { id: id } }"
                  class=" block text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:bg-indigo-50 pl-3 pr-4 py-3 sm:pl-5 sm:pr-6  dark:bg-primaryDark dark:text-white">Edit
                  Profile</router-link>
                  <router-link :to="{ name: 'Settings',params: { id: id } }"
                  class="block pl-3 pr-4 py-3 sm:pl-5 sm:pr-6  text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:bg-indigo-50 dark:bg-primaryDark dark:text-white">Settings</router-link>
                <router-link @click="openClose = !openClose" :to="{ name: 'Logout' }"
                  class="block text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:bg-indigo-50 pl-3 pr-4 py-3 sm:pl-5 sm:pr-6  dark:bg-primaryDark dark:text-white">Sign
                  out</router-link>
              </div>
              <div v-else>
                <router-link @click="openClose = !openClose" :to="{ name: 'Login' }"
                  class=" block px-4 py-3 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:bg-indigo-50 sm:px-6">Sign
                  In</router-link>
                <router-link @click="openClose = !openClose" :to="{ name: 'Register' }"
                  class=" block px-4 py-3 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:bg-indigo-50 sm:px-6">Sign
                  Up</router-link>
              </div>
            
            </template>
          </app-accordion>



        </div>
        <div v-else>
          <router-link @click="openClose = !openClose" :to="{ name: 'Login' }"
            class=" block px-4 py-3 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:bg-indigo-50 sm:px-6">Sign
            In</router-link>
          <router-link @click="openClose = !openClose" :to="{ name: 'Register' }"
            class=" block px-4 py-3 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:bg-indigo-50 sm:px-6">Sign
            Up</router-link>
        </div>
        </div>



        
    </DisclosurePanel>
   </div>
    </Disclosure>

  </header>
</template>

<script>
import { useStore } from 'vuex'
import AppAccordion from "@/components/widgets/accordion/AppAccordion";
import { computed, defineAsyncComponent, ref } from "vue";
import { BellIcon, MenuIcon } from '@heroicons/vue/outline'
import { LoginIcon, UserAddIcon, SearchIcon } from '@heroicons/vue/solid'
import { Disclosure, DisclosureButton, DisclosurePanel, Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import { useRouter } from "vue-router";
import { MoonIcon, SunIcon } from '@heroicons/vue/outline'
import MobileSearchBar from '@/components/widgets/globalSearchBar/MobileSearchBar'
import CommandPaletteSearchBar from '../../widgets/globalSearchBar/CommandPaletteSearchBar.vue';
const UserDropdown = defineAsyncComponent({
  loader: () => import("@/components/layout/navbar/UserDropdown"),
})
const navigation = [
  { name: 'People', router: '/people', id: 1, },
  { name: 'Places', router: '/places', id: 2, },
  { name: 'Things', router: '/things', id: 3, },
  { name: 'Companies', router: '/companies', id: 4, },
  { name: 'Works', router: '/works', id: 5, },
  { name: 'Productions', router: '/productions', id: 6, }
]

export default {
  components: {
    SearchIcon,
    MobileSearchBar,
    CommandPaletteSearchBar,
    UserDropdown,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    BellIcon,
    MenuIcon,
    LoginIcon,
    UserAddIcon,
    Switch,
    SwitchGroup,
    SwitchLabel,
    MoonIcon,
    SunIcon,
    AppAccordion
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const openClose = ref(true)
    const mobileSearchActive = ref(false)
    const oldSearch = ref(false)
    const darkModeEnabled = ref(store.getters.getDarkMode)
    const isSearchBoxOpen = computed(() => store.getters.getSearchBoxState)
    function toggleSearchBox() {
      if (oldSearch.value == true) {
        mobileSearchActive.value = !mobileSearchActive.value
      } else {
        store.dispatch("changeSearchBoxState")
      }
    }
    return {
      mobileSearchActive,
      openClose,
      navigation,
      oldSearch,
      toggleSearchBox,
      isSearchBoxOpen,
      goHome: function () { router.push({ name: "Home" }) },
      isLoggedIn: computed(() => store.getters.isLoggedIn),
      id: computed(() => store.getters.getId),
      profilePhoto: computed(() => store.getters.getUserProfilePicture),
      email: computed(() => store.getters.getEmail),
      firstName: computed(() => store.getters.getFirstName),
      darkModeEnabled,
      darkModeOn: computed(() => store.getters.getDarkMode),
      onDarkModeToggle() {
        store.commit('setDarkMode', darkModeEnabled.value)
      },
    }
  }

}
</script>
<style>.router-link-active {
  transition: .2s ease-in;
  /*background-color: #f6f6f6;*/
}
:root .sign_btn.router-link-exact-active {
    background: #404040 !important;
 }
 :root .dark-mode .sign_btn.router-link-exact-active *{
    color: #fff !important;
 }
.router-link:hover {
  border-bottom: 3px solid gray;
  transition: .2s ease-in;
}</style>
