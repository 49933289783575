import gql from "graphql-tag";

export const LanguageFragment = gql`
    fragment LanguageFragment on ProfileLanguageType {
        id
        writtenProficiency
        spokenProficiency
        language{
          name
        }
    }
`
