import gql from "graphql-tag";
import { ContactInfoFragment } from "@/apollo/contact_info/contact_info.fragments";
import { MediaLogoAndPlaceholderFragment,MediaLibraryFragment } from "@/apollo/media/fragments";

export const CompanyFragment = gql`
  fragment CompanyFragment on CompanyType {
    id
    name
	placeFounded
	englishName
    founded
    dissolved
    bio
    country
	userIsAdmin
    medialibrary {
      ...MediaLogoAndPlaceholderFragment
	  ...MediaLibraryFragment
    }
    type {
      name
    }
    level {
      name
    }
  }
  ${MediaLogoAndPlaceholderFragment}
  ${MediaLibraryFragment}
`;

export const SeasonFragment = gql`
  fragment SeasonFragment on SeasonType {
    id
    name
    startDate
    endDate
    notes
    company {
      id
    }
  }
`;

export const CompanyMemberFragment = gql`
  fragment CompanyMemberFragment on CompanyMemberType {
    id
    member {
      profile {
        id
        firstName
        middleName
        surname
        medialibrary {
          logo {
               image(size: "540x540")
           }
           images {
               edges {
                   node {
                       image(size: "540x540")
                   }
               }
           }
       }
      }
    }
    department {
      name
    }
    companymemberjobSet {
      edges {
        node {
          job {
            title
          }
        }
      }
    }
  
  }
`;

export const CompanyDetailedFragment = gql`
  fragment CompanyDetailedFragment on CompanyType {
    createdBy {
      profile {
        id
      }
    }
    ...CompanyFragment
    seasonSet {
      edges {
        node {
          ...SeasonFragment
        }
      }
    }
    contactinfo {
      ...ContactInfoFragment
    }
  }
  ${CompanyFragment}
  ${SeasonFragment}
  ${ContactInfoFragment}
`;
