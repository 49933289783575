import gql from "graphql-tag";
import { ProfileWorkExperienceFragment } from "./user.workExperience.fragments";

export const CREATE_WORK_EXPERIENCE = gql`
    mutation profileWorkExperienceCreateMutation($input: ProfileWorkExperienceInput!){
        profileWorkExperienceCreateMutation(input:$input){
            experience{
            ...ProfileWorkExperienceFragment
            }
        }
    }${ProfileWorkExperienceFragment}
`


export const EDIT_WORK_EXPERIENCE = gql`
    mutation profileWorkExperienceEditMutation($input: ProfileWorkExperienceInput!){
        profileWorkExperienceEditMutation(input:$input){
            experience{
            ...ProfileWorkExperienceFragment
            }
        }
    }${ProfileWorkExperienceFragment}
`
