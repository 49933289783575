import gql from "graphql-tag";


export const ADD_USER_SKILL = gql`
   mutation profileSkillMutation($input: ProfileSkillInput!){
        profileSkillMutation(input: $input){
            skill {
                title
            }
        }
   } 

`
