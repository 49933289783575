import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
    state: () => ({
        company: {
            id: null,
            name: '',
            founded: '',
            dissolved: '',
            englishName: '',
            bio: '',
            country: '',
            type: '',
            level: '',
            userIsAdmin: '',
            logo: null,
            createdBy: '',
            seasonSet: [{
                id: null,
                name: '',
                startDate: '',
                endDate: '',
                notes: '',
                companyId: null,
            }],
            contact: {
                website: {
                    id: null,
                    url: '',
                    title: ''
                },
                email: {
                    id: null,
                    email: '',
                    label: ''
                },
                phone: {
                    id: null,
                    phone: '',
                    label: ''
                },
            },
            companyMembers: [],
            awards: [],
            productions: [],
            header: {
                name: '',
                type: '',
                locations: [],
                phones: [],
                emails: [],
                websites: [],
                socials: [],
            },
            albums: [],
          
        },
        images: [],
        audio: [],
    
        documents: [],
        videos: [],
        albumTitles: [],
        profilePicture: null,
        phoneNumbers: [],
        websites: [],
        emails: [],
        addresses: [],
        socials: [],
        productions: [],
        companyProduction: {
            productionViewSettings: {}
        },
        companyMedia: {
            images: {
                images: [],
                showRandom: null,
            }
        },
        companyTypes: [],
        companyLevels: [],
        companies: [],
        season: {
            id: null,
            name: '',
            startDate: '',
            endDate: '',
            notes: '',
            companyId: null,
        },
        filters: {
            level: {
                title: 'level',
                options: [],
                value: ''
            },
            type: {
                title: 'type',
                options: [],
                value: ''
            }
        },
        sortBy: 'name'
    }),
    mutations: {
        ...mutations
    },
    actions: {
        ...actions
    },
    getters: {
        ...getters
    }
}