import getters from './getters'
import mutations from './mutations'
import actions from './actions'


export default {
    state: () => ({
        height: '',
        eyeColour: '',
        weight: '',
        bodyType: '',
        shoeSize: '',
        voiceType: '',
        hairColour: '',
        vocalRangeLowest: '',
        hairLength: '',
        hairType: '',
        vocalRangeHighest: '',
        beardLength: '',
        beardColour: '',
        beardType: '',
        tattoos: [],
        piercings: [],
        appearance: []
    }),
    mutations: {
        ...mutations
    },
    actions: {
        ...actions
    },
    getters: {
        ...getters
    }
}
