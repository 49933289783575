import gql from "graphql-tag";
import { MedicalContactFragment,MedicalConditionFragment } from "./user.medical.fragments";

export  const MEDICAL_CONTACT_MUTATION = gql`
    mutation medical_contact_mutation($input: ProfileMedicalContactInput!){
        profileMedicalContactMutation(input: $input){
            contact {
            ...MedicalContactFragment
            }
        }
    }${MedicalContactFragment}
`
export  const CREATE_MEDICAL_CONDITION = gql`
    mutation medical_condition_create_mutation($input: ProfileMedicalConditionInput!){
        profileMedicalConditionCreateMutation(input: $input){
            condition {
            ...MedicalConditionFragment
            }
        }
    }${MedicalConditionFragment}
`
export  const EDIT_MEDICAL_CONDITION = gql`
    mutation medical_condition_edit_mutation($input: ProfileMedicalConditionInput!){
        profileMedicalConditionEditMutation(input: $input){
            condition {
            ...MedicalConditionFragment
            }
        }
    }${MedicalConditionFragment}
`
