import { apolloClient } from '@/apollo/apolloClient'
import { CREATE_WORK_EXPERIENCE, EDIT_WORK_EXPERIENCE } from '@/apollo/user/workExperience/user.workExperience.mutations'
import { singleWorkExperienceFormatter } from '@/store/formatters'
import RemoveFalsy from '@/utils/RemoveFalsy'
export default {

    async createWorkExperience(state, exp){
        try {
            exp = RemoveFalsy(exp)
            const res = await apolloClient.mutate({
                mutation: CREATE_WORK_EXPERIENCE,
                variables: { input: { ...exp, profileId:state.getters.getId}}
            })
            state.commit("pushWorkExperience", singleWorkExperienceFormatter(res.data.profileWorkExperienceCreateMutation.experience)) 
        } catch (e) {
            console.error(e)
        }
    },

    async editWorkExperience(state, exp) {
       try {
            exp = RemoveFalsy(exp)
            const res = await apolloClient.mutate({
                mutation: EDIT_WORK_EXPERIENCE,
                variables: { input: exp}
            })
            state.commit("replaceWorkExperience", singleWorkExperienceFormatter(res.data.profileWorkExperienceEditMutation.experience)) 
       } catch (e) {
            console.error(e)
       }
    },

    async deleteWorkExperience(state, id){
        try {
            await apolloClient.mutate({
                mutation: EDIT_WORK_EXPERIENCE,
                variables: { input: { id, delete:true}}
            })
        } catch (e) {
            console.error(e)
        }
    }
}
