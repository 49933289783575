import { nameFormatter } from '@/store/formatters'
import sortContact from '../../../utils/SortContact'
export default {

    getFilterOptions: state => option => state.filterOptions[option] ?? [],
    getPeopleFilters: state => state.filters,
    getPeopleRangeFilters: state => state.rangeFilters,
    getPeopleSortBy: state => state.sortBy,
    getProfile: state => state.profile,
    getProfileId: state => state.profile.id,
    getProfilePhoneNumbers: state => state.profile.phoneNumbers,
    getProfileEmails: state => state.profile.emails,
    profileIsLoaded: state => state.profile.firstName !== null,
    getProfileMedia: state => state.profile.media,
    getProfilePrimaryJob: state => state.profile.primaryJob,
    getProfileDocuments: state => state.profile.documents,
    getProfileImages: state => state.profile.images,
    getProfileVideos: state => state.profile.videos,
    getProfileImage: state => state.profile.image,
    getPreviewProfileImages: state => state.profile.images.filter(img => img.enablePreview),
    getPreviewProfileVideos: state => state.profile.videos.filter(vid => vid.enablePreview),
    getProfileHeader: state => state.profile.header,
    getProfileAbout: state => state.profile.about,
    getProfileSkills: state => state.profile.skills,
    getProfileWebsites: state => state.profile.websites,
    getProfileWorkExperience: state => state.profile.workExperience,
    getProfileEducation: state => state.profile.education,
    getProfileBio: state => state.profile.bio,
    getEnableVcard: state => state.profile.enableVcard,
    getProfileProfileProductions: state => state.profile.profileProductions,
    getProfileAddresses: state => state.profile.addresses, // Puts primary address first
    getSortedProfilePhoneNumbers: state => sortContact(state.profile.phoneNumbers), // Puts primary phone first
    getSortedProfileEmails: state => sortContact(state.profile.emails), // Puts primary email first
    getSortedProfileWebsites: state => sortContact(state.profile.websites), // Puts primary website/link first
    getProfileAlbumTitles: state => state.profile.albumTitles,
	getSearchPeople:state=>state.searchPeople,
    getProfileCities(state) {
        // Returns unique city names of primary addresses
        const primaryAddresses = state.profile.addresses.filter(a => a.isPrimary)
        const cities = primaryAddresses.map(c => c.city)
        const uniqueCities = [...new Set(cities)]; // This is to prevent repeating a city name
        return uniqueCities
    },
    getProfileAlbum: state => title => {
        const albums = state.profile.albums.filter(a => a.title.toLowerCase() === title.toLowerCase())
        if (albums.length > 0)
            return albums[0].images
        return albums
    },
    getPeopleFilterValues(state){
        const obj = {}
        for(let i in state.filters){
            obj[state.filters[i].queryVariable] = state.filters[i].value.join(",")
        }
        return obj
    },
    getPeopleRangeFilterValues(state){
        const obj = {}
        for(let el in state.rangeFilters){
            let filter = state.rangeFilters[el]

            if (filter.min === filter.value[0] && filter.max === filter.value[1]) continue

            obj[el] = filter.value[0].toString() + "-" + filter.value[1].toString()
        }
        return obj
    },
    getTableFormattedPeople(state){
        return state.people.map(item => {
            return {
                job: item.primaryJob,
                name: nameFormatter(item.firstName, item.middleName, item.surname),
                location: item.location,
                id: item.id,
                image: item.profilePhoto
            }
        })
    },
    getCardFormattedPeople(state){
        return state.people.map(item => {
            return {
                name: nameFormatter(item.firstName, item.middleName, item.surname),
                email: item.email,
                image: item.profilePhoto,
                id: item.id,
                url: '/profile/' + item.id,
                title: nameFormatter(item.firstName, item.middleName, item.surname),
                subtitle: item.primaryJob,

            }
        })
    },
    getProfilePicture(state){
        return state.profile.profilePhoto
    },
    getProfilePrimaryPhone(state) {
        if (state.profile.phoneNumbers.length < 1) return ''

        for (let i = 0; i < state.profile.phoneNumbers.length; i++) {
            let phone = state.profile.phoneNumbers[i]
            if (phone.isPrimary) return phone
        }
        return state.profile.phoneNumbers[0]
    },
    getAffiliateCompanies(state){
       let companies= state.profile.affiliateCompanies;
       return companies.map(item => {
        let img=(item.medialibrary.logo !==null && item.medialibrary.logo.isCropped !==undefined &&  item.medialibrary.logo.image !=null )?item.medialibrary.logo.croppedImage:'https://s3.eu-west-1.amazonaws.com/showdeck.io/static/placeholders/company_placeholder.png';
        return{
            id:item.id,
            name:item.name,
            logo:img,
         }
       })
    }
} 