export const PhotoAlbumNames = {
    ProfilePhotos: "PROFILE_PICTURES",
    Photos: "PHOTOS",
    Headshots: "HEADSHOTS"
}

export const AllowedImageFormats = ["jpg", "jpeg", "png"]
export const ImageSizeLimit = 50000000 
export const ImageIsToLargeMsg = "The image is to large. You can upload up to 50MB per image"
export const ImageIsWrongFormatMsg = "The file has a strange format. Images can be jpg, jpeg and png"

export const PROFILE_IMG_MAX_WIDTH = 800;
export const PROFILE_IMG_MAX_HEIGHT = 400;
export const PROFILE_IMG_SIZE = 51200;
