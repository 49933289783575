import ID_DECODER from "@/utils/ID_DECODER";

export default {
    setLoggedIn (state, loggedIn) {
        state.isLoggedIn = loggedIn;
    },
    setMe (state, data) {
        state.email = data.email;
        state.id = ID_DECODER(data.profile.id);
        state.firstName = data.profile.firstName;
		state.showOnboarding=data.showOnboarding;
    },

    removeMe(state){
        state.isLoggedIn = false
        state.email = null
        state.id = null
        state.firstName = null
        state.profilePhotos = null
        state.profilePhoto = null
		state.showOnboarding=false
    },
    setOnboardModal: (state, data) => state.showOnboardModal = data
}
