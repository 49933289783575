import { apolloClient } from "@/apollo/apolloClient";
import { GET_USER_PROGRAMME_BIOGS } from "@/apollo/user/programmeBiog/user.programmeBiog.queries";
import { EDIT_USER_PROGRAMME_BIOGS } from "@/apollo/user/programmeBiog/user.programmeBiog.mutations";

export default {
    async fetchUserProgrammeBiog(state) {
        try {
            const res = await apolloClient.query({
                query: GET_USER_PROGRAMME_BIOGS
            })
            let biog = res.data.me.profile.profileprogrammebiog
            if (biog)
                state.commit("setUserProgrammeBiog", biog)
        } catch (e) {
            console.error(e)
        }
    },

    async editUserProgrammeBiog(state, params) {
		console.log(params)
        try {
            await apolloClient.mutate({
                mutation: EDIT_USER_PROGRAMME_BIOGS,
                variables: { input: params }
            })
        } catch (e) {
            console.error(e)
        }
    }
}