<template>
  <button @click="onClick" type="button" :class="buttonClasses">
    Cancel
  </button>
</template>

<script>
export default {
  name: "CancelButton",
  props:{
    buttonClasses:{
      required: false,
      default: 'inline-flex items-center px-6 py-2 border border-gray-200 text-base font-medium rounded-md shadow-sm text-gray-700 bg-gray-100 hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
    }
  },
  setup(props, { emit }){

    function onClick(){
      emit("onCancel")
    }
    return {onClick}
  }
}
</script>
