<template>
  <div
    :id="title"
    class="bg-white rounded-lg dark:bg-primaryDark py-5 px-6 dark:text-white"
  >
    <div class="flex justify-between">
      <div class="flex">
        <component :is="titleIcon" v-if="showTitleIcon" v-show="titleIcon  !='WrenchIcon'" class="w-4 mr-2 text-gray-400 dark:text-white" />
        <svg  v-if="titleIcon ==='WrenchIcon'" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 mr-2 text-gray-400 dark:text-white"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"  d="M6.47 9.8A5 5 0 0 1 .2 3.22l3.95 3.95 2.82-2.83L3.03.41a5 5 0 0 1 6.4 6.68l10 10-2.83 2.83L6.47 9.8z"/></svg>
        <h2 :class="titleClasses" class="font-medium">{{ title }}</h2>
      </div>
      <button @click="$emit('onClick', true)" class="btn btn-sm edit_button text-blue-500 flex" v-if="editMode">
        <span v-if="funType == 'edit'">
          <PencilIcon class="w-4 mt-1 text-gray-600 dark:text-gray-200" />
        </span>
        <span class="mr-1" v-else-if="funType == 'add'">
          + New {{ title }}
        </span>
      </button>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { PencilIcon, IdentificationIcon , LinkIcon, KeyIcon,CogIcon } from "@heroicons/vue/outline/esm"
export default {
  name: "SideBarWrapper",
  components: {
    PencilIcon,
    IdentificationIcon,
    LinkIcon,
    KeyIcon,
    CogIcon 
  },
  computed:{
    titleClasses(){
      if(this.funType == 'add') return 'text-xl'
      return 'text-sm font-medium'
    },
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    editMode: {
      required: true,
      type: Boolean,
    },
    funType:{
      required: false,
      default: "edit"
    },
    showTitleIcon: {
      required: false,
      default: false
    },
    titleIcon:{
      required: false,
      default: ''
    }
  },
};
</script>
<style>

</style>
