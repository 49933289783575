import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default  {
    state: () => ({
        isLoggedIn: false,
        email: null,
        id: null,
        firstName: null,
        profilePhoto: null,
        showOnboarding: false
    }),
    mutations: {
        ...mutations
    },
    getters: {
        ...getters
    },
    actions: {
        ...actions
    }
}