import { store } from "../store";

function registerEvents() {
    const allInputs = document.querySelectorAll("input");
    events(allInputs);
    const allTextAreas = document.querySelectorAll("textarea");
    events(allTextAreas)
  }

  function events(inputs){
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].onblur = function() {
        store.dispatch("setShouldOpenSearchBox" , true)
      };
      inputs[i].onfocus = function(){
        store.dispatch("setShouldOpenSearchBox", false)
      }
    }
  }

  export default registerEvents;