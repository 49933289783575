<template>
  <nav aria-label="Progress" class="px-6 flex items-center justify-center">
    <ol role="list" class="space-y-0 flex items-center  space-x-2 flex md:space-y-0 md:space-x-4">
      <li v-for="(step, idx) in totalSteps" :key="idx" class="flex-1">
        <a
          v-if="step <= currentStep"
          class="block w-2.5 h-2.5 bg-gray-600 rounded-full hover:bg-gray-800"
        >
        </a>
        <a
          v-else
          class="block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-300"
        >
        </a>
      </li>
    </ol>
  </nav>
</template>
<script>
export default {
  name: "ProgressStepIndicator",
  props: {
    totalSteps: {
      required: true,
    },
    currentStep: {
      required: true,
    }
  },
};
</script>
