import {apolloClient} from "@/apollo/apolloClient";
import { CREATE_EMAIL, EDIT_EMAIL, SET_EMAIL_PRIMARY } from "@/apollo/contact_info/contact_info.mutations";
import {singleEmailFormatter} from "@/store/formatters";
import ID_DECODER from '@/utils/ID_DECODER'

export default {
    async saveEmails(state){
        const emails = state.getters.getEmails
            .filter(item => item.email !== '' && item.id === undefined) // Get new addresses with non empty email field
        const oldEmails = state.getters.getEmails
            .filter(item => item.id !== undefined)
        try{
            for(let i=0; i < emails.length; i++){
                await apolloClient.mutate({
                    mutation: CREATE_EMAIL,
                    variables: {input: {...emails[i], profileId:state.getters.getId}}
                })
            }
            for(let i=0; i < oldEmails.length; i++){
                await apolloClient.mutate({
                    mutation: EDIT_EMAIL,
                    variables: {input: {label: oldEmails[i].label, email:oldEmails[i].email,  emailId: oldEmails[i].id}}
                })
            }
        }catch(err){
            console.log(err)
        }
    },

    async createEmail(state, params){
        try {
            const res = await apolloClient.mutate({
                mutation: CREATE_EMAIL,
                variables: {input: {...params, profileId:state.getters.getId}}
            })
            state.commit("appendEmail", singleEmailFormatter(res.data.emailCreate.email))
        }  catch (e) {
            console.error(e)
        }
    },

    async deleteEmail(state, id){
        try{
            await apolloClient.mutate({
                mutation: EDIT_EMAIL,
                variables: {input: {delete: true, emailId: id}}
            })
        } catch(err){
            console.log(err)
        }
    },

    async setEmailPrimary(state, emailIds){
        try{
            const res = await apolloClient.mutate({
                mutation: SET_EMAIL_PRIMARY,
                variables: {input: {profileId: state.getters.getId, emailIds}}
            })
            const primaryIds = res.data.emailSetPrimaryMutation.emails.map(email => ID_DECODER(email.id))
            state.commit("refreshEmailPrimaries", primaryIds)
        } catch(err){
            console.log(err)
        }
    }
}
