export default {

    pushWorkExperience(state, exp){
        state.workExperience.push(exp)
     },
    setWorkExperience(state, data) {
        state.workExperience = data
    },
    removeWorkExperienceById(state, id){
        state.workExperience = state.workExperience.filter(el => el.id !== id)
    },
    replaceWorkExperience(state, newExp){
        for(let idx in state.workExperience){
            let exp = state.workExperience[idx]

            if(exp.id === newExp.id){
                exp.role = newExp.role
                exp.department = newExp.department
                exp.venue = newExp.venue
                exp.company = newExp.company
                exp.startDate = newExp.startDate
                exp.endDate = newExp.endDate
            }
        }
    }
}
