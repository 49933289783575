import gql from 'graphql-tag';


export const EDIT_MEDIA_VIDEO = gql`
    mutation mediaVideoEditMutation($input: MediaVideoInput!) {
        mediaVideoEditMutation(input: $input) {
            video {
                id
            }
        }
    }
`
