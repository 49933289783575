<template>
  <div class=" ">
    <div class="mb-4">
      <strong> People </strong>
      <p>
        Here is a directory of all the people that have signed up to Showdeck.
        Find the people you need and fill your profile to make sure you are
        found.
      </p>
    </div>
    <div class="mb-4">
      <strong> Places </strong>
      <p>
        A directory of performance venues and rehearsal spaces, with technical
        specifications and tools to help manage you own venue.
      </p>
    </div>
    <div class="mb-4">
      <strong> Things </strong>
      <p>
        A directory of props, costumes, technical equipment and other things.
        Mange your own collection or search for things for hire.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "OnboardStep4",
};
</script>
