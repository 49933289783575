import gql from 'graphql-tag';


export const MediaImageMediumFragment = gql`
    fragment MediaImageMediumFragment on MediaImageType {
        id
        uuid
        image(size: "540x540", crop: false)
        croppedImage
        isCropped
        primary
        title
        caption
        photographer
        date
        location
        album
    }
`

export const MediaImageLargeFragment = gql`
    fragment MediaImageLargeFragment on MediaImageType {
        id
        uuid
        image(size: "1080x1080", crop: false)
        croppedImage
        isCropped
        primary
        title
        caption
        photographer
        date
        location
        album
    }
`


export const MediaLogoFragment = gql`
    fragment MediaLogoFragment on MediaImageType {
        image(size: "540x540", crop: false)
        croppedImage
        isCropped
        uuid
    }
`
