export default {
    setBaseDynamicTableValues(state, data){
        if(data){
            state.totalCount = data.totalCount
            state.hasNextPage = data.hasNextPage
            state.endCursor = data.endCursor
        }

    },
    setDynamicTableSearchParam(state, data){
        state.searchParam = data
    },
    setDynamicTablePage(state, pageNumber){
        if(pageNumber === '1'){
            state.endCursor = ''
            state.after = ''
            state.before = ''
            state.page = 1
        } else {
            const pageNumberTemp = parseInt(pageNumber - 1, 10);
            const paginateBy = parseInt(state.resultCount, 10);
            const totalItems = (pageNumberTemp * paginateBy) -1;
            state.page = parseInt(pageNumber)
            state.after = btoa(`arrayconnection:${totalItems}`);
        }

    },
    setDynamicTableFields(state, data){
        state.fields = data
    },
    setDynamicTablePhotoFields(state, data){
        state.photoFields = data
    },
    setDynamicTableItems(state, data){
        state.items = data
    },
    setDynamicTableResultCount(state, data){
        state.resultCount = data
    },
    setNextPage(state){
        state.page += 1
        state.before = ''
        state.after = state.endCursor
    },
    setPreviousPage(state){
        state.page -= 1
        state.after = ''
        state.before = state.endCursor
    },
    resetDynamicTableState(state){
        state.currentCursor = ''
        state.endCursor = ''
        state.after = ''
        state.before = ''
        state.hasNextPage = false
        state.page = 1
        state.totalCount = 0
        state.resultCount = 12
        state.searchParam = ''
        state.fields = []
        state.items = []
    },
    resetDynamicTableSearchParams(state){
        state.currentCursor = ''
        state.endCursor = ''
        state.after = ''
        state.before = ''
        state.page = 1
        state.resultCount = 12

        state.searchParam = ''
    },removeTableItems(state,id) {
        let productions= state.items.filter(product => product.id !== id)
        state.items=productions;
        state.totalCount=productions.length;
       
      },
      addNewTableItems(state, production){
        let productions=state.items.push(production)
        state.items=productions;
        state.totalCount=productions.length;
    },

}
