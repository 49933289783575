<template>
  <!-- <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" /> -->
<li>
  <h2 class="text-xs font-semibold text-gray-900 dark:text-white">{{ title }}</h2>
  <ul class="-mx-4 mt-2 text-sm text-gray-700 dark:text-gray-300"
      v-for="item in items"
      :value="item"
      :key="item.id"
      as="template"
  >
     <router-link :to="item.url" @click="close">
      <li
        :class="[
          'flex cursor-pointer select-none items-center px-4 py-2 hover:bg-gray-100 hover:text-black',
        ]"
      >
        <img
          :src="item.image ? item.image : '' "
          alt=""
          class="h-6 w-6 flex-none rounded-full"
        />
        <span class="ml-3 flex-auto truncate">{{ item.title }}</span>
      </li>
     </router-link>
  </ul>
</li>
</template>
<script>
export default {
  name: "SearchItemContainer",
  props: {
    items: {
      required: true,
      type: Array
    },
    title: {
      required: true,
      type: String
    }
  },
  methods:{
    close(){
       this.$store.dispatch("changeSearchBoxState");
    }
  }
}
</script>