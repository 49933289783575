/**
 * Remove all keys that don't have values that implicitly evaluate to true. This for example
 * removes any keys that have a value like false/null/NaN/...
 */
export default (obj) => {
    let newObj = {};
    Object.keys(obj).forEach((prop) => {
        if (obj[prop]) { newObj[prop] = obj[prop]; }
    });
    return newObj;
};