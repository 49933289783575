import {apolloClient} from "@/apollo/apolloClient";
import { CREATE_SOCIAL, EDIT_SOCIAL, SET_SOCIAL_PRIMARY } from '@/apollo/contact_info/contact_info.mutations'
import {singleSocialFormatter} from "@/store/formatters";
import ID_DECODER from '@/utils/ID_DECODER'
export default {

    async saveSocials(state){
        const socials = state.getters.getSocials.filter(item =>item.id === undefined)
        const oldSocials = state.getters.getSocials.filter(item => item.lineId !== undefined)
		try {
            for (let i=0; i < socials.length; i++) {
                await apolloClient.mutate({
                    mutation: CREATE_SOCIAL,
                    variables: {input: {...socials[i], profileId:state.getters.getId}}
                })
            }
            for(let i=0; i < oldSocials.length; i++){
                oldSocials[i].lineId = oldSocials[i].id
                delete oldSocials[i].id
                await apolloClient.mutate({
                    mutation: EDIT_SOCIAL,
                    variables: {input: {...oldSocials[i]}}
                })
            }
        } catch (e) {
            console.error(e)
        }
    },

    async createSocial(state, params){
        try {
            const res = await apolloClient.mutate({
                mutation: CREATE_SOCIAL,
                variables: {input: {...params, profileId:state.getters.getId}}
            })
            state.commit("appendSocial", singleSocialFormatter(res.data.socialsLineCreate.socialLine))
        } catch (e) {
            console.error(e)
        }
    },

    async deleteSocial(state, id){
        try{
            await apolloClient.mutate({
                mutation: EDIT_SOCIAL,
                variables: {input: {delete:true, lineId: id}}
            })
        } catch (e) {
            console.error(e)
        }
    },

    async setSocialPrimary(state, lineIds){
        try{
            const res = await apolloClient.mutate({
                mutation: SET_SOCIAL_PRIMARY,
                variables: {input: {profileId:state.getters.getId, lineIds}}
            })

            const socialIds = res.data.socialsLineSetPrimaryMutation.socialLines.map(s => ID_DECODER(s.id))
            state.commit("refreshSocialPrimaries", socialIds)
        } catch (e){
            console.error(e)
        }

    }
}
