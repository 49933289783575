import gql from 'graphql-tag';
import { ProfileFragment, ProfileDetailedFragment } from './people.fragments';
import { EducationFragment } from '@/apollo/user/education/education.fragments';

import { ProfileWorkExperienceFragment } from '@/apollo/user/workExperience/user.workExperience.fragments';

export const GET_PEOPLE = gql`
    query GET_PROFILES(
        $first: Int!
        $after: String!
        $before: String!
        $searchFilter: String
        $name: String
        $sortBy: String
        $primaryJob: String
        $secondaryJobs: String
        $additionalSkills: String
        $height: String
        $age: String
        $countries: String
        $cities: String
        $genders: String
        $appearances: String
        $disabilities: String
        $hairTypes: String
        $hairLengths: String
        $hairColours: String
        $beardTypes: String
        $beardLengths: String
        $beardColours: String
        $bodyTypes: String
        $voiceTypes: String
        $piercings: String
        $tattoos: String
        $stageCombats: String
        $skinColours: String
        $eyeColours: String
    ) {
        profiles(
            first: $first
            after: $after
            before: $before
            search: $searchFilter
            name: $name
            sortBy: $sortBy
            primaryJob: $primaryJob
            secondaryJobs: $secondaryJobs
            additionalSkills: $additionalSkills
            height: $height
            age: $age
            countries: $countries
            cities: $cities
            genders: $genders
            appearances: $appearances
            disabilities: $disabilities
            hairTypes: $hairTypes
            hairLengths: $hairLengths
            hairColours: $hairColours
            beardTypes: $beardTypes
            beardLengths: $beardLengths
            beardColours: $beardColours
            bodyTypes: $bodyTypes
            voiceTypes: $voiceTypes
            piercings: $piercings
            tattoos: $tattoos
            stageCombats: $stageCombats
            skinColours: $skinColours
            eyeColours: $eyeColours
        ) {
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
            }
            totalCount
            edges {
                node {
                ...ProfileFragment
            }
        }
    }
}${ProfileFragment}
`

export const GET_RANDOM_PEOPLE = gql`
    query GET_PROFILES(
        $first: Int!
        $after: String!
        $before: String!
        $searchFilter: String
        $name: String
        $sortBy: String
        $primaryJob: String
        $secondaryJobs: String
        $additionalSkills: String
        $height: String
        $age: String
        $countries: String
        $cities: String
        $genders: String
        $appearances: String
        $disabilities: String
        $hairTypes: String
        $hairLengths: String
        $hairColours: String
        $bodyTypes: String
        $voiceTypes: String
        $piercings: String
        $tattoos: String
    ) {
        randProfiles(
            first: $first
            after: $after
            before: $before
            search: $searchFilter
            name: $name
            sortBy: $sortBy
            primaryJob: $primaryJob
            secondaryJobs: $secondaryJobs
            additionalSkills: $additionalSkills
            height: $height
            age: $age
            countries: $countries
            cities: $cities
            genders: $genders
            appearances: $appearances
            disabilities: $disabilities
            hairTypes: $hairTypes
            hairLengths: $hairLengths
            hairColours: $hairColours
            bodyTypes: $bodyTypes
            voiceTypes: $voiceTypes
            piercings: $piercings
            tattoos: $tattoos
        ) {
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
            }
            totalCount
            edges {
                node {
                ...ProfileFragment
            }
        }
    }
}${ProfileFragment}

`

export const GET_PROFILE = gql`
    query getProfile($id: String){
        profile(id: $id){
            ...ProfileDetailedFragment 
        }
    }${ProfileDetailedFragment}
`

export const GET_PROFILE_FILTER_OPTIONS = gql`
    query getProfileFilterOptions{
        profileJobs
        profileSkills
        hairColour
        hairType
        hairLength
        eyeColour
        voiceType
        skinColour
        bodyType
        stageCombat
        appearance
        tattoos
        piercings
        disabilities
        countries
        cities
        genders
        beardType
        beardColour
        beardLength
    }
`


export const GET_PROFILE_HEADER = gql`
    query getProfile($id: String){
        profile(id: $id){
            header
    }
}
`

export const GET_PROFILE_WORK_EXPERIENCE = gql`
    query getProfile($id: String){
        profile(id: $id){
            profileWorkExperience{
                edges {
                    node{
                    ...ProfileWorkExperienceFragment
                    }
                }
            }
    }
}${ProfileWorkExperienceFragment}
`
export const GET_PROFILE_EDUCATION = gql`
    query getProfile($id: String){
        profile(id: $id){
            education(orderBy: { yearFinished: asc }){
                edges{
                    node{
                    ...EducationFragment
                    }
                }
            }
        }
    }${EducationFragment}
`
