<template>
  <div  class="flex justify-center w-full px-2 lg:ml-6 ">
        <input 
            ref="searchInput"
            @keyup.enter="startSearch" 
            :value="searchTerm" 
            @input="searchTerm=$event.target.value" 
            id="search" 
            name="search" 
            class="md:block border-gray-200 border-1
                   w-1/2 pl-10 pr-3 py-2 rounded-md leading-5  
                   text-gray-300 placeholder-gray-400 focus:outline-none focus:bg-white 
                   focus:border-white focus:ring-white focus:text-gray-900 sm:text-sm 
                   dark:bg-gray-700" 
            placeholder="Search" 
            type="search" />
    <teleport to="body">
<!--      <div v-if="searchActive" class="absolute z-10 inset-0 h-screen flex justify-center items-center bg-white">-->
      <div v-if="searchActive" class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
        <div class="modal-overlay absolute w-full h-full bg-white opacity-95"></div>

        <div class="modal-container fixed w-full h-full z-50 overflow-y-auto ">

          <div class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-black text-sm z-50">
            <svg class="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
            </svg>
            (Esc)
          </div>

          <!-- Add margin if you want to see grey behind the modal-->
          <div class="modal-content container mx-auto h-auto text-left p-4">
          <SearchResultsPage @onClose="searchActive=false; $emit('onClose')" :reload="reload" :search-term="searchTerm"/>
        </div>
      </div>
      </div>
    </teleport>
<!--      </div>-->
  </div>
</template>

<script>
import {ref, watchEffect, onMounted} from "vue";
import SearchResultsPage from "@/components/widgets/globalSearchBar/SearchResultsPage";
export default {
  name: "MobileSearchBar",
  components: {SearchResultsPage},
  setup() {
    const searchActive = ref(false);
    const searchTerm = ref("");
    const searchInput = ref(null)
    const reload = ref(0)
        
      onMounted(() => {
          searchInput.value.focus()
      })  


        watchEffect(() => {
          if(searchTerm.value === "") searchActive.value=false
        })

    return {
      searchActive,
      searchInput,
      searchTerm,
      reload
    }
  },
  methods: {
    startSearch(){
      if(this.searchActive) {
        this.reload++;
      } else {
        this.searchActive = true;
      }
    },
  }
}
</script>

