import { apolloClient } from '@/apollo/apolloClient'
import RemoveFalsy from '@/utils/RemoveFalsy'
import {
    GET_PRODUCTION, GET_PRODUCTION_CONTACT_LIST, GET_PRODUCTION_FILTERS,
    GET_PRODUCTION_MEMBERS,
    GET_PRODUCTIONS,
    GET_WORK_TYPES
} from "@/apollo/entities/productions/productions.queries";
import {
    mediaEncoder,
    productionFormatter,
    // singleProductionFormatter,
    productionMemberFormatter, singleProductionMemberFormatter
} from '@/store/formatters'
import {
    CREATE_PRODUCTION,
    CREATE_PRODUCTION_MEMBER,
    EDIT_PRODUCTION,
    EDIT_PRODUCTION_MEMBER,
} from "@/apollo/entities/productions/productions.mutations";
import { CREATE_EVENT, DELETE_EVENT } from "../../../apollo/events/calendar/event.mutations";
import { singleEventFormatter } from "../../formatters";


export default {

    async fetchProductions(state, params) {

        try {
            const res = await apolloClient.query({
                query: GET_PRODUCTIONS,
                variables: params
            })
            await state.commit('setProductions', productionFormatter(res.data.productions))
            await state.commit('setDynamicTableItems', productionFormatter(res.data.productions))
            return { ...res.data.productions.pageInfo, totalCount: res.data.productions.totalCount }
        } catch (e) {
            console.error(e)
        }
    },

    async fetchProduction(state, id) {
        try {
            const res = await apolloClient.query({
                query: GET_PRODUCTION,
                variables: { id: id },
            })
            state.commit('setProduction', res.data.production)
        } catch (e) {
            console.error(e)
        }
    },

    async createProduction(state, data) {
        data = RemoveFalsy(data)
        if ('logo' in data) {
            if (data['logo'] === '') {
                delete data['logo']
            } else {
                try {
                    data = { ...data, logo: await mediaEncoder(data['logo']) }
                } catch (e) {
                    delete data['logo']
                }
            }
        }
        delete data['based_on'];

        try {
            const res = await apolloClient.mutate({
                mutation: CREATE_PRODUCTION,
                variables: { input: data }
            })
            await state.commit('addNewProduction', res.data.productionCreateMutation)
            await state.commit('addNewTableItems', res.data.productionCreateMutation)
            return res.data.productionCreateMutation.production;
        } catch (e) {
            console.error(e)
        }
    },
    async deleteProduction(state, data) {
        try {
            await apolloClient.mutate({
                mutation: EDIT_PRODUCTION,
                variables: { input: data }
            })

            await state.commit('removeProduction', data.id)
            await state.commit('removeTableItems', data.id)

        } catch (err) {
            console.error(err)
        }
    },

    async saveProduction(state, data) {
        data = RemoveFalsy(data)
        if ('logo' in data) {
            if (data['logo'] === '') {
                delete data['logo']
            } else {
                try {
                    data = { ...data, logo: await mediaEncoder(data['logo']) }
                } catch (e) {
                    delete data['logo']
                }
            }
        }
        try {
            const res = await apolloClient.mutate({
                mutation: EDIT_PRODUCTION,
                variables: { input: data }
            })
            await state.commit('setProductions', res.data.productionEditMutation.production)
            return res.data.productionEditMutation.production;
        } catch (e) {
            console.error(e)
        }
    },


    async fetchProductionMembers(state, params) {
        try {
            const res = await apolloClient.query({
                query: GET_PRODUCTION_MEMBERS,
                variables: params
            })
            state.commit('setProductionMembers', productionMemberFormatter(res.data.productionMembers))
            return { ...res.data.productionMembers.pageInfo, totalCount: res.data.productionMembers.totalCount }
        } catch (e) {
            console.error(e)
        }
    },



    async fetchProductionContactList(state, id) {
        try {
            const res = await apolloClient.query({
                query: GET_PRODUCTION_CONTACT_LIST,
                variables: { id: id }
            })
            return "https://s3.eu-west-1.amazonaws.com/showdeck.io/media/" + res.data.productionContactList.file
        } catch (e) {
            console.error(e)
        }
    },

    async fetchWorkTypes(state) {
        try {
            const res = await apolloClient.query({
                query: GET_WORK_TYPES
            })

            state.commit('setWorkTypes', res.data.workTypes)
        } catch (e) {
            console.error(e)
        }
    },

    async createProductionMember(state, data) {
        try {
            const res = await apolloClient.mutate({
                mutation: CREATE_PRODUCTION_MEMBER,
                variables: { input: data }
            })
            state.commit('addProductionMember', singleProductionMemberFormatter(res.data.productionMemberCreate.productionMember))
        } catch (e) {
            console.error(e)
        }
    },

    async editProductionMember(state, data) {
        try {
            const res = await apolloClient.mutate({
                mutation: EDIT_PRODUCTION_MEMBER,
                variables: { input: data }
            })
            state.commit('updateProductionMember', singleProductionMemberFormatter(res.data.productionMemberEdit.productionMember))
        } catch (e) {
            console.error(e)
        }
    },

    async deleteProductionMember(state, id) {
        try {
            const res = await apolloClient.mutate({
                mutation: EDIT_PRODUCTION_MEMBER,
                variables: { input: { id: id, delete: true } }
            })
            state.commit('removeProductionMember', res.data.productionMemberEdit.productionMember.id)
        } catch (e) {
            console.error(e)
        }
    },


    async createEvent(state, form) {
        let data = RemoveFalsy({ ...form })
        console.log(data)

        try {
            const res = await apolloClient.mutate({
                mutation: CREATE_EVENT,
                variables: { input: data }
            })
            console.log(res.data.eventCreate.event)
            if (res.data.eventCreate.event) {
                await state.commit('setEvents', singleEventFormatter(res.data.eventCreate.event))
            } else {
                console.log('SOMETHING WENT WRONG')
            }

        } catch (e) {
            console.error(e)
        }
    },

    async removeEvent(state, id) {
        try {
            await apolloClient.mutate({
                mutation: DELETE_EVENT,
                variables: { id: id },
            })
        } catch (e) {
            console.error(e)
        }
    },


    async fetchProductionFilters(state) {
        try {
            const res = await apolloClient.query({
                query: GET_PRODUCTION_FILTERS
            })
            state.commit('setProductionFilters', res.data)
        } catch (e) {
            console.error(e)
        }
    },
}
