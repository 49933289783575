<template>
    <Head />
  <!-- <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto&display=swap" rel="stylesheet"> -->
  <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet">
  <div :class="[isDarkMode ? 'dark-mode' : '']">
  <div id="root" class="bg-background relative min-h-screen dark:bg-backgroundDark" key="root">
      <div id ="spacing div" class="w-full h-20">
            <Header/>
      </div>
      <div v-if="hasTopBar" class="w-full h-12">
        <ThingTopBar />
      </div>
    <div :class="fullWidth ? '' : 'max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'">
      <router-view class="pb-80" v-slot="{ Component }">
        <template v-if="Component">
          <transition name='fade' mode="out-in">
<!--            <keep-alive>-->
                <component :is="Component"></component>
<!--            </keep-alive>-->
          </transition>
        </template>
        <LoadingSpinner v-else />
      </router-view>
      <notifications position="bottom center"/>
      <Footer/>
      <onboard v-if="showOnboard"></onboard>
    </div>
  </div>
  </div>
</template>

<script>
import Head from "@/Head"
import Header from "./components/layout/navbar/Header";
import registerEvents from "./utils/DisableSearchBoxInInputs";
import ThingTopBar from "./components/things/viewThing/ThingTopBar.vue";
import Footer from "@/components/layout/Footer";
import Loading from "@/components/layout/Loading";
import LoadingSpinner from '@/components/widgets/LoadingSpinner'
import localStorage from 'store2'
import Onboard from "./components/widgets/onboard/Onboard";
import {useStore} from "vuex";
import {computed, ref, onUpdated} from "vue";
export default {
  components: {Footer, Header, Head, Loading, LoadingSpinner , Onboard, ThingTopBar},
  setup() {
    const store = useStore()

    onUpdated(() => {
      setTimeout(registerEvents , 500);
    });

    // searchbar shortcuts start ( just uncomment if you want shortcuts )
        // const isSearchBoxOpen = computed(() => store.getters.getSearchBoxState)
        // const shouldShortcutsEnable = computed( () => store.getters.getShouldOpenSearchBox );
        // onkeydown = (event) => {
        //   if (shouldShortcutsEnable.value) {
        //     if (event.key === "~" && !isSearchBoxOpen.value) {
        //       store.dispatch("changeSearchBoxState");
        //     } else if (event.key === "Escape" && isSearchBoxOpen.value) {
        //       store.dispatch("changeSearchBoxState");
        //     }
        //   }
        // };
    // searchbar shortcuts end

    // if(store.getters.getDarkMode) {
    //   let htmlElm = document.querySelector("html");
    //   htmlElm.classList.add('dark-mode')
    // }
    return {
        isDarkMode: computed(() => store.getters.getDarkMode),
        isLoading: computed(() => store.getters.isLoading),
        showOnboard: computed(() => store.getters.getOnBoardState),
        doNotShow: ref(false)
    }
  },
  mounted(){
    this.$store.dispatch('Authenticate')
    this.$store.dispatch('fetchCountries')
    this.$store.dispatch('fetchSocialTypes')
    this.$store.dispatch('fetchDocumentTypes')
    this.$store.dispatch('fetchDepartmentTypes')
    this.$store.dispatch('fetchProfileJobs')
    this.$store.dispatch('fetchCalVenues')
    this.$store.dispatch('fetchCalSpaces')
    this.$store.dispatch("fetchBasicProfile")
    this.$store.dispatch('fetchUserProfilePicture')

    const darkMode = localStorage.get('darkMode')
    if (darkMode !== null && darkMode !== undefined) {
        this.$store.commit("setDarkMode", darkMode)
    }

  },

  computed: {
    fullWidth: function () {
      return this.$route.meta.fullWidth
    },
    hasTopBar: function () {
      return this.$route.meta.hasTopBar ?? false
    },
  }

}
</script>
<style>
/*-------------------------*/
.fade-enter-active{
  transition: all 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
/*-------------------------*/

.move-unit-move {
  transition: all .6s ease;
}
/*-------------------------*/
.slide-down-leave-active,
.slide-down-enter-active{
  transition: all .5s ease;
}

.slide-down-enter-from,
.slide-down-leave-to{
  opacity: 0;
  transform: translateY(-50px);
}

/*-------------------------*/
</style>
