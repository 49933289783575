import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
    state:() => ({
        biog: {
            version1: "",
            version2: "",
            version3: "",
            version4: "",
        }
    }),
    actions: {
        ...actions
    },
    mutations: {
        ...mutations
    },
    getters: {
        ...getters
    }
}