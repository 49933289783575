import gql from "graphql-tag";
import { PerformerInformationFragment } from "./user.physical.fragments";

export const GET_PERFORMER_INFO = gql`
    query GET_PERFORMER_INFO{
        me{
            profile{
                performerinformation{
                ...PerformerInformationFragment
                }
                measures{
                    edges{
                        node{
                            measure
                            value
                        }
                    }
                }
            }
        }
    }${PerformerInformationFragment}
`
