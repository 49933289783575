import gql from "graphql-tag";
import { DiversityFragment } from "./diversity.fragments";

export const GET_USER_DIVERSITY = gql`
    query GET_ME{
        me {
            profile{
                diversity{
                ...DiversityFragment
                }
            }
        }
    }${DiversityFragment}
`
