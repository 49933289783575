import gql from "graphql-tag";
import { EducationFragment } from './education.fragments'

export const CREATE_USER_EDUCATION = gql`
    mutation profileEducationCreate($input:EducationInput!){
        profileEducationCreate(input:$input){
            education{
            ...EducationFragment
            }
        }
    }${EducationFragment}
`

export const EDIT_USER_EDUCATION = gql`
    mutation profileEducationEdit($input:EducationInput!){
        profileEducationEdit(input:$input){
            education{
            ...EducationFragment
            }
        }
    }${EducationFragment}
`
