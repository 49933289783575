import gql from "graphql-tag";

export const AddressFragment= gql`
    fragment AddressFragment on AddressType {
        id
        country
        city
        address
        address2
        postcode
        state
        isPrimary
    }
`
    
export const EmailFragment = gql`
    fragment EmailFragment on EmailType {
        label
        email
        isPrimary
        id
    }
`

export const PhoneFragment = gql`
    fragment PhoneFragment on PhoneNumberType {
        label
        phoneNumber
        isPrimary
        id
    }
`

export const WebsiteFragment = gql`
    fragment WebsiteFragment on WebsiteType {
        url
        title
        id
        isPrimary
    }
`

export const SocialFragment = gql`
    fragment SocialFragment on SocialsLineType {
        id
        url
        network
        username
        isPrimary
    }
`

export const ContactInfoFragment = gql`
    fragment ContactInfoFragment on ContactInfoType {
          phoneNumbers{
            edges{
              node{
              ...PhoneFragment
              }
            }
          }
          websites{
           edges{
            node{
            ...WebsiteFragment
            }
           }
          }
          addresses{
            edges{
              node{
              ...AddressFragment
              }
            }
          }
          emails{
            edges{
              node{
              ...EmailFragment
              }
            }
          }
         socials{
                lines{
                    edges{
                        node{
                        ...SocialFragment
                        }
                    }
                }
            }
    }
    ${PhoneFragment}
    ${WebsiteFragment}
    ${AddressFragment}
    ${EmailFragment}
    ${SocialFragment}
`
