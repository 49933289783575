import gql from "graphql-tag"

export const ProfileWorkExperienceFragment = gql`
    fragment ProfileWorkExperienceFragment on ProfileWorkExperienceType {
        id
        company
        venue
        role
        department
        startDate
        endDate
    }
`
