import gql from "graphql-tag";

export const ProfileProductionFragment = gql`
    fragment ProfileProductionFragment on ProfileProductionType {
            id
            name
            role
            productionType
            director
            company
            companyType
            venue
            space
            startDate
            endDate
    }
`
