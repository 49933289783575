//  This function takes an array of contact items (phones/emails/links)
//  and returns an array where the primary item is first.
export default function sortContact(contact) {
  if (contact.length <= 1) return contact

  const primary = contact.filter(c => c.isPrimary)
  const sorted = contact.filter(c => !c.isPrimary)

  if (primary.length === 0)
    return contact 
  else {
    sorted.unshift(...primary)
    return sorted
  }
}
