export default {
    getUserWebsites(state){
        return state.websites
    },
    getWebsiteByIdx: (state) => (idx) => {
        return state.websites[idx]
    },
    getUserPrimaryWebsite(state){
        if(state.websites.length < 1) return ''

        for(let i=0; i < state.websites.length; i++) {
            let website = state.websites[i]
            if (website.isPrimary) return website
        }
        return state.websites[0]
    }
}