import gql from "graphql-tag";
import { ProfileDetailedFragment } from "@/apollo/entities/people/people.fragments"
import { LanguageFragment } from "@/apollo/user/language/user.language.fragments";
import { ProfileJobFragment } from "@/apollo/user/job/user.job.fragments";


export const UserSpecificFragment = gql`
    fragment UserSpecificFragment on ProfileType {
        suffix
        prefix
        nickname
        professionalName
        displayProfessionalName
        placeOfBirth
        pronouns
    }
`


export const OverviewSettingsFragment = gql`
    fragment OverviewSettingsFragment on ProfileOverviewSettingsType {
        showPronouns
        showNationality
        showEthnicity
        showReligion
        showDateOfBirth
        showPlaceOfBirth
        showGender
        showDisabilities
        enableVcard
    }
`

export const UserFragment = gql`
    fragment UserFragment on ProfileType {
        ...UserSpecificFragment
        ...ProfileDetailedFragment
        overviewSettings {
        ...OverviewSettingsFragment
        }
        languages{
            edges{
                node{
                ...LanguageFragment
                }
            }
        }
        secondaryJobs {
            edges{
                node{
                ...ProfileJobFragment
                }
            }
        }
    }
    ${UserSpecificFragment}
    ${ProfileDetailedFragment}
    ${OverviewSettingsFragment}
    ${LanguageFragment}
    ${ProfileJobFragment}
`
