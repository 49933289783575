import { apolloClient } from '@/apollo/apolloClient'
import { VERIFY_EMAIL, RESET_PASSWORD, SIGNUP, LOGIN_USER, FORGOT_PASSWORD, SHOW_ONBOARDING_MUTATION } from '@/apollo/auth/auth.mutations'
import { GET_ME } from "@/apollo/auth/auth.queries";
// import { notify } from "@kyvg/vue3-notification";
import localStorage from 'store2'

export default {

    async Login(state, data) {
        // Handles initial login
        try {
            const res = await apolloClient.mutate({
                mutation: LOGIN_USER,
                variables: {
                    email: data.email,
                    password: data.password
                }
            })
            if (res.data.loginUser.token) {
                await localStorage.set('accessToken', res.data.loginUser.token)
                await state.dispatch('Authenticate')
            } else {
                // notify({
                // title: "Login Failed",
                // text: res.data.loginUser.message,
                // type: 'warn',
                // duration: 2000
                // })
                await state.commit('setLoggedIn', false)
            }
            return res.data.loginUser.message;
        } catch (e) {
            // notify({
            // title: "Login Failed",
            // text: "Incorrect Credentials",
            // type: 'warn',
            // duration: 2000
            // })
            await state.commit('setLoggedIn', false)
        }
    },

    async Logout(state) {
        await localStorage.set('accessToken', null)
        await state.commit('setLoggedIn', false)
        state.commit('removeMe')
        state.commit('resetUserState')
    },

    async Authenticate(state) {
        // Handles authentication through jwt token
        try {
            const res = await apolloClient.query({
                query: GET_ME,
                fetchPolicy: 'no-cache'
            })
            if (res.data.me) {
                await state.commit('setMe', res.data.me)
                await state.commit('setLoggedIn', true)
                await state.dispatch('fetchBasicProfile')
                // await state.dispatch("setOnboardModal", { showOnboarding: res.data.me.showOnboarding })
            } else {

                // localStorage.set('accessToken', null)
            }
        } catch (err) {
            console.error('Authentication Error: ', err)
            localStorage.set('accessToken', null)
        }
    },

    async Register(state, data) {
        // Handles auth registration

        const res = await apolloClient.mutate({
            mutation: SIGNUP,
            variables: {
                input: data
            }
        })
        return res.data.signup

    },

    async ForgotPassword(state, email) {
        try {
            const res = await apolloClient.mutate({
                mutation: FORGOT_PASSWORD,
                variables: { input: { email } }
            })
            return res.data.forgotPassword
        } catch (e) {
            console.error(e)
        }
    },

    async ResetPassword(state, payload) {
        try {
            console.log(payload)
            const res = await apolloClient.mutate({
                mutation: RESET_PASSWORD,
                variables: { input: payload }
            })
            return res.data.resetPassword

            // notify({
            // title: "Success",
            // text: res.data.resetPassword.message,
            // type: 'success',
            // duration: 3000
            // })
        } catch (e) {
            return "Password reset link is expired.";
        }
    },

    async setOnboardModal(state, payload) {
        state.commit("setOnboardModal", payload.localState);
        try {
            await apolloClient.mutate({
                mutation: SHOW_ONBOARDING_MUTATION,
                variables: { input: { showOnboarding: payload.showOnboarding } }
            })
        } catch (e) {
            console.log(e)
        }

    },

    async VerifyEmail(state, token) {
        try {
            return await apolloClient.mutate({
                mutation: VERIFY_EMAIL,
                variables: { input: { token } }
            })
        } catch (e) {
            console.error(e)
        }
    }
}
