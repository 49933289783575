<template>
  <ExclamationIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
  <p class="mt-4 font-semibold text-gray-900">No results found</p>
  <p class="mt-2 text-gray-500">
    We couldn’t find anything with that term. Please try again.
  </p>
</template>

<script>
import { ExclamationIcon } from "@heroicons/vue/outline";
export default {
    name:"SearchNotFound",
    components:{
        ExclamationIcon
    }
}
</script>
