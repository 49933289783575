export default {
    getPhoneNumbers: state => state.phoneNumbers,
    getPhoneByIdx: state => idx => state.phoneNumbers[idx],
    getUserPrimaryPhone(state) {
        if (state.phoneNumbers.length < 1) return ''

        for (let i = 0; i < state.phoneNumbers.length; i++) {
            let phone = state.phoneNumbers[i]
            if (phone.isPrimary) return phone
        }
        return state.phoneNumbers[0]
    }
}