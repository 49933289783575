import ObjMap from "./ObjMap";

const type = obj => Object.prototype.toString.call(obj)

/**
 * Goes through a list or array and makes a deep copy that has all instances of an edges list and
 * node object inside that compressed down to a list of the objects that were inside each node.
 */
const AutoEdgeNodeRemover = data => {
    const dataStringType = type(data);

    // If we're dealing with a list, call this function on each item
    if (dataStringType === "[object Array]") {
        return data.map(item => AutoEdgeNodeRemover(item))
    }
    // Object handling, here is the magic
    else if (dataStringType === "[object Object]") {
        // If the object has edges, that property is an array and the items in that array have a
        // property node of the type object, return just the data from the array and skipping over
        // the nodes as well
        if (type(data?.edges) === "[object Array]"
            && (data.edges.length === 0 || (type(data.edges[0]?.node) === "[object Object]"))) {
            return data.edges.map(item => AutoEdgeNodeRemover(item.node))
        }
        // Otherwise just call this function on all the children
        else {
            return ObjMap(data, AutoEdgeNodeRemover)
        }
    }
    // We hit one of the ends, a string, int or something like that, so we can stop recursing now.
    else {
        return data
    }
}

export default AutoEdgeNodeRemover
