import gql from "graphql-tag";
import { MediaLibraryAlbumNamesFragment } from "./fragments";

export const GET_USER_ALBUM_NAMES = gql`
    query getUserAlbumNames {
        me {
            profile {
                medialibrary {
                    ...MediaLibraryAlbumNamesFragment
                }
            }
        }
    }${MediaLibraryAlbumNamesFragment}
`

export const GET_PROFILE_ALBUM_NAMES = gql`
    query getUserAlbumNames($id: String) {
            profile(id: $id) {
                medialibrary {
                    ...MediaLibraryAlbumNamesFragment
                }
            }
    }${MediaLibraryAlbumNamesFragment}
`
