import { createStore } from 'vuex'
import auth from './auth/index'
import user from './user/index'
import application from './application/index'
import entitiesState from './entities/index'
import componentState from './component/index'
import contactState from './contact/index'
import mediaState from './media/index'

export const store =  createStore({
    modules: {
        user,
        auth,
        application,
        entitiesState,
        componentState,
        contactState,
        mediaState
    }
})

store.subscribeAction({
    before: (_ , state) => {
        if(!state.application.loading) state.application.loading = true
    },
    after: (_ , state) => {
        if(state.application.loading) state.application.loading = false
    }
})
