export default {
    setUserWebsites(state, data){
        state.websites = data
    },
    addNewWebsite(state){
        state.websites.push({title: '', url: ''})
    },
    appendWebsite(state, website){
        state.websites.push(website)
    },
    editWebsiteByIdx(state, {value, key, idx}){
        const website = state.websites[idx]
        website[key] = value
    },
    removeWebsiteByIdx(state, idx){
        state.websites = state.websites.filter((website, i) => idx !== i)
    },
    removeWebsiteErrorProperty(state){
        state.websites.forEach(obj => delete obj.error)
    },
    removeWebsiteById(state, id){
        state.websites = state.websites.filter(e => e.id !== id)
    },
    refreshWebsitePrimaries(state, primaryIds){
        for(let i=0; i < state.websites.length; i++){
            let website = state.websites[i]
            if(primaryIds.includes(website.id)){
                website.isPrimary = true
            } else {
                website.isPrimary = false
            }
        }
    },
    setAllWebsitePrimaryToFalse(state){
        for (let i = 0; i <state.websites.length; i++) {
            state.websites[i].isPrimary = false;
        }
    }
}
