export default {
    setUserLanguages: (state, data) => state.languages = data,
    removeLanguageByIdx:(state, idx) => state.languages.splice(idx, 1),
    removeLanguageById: (state, id) => state.languages = state.languages.filter(lang => lang.id !== id),
    addLanguage:(state, lang) => state.languages.push(lang),
    addNewLanguageLine(state){
        state.languages.push({
            language: "",
            spokenProficiency: "",
            writtenProficiency: ""
        })
    },
    updateLanguageByName(state, newLang){
        for(let i = 0; i < state.languages.length; i++){
            let lang = state.languages[i] 
            if (lang.language === newLang.language){
                lang.language = newLang.language
                lang.spokenProficiency = newLang.spokenProficiency
                lang.writtenProficiency = newLang.writtenProficiency
                return
            }
        }
    }
}
