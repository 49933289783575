<template>
  <button @click="onClick" type="button" :class="buttonClasses">
    <span v-if="showLeftIcon">
      <ArrowNarrowLeftIcon class="w-4 ml-1 mt-0.5"></ArrowNarrowLeftIcon>
    </span>
    {{ title }}
    <span v-if="showRightIcon">
      <ArrowNarrowRightIcon class="w-4 ml-1 mt-0.5"></ArrowNarrowRightIcon>
    </span>
  </button>
</template>

<script>
import {
  ArrowNarrowRightIcon,
  ArrowNarrowLeftIcon,
} from "@heroicons/vue/solid";
export default {
  name: "SaveButton",
  components: { ArrowNarrowRightIcon, ArrowNarrowLeftIcon },
  props: {
    title: {
      required: true,
    },
    buttonClasses: {
      required: false,
      default:
        "inline-flex items-center px-6 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-700 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
    },
    showRightIcon: {
      required: false,
      default: false,
    },
    showLeftIcon: {
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    function onClick() {
      emit("onSave");
    }
    return { onClick };
  },
};
</script>
