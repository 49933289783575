export default {
    setBasicInfo(state, data){
        state.bio = data.bio
        state.firstName = data.firstName
        state.middleName = data.middleName
        state.surname = data.surname
        state.dateOfBirth = data.dateOfBirth
        state.placeOfBirth = data.placeOfBirth
        state.pronouns = data.pronouns
        state.prefix = data.prefix
        state.suffix = data.suffix
        state.nickname = data.nickname
        state.professionalName = data.professionalName
        state.displayProfessionalName = data.displayProfessionalName
        state.representation = data.representation
        state.header = data.header
        state.overviewSettings = data.overviewSettings
        state.about = data.about
    },
    resetUserState(state){
        state.firstName = null
        state.middleName = null
        state.surname = null
        state.dateOfBirth = null
        state.bio = null
        state.prefix = null
        state.suffix = null
        state.nickname = null
        state.professionalName = null
    },

    setUserOverviewSettings(state, data){
        state.overviewSettings = data
    },

    refreshUserAbout(state, data) {
        const keys = Object.keys(state.about) 
        const dataKeys = Object.keys(data)
        
        for (let idx in dataKeys){
            let key = dataKeys[idx]

            if (keys.includes(key)){
                state.about[key] = data[key]
            }
        }
    }
}
