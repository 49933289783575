import sortContact from "../../../utils/SortContact";
export default {
  getCompany(state) {
    return state.company;
  },
  getCompanyId(state) {
    return state.company.id;
  },
  getCompanyBio: state => state.company.bio,
  getCompanyCreatedBy(state) {
    return state.company.createdBy;
  },
  getCompanyName(state) {
    return state.company.name;
  },
  getCompanyMembers(state) {
    return state.company.companyMembers;
  },
  getCardFormattedCompanies(state) {
    return state.companies.map((comp) => {
      return {
        id: comp.id,
        image: comp.logo,
        title: comp.name,
        subtitle: comp.type,
        url: "/company/" + comp.id,
      };
    });
  },
  getCompanyMemberByProfileId: (state) => (id) => {
    for (let i = 0; i < state.company.companyMembers.length; i++) {
      let mem = state.company.companyMembers[i];
      if (mem.member.id === id) return mem;
    }
  },
  getTableFormattedCompanyMembers(state) {
    if (!state.company.companyMembers) return [];
    return state.company.companyMembers.map((member) => {
      let role = "";
      for (let i = 0; i < member.jobs?.length ?? 0; i++) {
        if (i != 0) {
          role += ", ";
        }
        role += member.jobs[i].job;
      }
      return {
        id: member.member.id,
        name: member.member.name,
        image: member.member.image,
		department: member.member.department,
		email: member.member.email,
        //   image: "https://s3.eu-west-1.amazonaws.com/showdeck.io/static/placeholders/profile_placeholder.png",
        role: role == "" ? "Admin" : role,
      };
    });
  },
  getCompanyYearsActive(state) {
    if (state.company.dissolved) {
      return (
        parseInt(state.company.dissolved) - parseInt(state.company.founded)
      );
    }
    let d = new Date();
    let year = d.getFullYear();
    return year - parseInt(state.company.founded);
  },
  getCompanyEmail(state) {
    return state.company.contact.email;
  },
  getCompanyPhone(state) {
    return state.company.contact.phone;
  },
  getCompanyWebsite(state) {
    return state.company.contact.website;
  },
  getCompanySeasons(state) {
    return state.company.seasonSet;
  },
  getSeason(state) {
    return state.season;
  },
  getSeasonID(state) {
    return state.season.id;
  },
  getCompanyTypes(state) {
    return state.companyTypes;
  },

  getCompanyLevels(state) {
    return state.companyLevels;
  },
  getCompanies(state) {
    return state.companies;
  },
  getTableFormattedCompanies(state) {
    return state.companies.map((comp) => {
      return {
        id: comp.id,
        name: comp.name,
        type: comp.type,
        country: comp.country,
        image: comp.logo,
      };
    });
  },
  getCompanyFilters(state) {
    return state.filters;
  },
  getCompaniesSortBy(state) {
    return state.sortBy;
  },
  getCompanyHeader(state) {
    return {
      ...state.company.header,
      title: state.company.name,
      subTitle: state.company.type,
      location: state.company.country,
      website: state.company.contact.website
        ? state.company.contact.website.title
        : "",
      email: state.company.contact.email
        ? state.company.contact.email.email
        : "",
      phone: state.company.contact.phone
        ? state.company.contact.phone.phoneNumber
        : "",
      logo: state.company.logo,
    };
  },
  getCompanyFilterValues(state) {
    const obj = {};
    for (let i in state.filters) {
      obj[state.filters[i].title] = state.filters[i].value;
    }
    return obj;
  },
  getCompanyGeneral(state) {
    return {
      type: state.company.type,
      level: state.company.level ? state.company.level : "Professional",
      founded: state.company.founded,
      dissolved: state.company.dissolved,
      country: state.company.country,
    };
  },
  getCompanyAwards(state) {
    return state.company.awards;
  }, 
  getAllCompanyProductions: state => state.productions,

  getCompanyProductionSettings(state) {
    return state.companyProduction.productionViewSettings;
  },
  getCompnayImages(state) {
    return state.companyMedia.images.images;
  },
  getCompanyImagesMode(state) {
    return state.companyMedia.images.showRandom;
  },
  getSortedCompanyWebsites(state) {
    // console.log(state.company.header);
    return sortContact(state.company.header.websites); // Puts primary website/link first
  },
  getSortedCompanyPhoneNumbers(state) {
    return sortContact(state.company.header.phoneNumbers); // Puts primary phoneNumbers first
  },
  getSortedCompanyEmails(state) {
    return sortContact(state.company.header.emails); // Puts primary emails first
  },
  getSelectedCompanyAddress(state) {
    return state.company.header.addresses.filter((a) => a.isPrimary);
  },
  getSelectedCompanySocials(state) {
    return state.company.header.socials.filter((a) => a.isPrimary);
  },
  getCompanyWebsites(state) {
    return state.websites
  },
  getCompanyWebsiteByIdx: (state) => (idx) => {
    return state.websites[idx]
  },
  getCompanyPrimaryWebsite(state) {
    if (state.websites.length < 1) return ''

    for (let i = 0; i < state.websites.length; i++) {
      let website = state.websites[i]
      if (website.isPrimary) return website
    }
    return state.websites[0]
  },
  getCompanyEmails(state) { return  state.emails},
  getCompanyEmailByIdx: state => idx => state.emails[idx],
  
  getCompanyPrimaryEmail(state) {
      if(state.emails.length < 1) return ''

      for(let i=0; i<state.emails.length; i++) {
          let email = state.emails[i]
          if(email.isPrimary) return email
      }
      return state.emails[0]
  },  
  getCompanyPrimaryPhone(state) {
      if (state.phoneNumbers.length < 1) return ''

      for (let i = 0; i < state.phoneNumbers.length; i++) {
          let phone = state.phoneNumbers[i]
          if (phone.isPrimary) return phone
      }
      return state.phoneNumbers[0]
  },
  getCompanyPhoneNumbers(state) {
   
    return state.phoneNumbers
  },
  getCompanyPhoneByIdx: (state) => (idx) => {
    return state.phoneNumbers[idx]
  },
  getCompanyAddresses(state) {
    return state.addresses
  },
  getCompanyAddressByIdx: state => idx => state.addresses[idx],
  getCompanySocials(state){
    return state.socials
},
getCompanySocialByIdx: state => idx => {
    return state.socials[idx]
},
getCompanySocialPrimaryByNetwork: state => network => {
    for (let i = 0; i < state.socials.length; i++) {
        let social = state.socials[i]
        if (social.network === network && social.isPrimary) {
            return social
        }
    }
    return {url:''}
},
getCompanyProfilePicture(state){
  return state.profilePicture
},
getCompanyAlbum: state => {
    return state.company.albums
},
getCompnayProduction(state){
  return state.CompnayProduction;
},
};
