import gql from 'graphql-tag';
import { CompanyMemberFragment, SeasonFragment, CompanyFragment } from './company.fragments';

export const CREATE_COMPANY = gql `
    mutation createCompany($input: CompanyInput!){
        companyCreate(input: $input){
            company{
                ...CompanyFragment
            }
        }
    }${CompanyFragment}
`
export const CREATE_SEASON = gql `
    mutation createSeason($input: SeasonInput!){
        seasonCreate(input: $input){
            season{
                ...SeasonFragment
            }
        }
    }${SeasonFragment}
`
export const EDIT_SEASON = gql`
    mutation editSeason($input: SeasonInput!){
        seasonEdit(input: $input){
            season{
                ...SeasonFragment
            }
        }
    }${SeasonFragment}
`

export const EDIT_COMPANY = gql`
    mutation editCompany($input: CompanyInput!){
        companyEdit(input: $input){
            company{
               ...CompanyFragment 
            }
        }
    }${CompanyFragment}
`

export const CREATE_COMPANY_MEMBER = gql`
    mutation create_company_member($input: CompanyMemberInput!){
        companyMemberCreate(input: $input){
            member{
                ...CompanyMemberFragment
            }
        }
    }${CompanyMemberFragment}
`

export const EDIT_COMPANY_MEMBER = gql`
    mutation edit_company_member($input: CompanyMemberInput!){
        companyMemberEdit(input: $input){
            ...CompanyMemberFragment
        }
    }${CompanyMemberFragment}
`
