import gql from "graphql-tag";

export const DiversityFragment = gql`
    fragment DiversityFragment on ProfileDiversityType {
        id
        ethnicity{
            name
        }
        sexuality{
            name
        }
        religion{
            name
        }
        sex{
            name
        }
        gender{
            name
        }
        caringResponsibilities
        disabilities
    }
`
