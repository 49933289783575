<template>
  <div class="relative z-10" role="dialog" aria-modal="true">
    <div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"
        @click="closeSearchBox"
      ></div>
      <div
        :class="searchBoxClasses"
        class="mx-auto lg:mr-0 lg:ml-auto max-w-lg lg:max-w-lg xl:-mt-16 transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-primary dark:bg-primaryDark shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
      >
        <div class="relative">
          <SearchIcon
            class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
            <!-- :autofocus="focusInput" -->
          <input
            v-model="rawQuery"
            @change="rawQuery = $event.target.value"
            type="text"
            class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 dark:text-white placeholder-gray-400 focus:ring-0 sm:text-sm"
            placeholder="Search..."
            role="combobox"
            id="searchbox"
            aria-expanded="false"
            aria-controls="options"
          />
        </div>
        <!-- Results, show/hide based on command palette state. -->
        <ul
          v-if="
            getCardFormattedPeople.length > 0 ||
              getCardFormattedProductions.length > 0 ||
              getCardFormattedCompanies.length > 0 ||
              getCardFormattedVenues.length > 0
          "
          class="max-h-80 scroll-py-10 scroll-pb-2 space-y-4 overflow-y-auto p-4 pb-2"
          id="options"
          role="listbox"
        >
          <search-item-container
            v-if="getCardFormattedPeople.length > 0"
            :items="getCardFormattedPeople"
            title="People"
          ></search-item-container>
          <search-item-container
            v-if="getCardFormattedVenues.length > 0"
            :items="getCardFormattedVenues"
            title="Places"
          ></search-item-container>
          <search-item-container
            v-if="getCardFormattedCompanies.length > 0"
            :items="getCardFormattedCompanies"
            title="Companies"
          ></search-item-container>
          <search-item-container
            v-if="getCardFormattedProductions.length > 0"
            :items="getCardFormattedProductions"
            title="Productions"
          ></search-item-container>
        </ul>
        <!-- Empty state -->
        <div
          v-if="
            rawQuery !== '' &&
              isFilterStarted &&
              getCardFormattedPeople.length === 0 &&
              getCardFormattedProductions.length === 0 &&
              getCardFormattedCompanies.length === 0 &&
              getCardFormattedVenues.length === 0
          "
          class="py-14 px-6 text-center text-sm sm:px-14"
        >
          <search-not-found></search-not-found>
        </div>
        <!-- footer -->
        <!-- <search-bar-footer></search-bar-footer> -->
      </div>
    </div>
  </div>
</template>
<script>
import { SearchIcon } from "@heroicons/vue/solid";
// import SearchBarFooter from "./components/CommandPaletteSearchBar/SearchBarFooter.vue";
import SearchItemContainer from "./components/CommandPaletteSearchBar/SearchItemContainer";
import SearchNotFound from "./components/CommandPaletteSearchBar/SearchNotFound.vue";
import { mapGetters } from "vuex";
export default {
  name: "CommandPaletteSearch",
  data() {
    return {
      rawQuery: "",
      isFilterStarted: false,
    };
  },
  props: {
    open: Boolean,
  },
  components: {
    SearchNotFound,
    SearchIcon,
    // SearchBarFooter,
    SearchItemContainer,
  },
  computed: {
    ...mapGetters([
      "getCardFormattedPeople",
      "getCardFormattedProductions",
      "getCardFormattedCompanies",
      "getCardFormattedVenues",
      "isLoggedIn"
    ]),
    searchBoxClasses(){
      if(this.isLoggedIn) return "xl:mr-12"
      return "xl:mr-48"
    }
  },
  mounted(){
    window.document.getElementById("searchbox").focus();
  },
  watch: {
    rawQuery() {
      if (this.rawQuery == "~") {
        this.rawQuery = "";
      } else if (this.rawQuery != "") {
        this.fetchData();
      }
    },
  },
  methods: {
    async fetchData() {
      this.$store.dispatch("fetchPeople", {
        first: 100,
        after: "",
        before: "",
        searchFilter: this.rawQuery,
      });
      this.$store.dispatch("fetchProductions", {
        first: 100,
        after: "",
        before: "",
        searchFilter: this.rawQuery,
        sortBy: "",
        venue: "",
        space: "",
        prods: "",
        type: "",
        country: "",
        city: "",
        company: "",
      });
      this.$store.dispatch("fetchCompanies", {
        first: 100,
        after: "",
        before: "",
        searchFilter: this.rawQuery,
        sortBy: "",
        name: "",
        level: "",
        type: "",
      });
      await this.$store.dispatch("fetchVenues", {
        first: 100,
        after: "",
        before: "",
        searchFilter: this.rawQuery,
        sortBy: "",
        venueType: "",
        country: "",
        name: "",
      });
      this.isFilterStarted = true;
    },
    closeSearchBox() {
      this.$store.dispatch("changeSearchBoxState");
    },
  },
};
</script>

<style>
html {
  overflow-y: visible !important;
  padding-right: 0 !important;
}
</style>
