<template>
  <div class="max-h-60 sm:max-h-full  z-5 m-5">
        <img
            v-if="editMode=true"
            @click="imagePoiSelectorOn = !imagePoiSelectorOn"
            :src="profilePicture ? profilePicture : ''"
            class="rounded-full cursor-pointer w-3/12 object-cover mx-auto"
          />
          <img
            v-else
            @click="imagePoiSelectorOn = !imagePoiSelectorOn"
            :src="profilePicture ? profilePicture : ''"
            class="rounded-full cursor-pointer w-3/12 object-cover mx-auto"
          />
        </div>
    
        <ProfilePhotoEdit
      modalOverFlow="z-80 edit-profile-modal"
      :modalActive="imagePoiSelectorOn"
      :imagePoiSelectorOn="imagePoiSelectorOn"
      @close="setIsPhotoEditOpen(false)"
     />
</template>


<script>
// import ImageCircleInput from "@/components/inputs/ImageCircleInput";
import { PhotoAlbumNames } from '@/constants'
import { mapGetters,useStore } from "vuex";
import { ref,computed } from "vue";
import ProfilePhotoEdit from "@/components/profile/components/profileHeader/ProfilePhotoEdit";

export default {
  name: "OnboardStep3",
  components: {
    ProfilePhotoEdit
    
  },
  data() {
    return {
      fileToBig: false,
      form: {},
      activateLocal: false,
      localPic: "",
      file: null,
      croppedImage:null
    };
  },
  props: ["submitActive"],
  watch: {
    submitActive(){
        if(this.submitActive == true){
            this.saveProfilePic();
        }
    },
  },
  computed: {
    ...mapGetters(["getProfilePicture"]),
  },
  setup(){
    const imagePoiSelectorOn = ref(false);
    const store = useStore();
    const displayProfilePicturesOn = ref(false);
    return{
      imagePoiSelectorOn,
      displayProfilePicturesOn,
      profilePicture: computed(() => store.getters.getProfilePicture),
      getIndexOfImage(images, target) {
        for (let i = 0; i < images.length; i++) {
          if (images[i].croppedImage === target) return i;
        }
        return 0;
      },
      profilePictures: computed(() =>
        store.getters.getProfileAlbum(PhotoAlbumNames.ProfilePhotos)
      ),
      setIsPhotoEditOpen(value) {
        imagePoiSelectorOn.value = value;
      },
    }
  },
  methods: {
    async saveProfilePic() {
      // Profile Picture
      if (this.activateLocal) {
        await this.$store.dispatch("createUserMediaImage", {
          file: this.file,
          logo: true,
          profileId:this.$store.getters.getId,
          album: PhotoAlbumNames.ProfilePhotos,
          croppedFile:this.croppedImage,
          crop:false
        });
       
      await this.$store.dispatch("fetchProfile", this.$store.getters.getId);

      }
    },
    async onPhotoChange(_, files) {
        let file = files[0];
        // console.log(file)
      if (file.size > 50000000) {
        this.fileToBig = true;
        alert("The file you tried to upload is to large then 50MB file limit.");
        return;
      } else {
        this.fileToBig = false;
      }
      this.file = files[0];
      this.croppedImage=files[0];
    //   this.$store.dispatch("saveLocalImageFile" , file)
      this.activateLocal = true;
      this.localPic = URL.createObjectURL(files[0]);
    },
  },
};
</script>
