export default {
    setProductions(state, data) {
        state.productions = data
    },
    setProduction(state, data) {
        state.production.id = data.id,
            state.production.name = data.name,
            state.production.logo = data.logo,
            state.production.productionType = data.productionType,
            state.production.company = data.company,
            state.production.companyType = data.companyType,
            state.production.role = data.role,
            state.production.director = data.director,
            state.production.venue = data.venue,
            state.production.space = data.space,
            state.production.startDate = data.startDate,
            state.production.endDate = data.endDate
    },
    setProductionFilters(state, data) {
        state.filters.city.options = data.cities
        state.filters.company.options = data.companyNames
        state.filters.type.options = data.workTypes
        state.filters.venue.options = data.venueNames
        state.filters.country.options = data.countries
    },
    resetProductionFilters(state) {
        state.filters.name.value = []
        state.filters.companyType.value = []
        state.filters.logo.value = []
        state.filters.director.value = []
        state.filters.role.value = []
        state.filters.based_on.value = []
        state.filters.venue.value = []
        state.rangeFilters.age.value[0] = 1
        state.rangeFilters.age.value[1] = 100
        state.rangeFilters.height.value[0] = 0
        state.rangeFilters.height.value[1] = 300
    },
    setProductionSortBy(state, data) {
        if (data === state.sortBy) {
            state.sortBy = `-${data}`
        } else {
            state.sortBy = data
        }
    },
    setWorkTypes(state, data) {
        state.workTypes = data
    },
    setReviews(state, data) {
        state.production.reviews = data
    },
    setProductionMembers(state, data) {
        state.production.productionMembers = data
    },
    setProductionDocuments(state, data) {
        state.production.documents = data
    },
    addProductionDocument(state, data) {
        state.production.documents.push(data)
    },
    addProductionMember(state, data) {
        state.production.productionMembers.push(data)
    },

    setEvents(state, data) {
        state.production.eventSet = data
    },
    setProductionPreviewPhotosById(state, ids) {
        for (let i = 0; i < state.production.images.length; i++) {
            let img = state.production.images[i]
            img.enablePreview = false
            if (ids.includes(img.id)) {
                img.enablePreview = true
            }
        }

    },
    updateProductionMember(state, data) {
        for (let i = 0; i < state.production.productionMembers.length; i++) {
            let mem = state.production.productionMembers[i]
            if (mem.id === data.id) {
                mem.job = data.job
                mem.profile = data.profile
            }
        }
    },
    removeProductionMember(state, id) {
        for (let i = 0; i < state.production.productionMembers.length; i++) {
            let mem = state.production.productionMembers[i]
            if (mem.id === id) {
                state.production.productionMembers.splice(i, 1)
            }
        }
    },
    resetProduction(state) {
        state.production = {
            name: null,
            companyType: null,
            logo: null,
            director: null,
            role: null,
            based_on: null,
            venue: null,
            startDate: null,
            space: null,
            endDate: null
        }
    },
    setCompnayProductions: (state, production) => (state.productions = production),
    setCompnayProduction: (state, production) => (state.production = production),
    addNewProduction(state, production){
        let productions=state.productions.push(production)
        state.productions=productions;
    },
    removeProduction(state, id) {
      let productions= state.productions.filter(product => product.id !== id)
      state.productions=productions;
    },

}