<template>

    <div class="flex rounded-lg  flex-col gap-2 items-center  pb-4 dark:bg-primaryDark"
        :class="[form.image == null ? '' : 'bg-white']">
        <h1 class="text-2xl  font-bold text-black-700 text-center w-full pb-4 pt-2 dark:text-white">Edit Profile Picture</h1>
        <div class=" bg-white rounded-lg lg:rounded-xl  dark:bg-primaryDark  w-full dark:bg-primaryDark" v-if="form.image === null">
            <label
                class=" dark:bg-primaryDark flex bg-lable-color items-center justify-center w-full h-64 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                <span class="flex items-center space-x-2 flex-col gap-2" @dragover.prevent @drop="onDrop">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-dropzone-color dark:text-white" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                    </svg>
                    <span class="font-normal text-sm  dark:text-white text-black leading-6 text-gray-500" accept="image/x-png,image/gif,image/jpeg" @change="$refs.fileInput.click()">
                        <span class="font-semibold">Click to upload</span> or Drag and drop <br /> SVG, PNG, JPG or GIF 
                    </span>
                </span>
                <input type="file" class="hidden" @change="onFileUpload($event.target.files)" accept="image/x-png,image/gif,image/jpeg" ref="fileInput" />
            </label>
        </div>

        <cropper v-show="form.image" class="w-full bg-black" :stencil-component="$options.components.CircleStencil"
            :src="form.image" :stencil-props="{ aspectRatio: 1 }" :default-size="defaultSize"
            :foreground-class="backgroundClass" ref="cropper" />

        <div v-if="form.image && !imageToBig" class="w-full col-center ">

            <div class="flex items-center dark:text-white justify-start my-3">
                <input type="checkbox" name="crop-check"   @change="false" v-model="form.crop" class="rounded bg-slate-100 mr-2 " />
                <label for="crop-check ">Crop</label>

            </div>
            <div class="flex-add-image">
            <SaveCancelForm :display="true"  @onCancel="$emit('close')"  @onSave="submit" />
        </div>
        </div>
        <div v-if="imageToBig" class="w-full flex-col items-center">
            <p class="text-red-500 text-lg font-sm">Image is to big. Maximum file size is 50 MB.</p>
            <p class="text-red-500 text-lg font-sm">Please upload a different picture</p>
            <button @click="$refs.fileInput.click()"
                class="bg-gray-200 w-48 font-lg text-lg px-4 py-2 rounded">Upload</button>
            <input type="file" class="hidden" @change="onFileUpload($event.target.files)" ref="fileInput" />
        </div>
    </div>

</template>

<script>
/* eslint-disable vue/no-unused-components */
import { ref, onBeforeMount, reactive, computed } from 'vue'
import { useStore } from "vuex";
import { Cropper, CircleStencil } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import SaveCancelForm from '@/components/forms/SaveCancelForm'
import { createToaster } from "@meforma/vue-toaster";
// import { PROFILE_IMG_MAX_WIDTH,PROFILE_IMG_MAX_HEIGHT,ImageSizeLimit } from '@/constants'

const imageToBig = ref(false)
export default {
    name: "ImageProfilePoiInput",
    components: { Cropper, CircleStencil, SaveCancelForm },
    props: {
        maxFileSize: {
            type: Number,
            default: 50000000
        }
    },
    data() {
        return {
            imageFile: "",
            companyUrl: "",
            imageErrorMsg:'',
         
        }
    },
    methods: {
        onDrop: function (e) {
            e.stopPropagation();
            e.preventDefault();
            //    console.log(e.dataTransfer.files);
            this.onFileUpload(e.dataTransfer.files)
        },
    },
    setup(props, { emit }) {
        const cropper = ref(null)
        const sizeError = ref(false)
        const form = reactive({
            image: null,
            file: null,
            croppedFile: null,
            crop: false
        })
        const store = useStore();
        const currentCompany = store.getters.getCompany;
        const state = reactive({
            logo: currentCompany.logo,
        })
        onBeforeMount(() => {
            URL.revokeObjectURL(form.image) // Release memory
        })

        return {
            imageToBig,
            cropper,
            sizeError,
            state,
            form,
            backgroundClass: computed(() => form.crop ? 'opacity-1' : ''),
            onFileUpload(files) {
                // let MAX_WIDTH = PROFILE_IMG_MAX_WIDTH;
                // let MAX_HEIGHT = PROFILE_IMG_MAX_HEIGHT;
                //let MAX_SIZE = ImageSizeLimit;
                const toast = createToaster({ position: "top-right" });
            
                if(!['image/png','image/jpeg','image/svg','image/svg+xml'].includes(files[0]['type'])){
                       toast.error(`Invalid image type. Only jpeg, png, svg and gif are allowed`);
                       emit('close')
                }

            //    if(files[0].size > MAX_SIZE){
            //     toast.error(`Image is to big. Maximum file size is 50 MB`);
            //     emit('close')
            //    }
            
                let img = new Image()
                img.src = window.URL.createObjectURL(files[0])
                img.onload = () => {
                    // if(img.height > MAX_HEIGHT || img.width > MAX_WIDTH ) {
                    //     toast.error(`The selected image is too big. Please choose one with maximum dimensions of ${MAX_WIDTH}x${MAX_HEIGHT}.`);
                    //    emit('close')
                     
                    // }
                                  
                }

            if (files[0].size > props.maxFileSize) {
                    imageToBig.value = true
                }
                form.image = URL.createObjectURL(files[0])
               
            },

            defaultSize({ imageSize, visibleArea }) {
                // Used to initialize stencil at max size
                form.initWidth = (visibleArea || imageSize).width
                form.initHeight = (visibleArea || imageSize).height
                return { width: form.initWidth, height: form.initHeight }
            },
            async submit() {
                const { canvas } = cropper.value.getResult();

                if (!form.crop) // In this case we need original image as well
                    form.file = await fetch(form.image).then(r => r.blob())

                if (canvas) {
                    canvas.toBlob(blob => {
                        if (blob.size > props.maxFileSize) {
                            imageToBig.value = true

                        } else {
                            imageToBig.value = false
                            form.croppedFile = blob  // Canvas provides cropped image
                            state.logo = blob


                            emit('submit', form)
                        }

                    })
                }

            }
        }
    }
}
</script>

<style >
.opacity-1 {
    opacity: 1;
}
</style>