<template>
  <div class=" sm:max-h-full  mb-2">
    <side-bar-wrapper title="Basic Information" :editMode="false">
      <div class="p-2">
        <OnboardUserInfoForm :custom="true">
          <!-- <div class="col-span-2 md:col-span-1 my-1">
            <ListSelector
              @onSubmit="saveJobs"
              :submit="submitActive"
              header="Job title"
              :items="registeredJobs"
              :selectedItems="getUserHeader.jobs"
              version="2"
            />
          </div> -->
          <!-- <div class="col-span-2 md:col-span-1 my-1">
            <ListSelector
              @onSubmit="saveEmail"
              :submit="submitActive"
              header="Email"
              :items="registeredEmails"
              :selectedItems="getUserHeader.emails"
              :singleValue="true"
              version="2"
            />
          </div> -->
        </OnboardUserInfoForm>
      </div>
    </side-bar-wrapper>
  </div>
  <!-- <overview-skills :editMode="true" :skills="getUserSkills"></overview-skills> -->
</template>
<script>
// import OverviewSkills from "../../profile/components/overviewPage/skills/OverviewSkills.vue";
import OnboardUserInfoForm from "../../forms/OnboardUserInfoForm.vue";
import SideBarWrapper from "../../profile/components/common/SideBarWrapper";
// import ListSelector from "@/components/inputs/ListSelector";
import { mapGetters } from "vuex";
export default {
  name: "OnboardStep2",
  components: {
    // OverviewSkills,
    OnboardUserInfoForm,
    SideBarWrapper,
    // ListSelector,
  },
  props: ["submitActive"],
  computed: {
    ...mapGetters(["getUserSkills", "getUserHeader"]),
    registeredJobs() {
      return this.$store.getters.getUserSecondaryJobs.map((job) => job.title);
    },
    registeredEmails() {
      return this.$store.getters.getEmails.map((e) => e.email);
    },
  },
  methods: {
    async saveJobs(jobs) {
      let jobIds = this.$store.getters.getUserSecondaryJobs.filter((job) =>
        jobs.value.includes(job.title)
      );
      jobIds = jobIds.map((job) => job.id);
      await this.$store.dispatch("setSecondaryJobPrimary", jobIds);
    },
    async saveEmail(selectedEmails) {
      let emailIds = this.$store.getters.getEmails.filter((email) =>
        selectedEmails.value.includes(email.email)
      );
      emailIds = emailIds.map((email) => email.id);
      if (emailIds.length > 0)
        await this.$store.dispatch("setEmailPrimary", emailIds);
    },
  },
};
</script>
