<template>
  <div :class="containerClass()">
      <label :class="labelClass()">
          <div class="flex gap-1">
            {{ heading }} <span v-if="requiredIndicator" class="text-red-600 text-sm">*</span>
            <BaseToolTip v-if="tooltip" :text=toolText> </BaseToolTip>
          </div>
      </label>
    <div :class="inputContainerClass()">
      <input
          @input="onInputUpdate($event)"
          :value="realValue"
          :placeholder="placeholder"
          :class="[inputClass()]"
          ref="inputRef"
          :type="type"
          :disabled="disabled"
          class="dark:bg-primaryDark dark:text-white rounded-md" 
          autofocus
           />

    </div>
    <div v-for="error in errors" :key="error.$uid">
      <p class="text-red-600 text-sm">{{ error.$message }}</p>
    </div>
  </div>
</template>

<script>
import BaseToolTip from "@/components/widgets/BaseToolTip.vue";
import { nextTick, ref, watch } from 'vue'

export default {
  name: "SmallTextInput",
  components: { BaseToolTip },
  props: {
    heading: String,
    toolText:String,
    value: [String, Number],
    errors: Array,
    version: String,
    placeholder: String,
    tooltip: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    requiredIndicator:{
       type:Boolean,
       default:false
    },
    focus: {
        type: Boolean,
        default: false
    },
    type: {
      type: String,
      required: false,
      default: "text"
    }
  },
 
  setup(props, { emit }) {
    const inputRef = ref(null)
    const realValue = ref(props.value)
    const onInputLoc = ref(null)

    watch(() => props.focus, newVal => {
        if (newVal) inputRef.value.focus()
    })

    // Keep cursor position stable, even if the prop value is modified underneath it
    watch(() => props.value, newValue => {

      // Store the current cursor position
      const beforeEndPos = inputRef.value.selectionEnd
      
      // Update the real value
      realValue.value = props.value

      // Wait until Vue put the real value into the HTML and update the cursor position
      nextTick().then(() => {
        inputRef.value.selectionStart = onInputLoc.value ?? beforeEndPos
        inputRef.value.selectionEnd = onInputLoc.value ?? beforeEndPos
        onInputLoc.value = null
      })

      emit('update:value', newValue)
    })

    const onInputUpdate = evt => {
      const newValue = evt.target.value

      // Store the current cursor position
      const beforeEndPos = inputRef.value.selectionStart
      onInputLoc.value = beforeEndPos

      if(props.value){
        evt.target.value = props.value
      }

      // Wait until Vue put the real value into the HTML and update the cursor position
      nextTick().then(() => {
        // inputRef.value.selectionStart = 
        inputRef.value.selectionStart = beforeEndPos
        inputRef.value.selectionEnd = beforeEndPos
       
      })

      emit('update:value', newValue)
    }

    return { inputRef, realValue, onInputUpdate }
  },
  methods: {
    containerClass() {
      if (this.$props.version === '2') return "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 "
      return ""
    },
    labelClass() {
      if (this.$props.version === '2') return "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-white"
      return 'block text-sm font-medium text-gray-700 dark:text-white'
    },
    inputContainerClass() {
      var defaultClasses = "rounded-md shadow-sm flex items-center ";
      if (this.$props.version === '2') return "mt-1 sm:mt-0 sm:col-span-2"
      if (this.$props.version == '3') return defaultClasses
      return defaultClasses + " mt-1"
    },
    inputClass() {
      var defaultClasses = "capitalize focus:ring-indigo-500 focus:border-indigo-500 flex-grow block min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 ";
      if (this.$props.version === '2') return  "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
      if (this.$props.version == '3') return defaultClasses + "h-12 w-48"
      return defaultClasses + "w-full";
    }
  }
}
</script>
