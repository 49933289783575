import gql from "graphql-tag";
import { AddressFragment, SocialFragment, WebsiteFragment, EmailFragment, PhoneFragment } from "./contact_info.fragments";

export const CREATE_ADDRESS = gql`
    mutation addressCreate($input: AddressInput!) {
        addressCreate(input: $input){
            address{
                ...AddressFragment
            }
        }
    }${AddressFragment}
`

export const EDIT_ADDRESS = gql`
    mutation addressEdit($input: AddressInput!) {
        addressEdit(input: $input){
            address{
                ...AddressFragment
            }
        }
    }${AddressFragment}

`

export const SET_ADDRESS_PRIMARY = gql`
    mutation addressSetPrimaryMutation($input: AddressInput!) {
        addressSetPrimaryMutation(input: $input){
            addresses{
                ...AddressFragment
            }
        }
    }${AddressFragment}
`


export const CREATE_EMAIL = gql`
    mutation emailCreate($input: EmailInfoInput!){
        emailCreate(input: $input){
            email{
                ...EmailFragment
            }
        }
    }${EmailFragment}
`

export const EDIT_EMAIL = gql`
    mutation emailEdit($input: EmailInfoInput!){
        emailEdit(input: $input){
            email{
                ...EmailFragment
            }
        }
    }${EmailFragment}
`

export const SET_EMAIL_PRIMARY = gql`
    mutation emailSetPrimaryMutation($input: EmailInfoInput!){
        emailSetPrimaryMutation(input: $input){
            emails{
                ...EmailFragment
            }
        }
    }${EmailFragment}
`

export const CREATE_PHONE = gql`
    mutation phoneCreate($input: PhoneNumberInput!){
        phoneCreate(input: $input){
            phoneNumber{
                ...PhoneFragment
            }
        }
    }${PhoneFragment}
`

export const EDIT_PHONE = gql`
    mutation phoneEdit($input: PhoneNumberInput!){
        phoneEdit(input: $input){
            phoneNumber{
                ...PhoneFragment
            }
        }
    }${PhoneFragment}
`

export const SET_PHONE_PRIMARY = gql`
    mutation phoneSetPrimaryMutation($input: PhoneNumberInput!){
        phoneSetPrimaryMutation(input: $input){
            phoneNumbers{
                ...PhoneFragment
            }
        }
    }${PhoneFragment}
`

export const CREATE_WEBSITE = gql`
    mutation websiteCreate($input: WebsiteInput!){
        websiteCreate(input: $input){
            website{
                ...WebsiteFragment
            }
        }
    }${WebsiteFragment}
`

export const EDIT_WEBSITE = gql`
    mutation websiteEdit($input: WebsiteInput!){
        websiteEdit(input: $input){
            website{
                ...WebsiteFragment
            }
        }
    }${WebsiteFragment}
`

export const SET_WEBSITE_PRIMARY = gql`
    mutation websiteSetPrimaryMutation($input: WebsiteInput!){
        websiteSetPrimaryMutation(input: $input){
            websites{
                ...WebsiteFragment
            }
        }
    }${WebsiteFragment}
`


export const CREATE_SOCIAL = gql `
    mutation socialsLineCreate($input: SocialsLineInput!){
        socialsLineCreate(input: $input){
            socialLine{
                ...SocialFragment
            }
        }
    }${SocialFragment}
`

export const EDIT_SOCIAL = gql `
    mutation socialsLineEdit($input: SocialsLineInput!){
        socialsLineEdit(input: $input){
            socialLine{
                ...SocialFragment
            }
        }
    }${SocialFragment}
`

export const SET_SOCIAL_PRIMARY = gql `
    mutation socialsLineSetPrimaryMutation($input: SocialsLineInput!){
        socialsLineSetPrimaryMutation(input: $input){
            socialLines{
                ...SocialFragment
            }
        }
    }${SocialFragment}
`
