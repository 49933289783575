import getters from './getters'
import mutations from './mutations'
import actions from './actions'


export default {
    state: () => ({
        firstName: null,
        middleName: null,
        surname: null,
        dateOfBirth: null,
        placeOfBirth: null,
        pronouns: null,
        bio: null,
        prefix: null,
        suffix: null,
        nickname: null,
        professionalName: null,
        displayProfessionalName: null,
        representation: null,
        header: null,
        overviewSettings: null,
        about: null
    }),
    mutations: {
        ...mutations
    },
    actions: {
        ...actions
    },
    getters: {
        ...getters
    }
}
