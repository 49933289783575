

export default {
    setUserPhysicalInformation(state, data){
        state.eyeColour = data.eyeColour
        state.piercings = data.piercings
        state.bodyType = data.bodyType
        state.tattoos = data.tattoos
        state.voiceType = data.voiceType
        state.hairColour = data.hairColour
        state.vocalRangeLowest = data.vocalRangeLowest
        state.hairLength = data.hairLength
        state.hairType = data.hairType
        state.vocalRangeHighest = data.vocalRangeHighest
        state.appearance = data.appearance
        state.beardType = data.beardType
        state.beardLength = data.beardLength
        state.beardColour = data.beardColour
    },
    setUserWeight(state, data){
        state.weight = data
    },
    setUserHeight(state, data){
        state.height = data
    },
    setUserShoeSize(state, data){
        state.shoeSize = data
    }
}
