export default {
    setProfilePicture: (state, data) => state.profilePicture = data,

    setProfileDocuments: (state, data) => state.documents = data,

    pushProfileDocument: (state, document) => state.documents.push(document),

    setUserImages: (state, images) => state.images = images,

    setUserVideos: (state, videos) => state.videos = videos,

    setUserAlbumTitles: (state, titles) => state.albumTitles = titles,

    addUserVideo: (state, video)  => state.videos.push(video),

    setUserProfilePicture: (state, image) => state.profilePicture = image,

    addUserImage: (state, image) => state.images.push(image),

    removeUserImageByUuid: (state, uuid) => state.images = state.images.filter(img => img.uuid !== uuid),

    removeUserVideoByUuid: (state, uuid)  => state.videos = state.videos.filter(v => v.uuid != uuid),

    setImageAsPrimary(state, id){
        for(let idx in state.images) {
            let i = state.images[idx]
            if(i.id === id){
                i.enablePreview = true
                return
            }
        }
    },
    addUserImageToAlbum(state, image){
        let album = state.albums.filter(a => a.title.toLowerCase() === image.album.toLowerCase()) 
        if (album.length < 1) // Make sure we have an album to work with
            return
        album = album[0]
        album.images.push(image)
    },
    replaceUserImageInfo(state, info) {
        for (let i = 0; i < state.images.length; i++) {
            let curImg = state.images[i]
            if (curImg.uuid === info.uuid) {
                curImg.caption = info.caption
                curImg.location = info.location
                curImg.photographer = info.photographer
                curImg.title = info.title
                curImg.date = info.date
            }
        }
    },

    replaceUserImage(state, img) {
        // find correct album
        let album = state.albums.filter(a => a.title.toLowerCase() === img.album.toLowerCase())
        if (album.length < 1)
            return
        album = album[0] 
        let images = album.images
        // Loop until we find matching image
        for(let i = 0; i < images.length; i++) {
            if (images[i].uuid === img.uuid) {
                images[i] = img
                return
            }    
        }
    },

    removeUserImageFromAlbumByUuid(state, { uuid,albumTitle }) {
		let album = state.albums.filter(a => a.title=== albumTitle)
        if (album.length < 1)
            return
        album = album[0]
        let idx = state.albums.indexOf(album)
        album = {title:album.title, images:album.images.filter(a => a.uuid !== uuid)}

        state.albums[idx] = album
    },


    pushUserAlbums(state, albums) {
        for (let i = 0; i < albums.length; i++) {
            let album = albums[i]
            state.albums.push(album)
        }
    },
    pushUserAlbumByTitle(state, album) {
        state.albums = state.albums.filter(a => a.title !== album.title)
        state.albums.push(album)
    }
}
