export default {
    getDynamicTableResultCount: state => state.resultCount,
    getDynamicTableTotalCount: state => state.totalCount,
    getDynamicTableBefore: state => state.before,
    getDynamicTableAfter: state => state.after,
    getDynamicTableFields: state => state.fields,
    getDynamicTablePhotoFields: state => state.photoFields,
    getDynamicTableItems: state => state.items,
    getDynamicTableLastResultIdx: state => state.resultCount * state.page,
    getDynamicTableCurrentPage: state => state.page,
    getDynamicTableFirstResultIdx(state){
        return ((state.resultCount * state.page) - state.resultCount) + 1
    },
    getDynamicTablePageCount(state){
      return Math.ceil(state.totalCount / state.resultCount)
    },
    getDynamicTableHasNextPage(state){
        return ((state.page) * state.resultCount < state.totalCount) //did this because for some reason hasNextPage
        // did not reset in places like it did in companies, this works for both
    },
    getDynamicTableHasPreviousPage(state){
        return !(state.page === 1)
    },
    getDynamicTableParams(state){
        return {
            first: state.resultCount,
            before: state.before,
            after: state.after,
            searchFilter: state.searchParam
        }
    },
}