<template>
<div class="z-5">
  <modal
    :modalActive="editActive"
    :duration="400"
    version="5"
    :backdrop="false"
    teleport="body"
    >

  <!-- <Dialog :open="editActive"> -->
  <!-- teleport modal to body to avoid error, when modal is being loaded even before #root is mounted in body -->
    <div class="text-center m-3 max-h-100 h-full overflow-hidden overflow-y-scroll sm:overflow-y-hidden p-4 bg-white rounded-xl ">
      <div class="flex flex-col items-center">
        <h2 class="text-2xl sm:text-4xl font-bold mt-6 mb-6 ">{{ showTitle() }}</h2>
      </div>

      <div class="p-1 pb-5 px-4 w-full text-left">
        <component
          :is="currentComponent"
          :submitActive="submitActive"
        ></component>
      </div>

      <progress-step-indicator
        :total-steps="totalStep"
        :current-step="currentStep"
      >
      </progress-step-indicator>
      <div class="flex mt-4 sm:mt-0 justify-between">
        <div class="text-left">
          <button
            @click="back"
            v-if="currentStep != 1"
            class=""
            :class="buttonClasses"
          >
            <ArrowNarrowLeftIcon class="w-4 mr-1 mt-0.5"></ArrowNarrowLeftIcon>
            Back
          </button>
        </div>
     
        <div class="text-right">
          <div v-if="currentStep === totalStep" class="flex flex-row">
            <div class="flex mt-2 mb-2 mr-2 items-center">
              <input
                name="show"
                v-model="checkedNames"
                class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 place-self-center mx-2"
                type="checkbox"
              />
              <label>Don't show again</label>
            </div>
            <button @click="closeOnBoard" :class="buttonClasses">Finish</button>
          </div>
          <div v-else-if="currentStep == 3">
            <button @click="saveProfilePic" :class="buttonClasses">
              Next
              <ArrowNarrowRightIcon
                class="w-4 ml-1 mt-0.5"
              ></ArrowNarrowRightIcon>
            </button>
          </div>
          <button
            v-else-if="currentStep != 2"
            @click="next"
            :class="buttonClasses"
          >
            Next
            <ArrowNarrowRightIcon
              class="w-4 ml-1 mt-0.5"
            ></ArrowNarrowRightIcon>
          </button>
          <div v-if="currentStep == 2" class="flex">
            <save-cancel-form
              class="col-span-2"
              :buttonClasses="buttonClasses"
              @onSave="saveBasicInfo"
              justifyOrientation="center"
              :display="true"
              version="2"
              title="Next"
              :showRightIcon="true"
            >
            </save-cancel-form>
          </div>
        </div>
      </div>
    </div>
  <!-- </Dialog> -->
  </modal>
  </div>
</template>

<script>
import Modal from "@/components/widgets/Modal";
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/vue/outline";
import ProgressStepIndicator from "../creationStepIndicator/ProgressStepIndicator";
// import CreationStepIndicator from "@/components/widgets/creationStepIndicator/CreationStepIndicator";
import SaveCancelForm from "../../forms/SaveCancelForm";
import OnboardStep1 from "./OnboardStep1.vue";
import OnboardStep2 from "./OnboardStep2";
import OnboardStep3 from "./OnboardStep3";
import OnboardStep4 from "./OnboardStep4";
// import {
//     Dialog,
//   } from '@headlessui/vue'
export default {
  name: "Onboard",
  data() {
    return {
      checkedNames:false,
      editActive: true,
      currentStep: 1,
      totalStep: 4,
      submitActive: false,
      currentComponent: "OnboardStep1",
      title: {
        step1: "Welcome to Showdeck!",
        step2: "Tell us more about you",
        step3: "Upload a Profile Picture",
        step4: "Showdeck core features",
      },
    };
  },
  components: {
    Modal,
    ProgressStepIndicator,
    // CreationStepIndicator,
    OnboardStep1,
    OnboardStep2,
    OnboardStep3,
    OnboardStep4,
    SaveCancelForm,
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
    // Dialog,
  },
  computed: {
    buttonClasses() {
      return "mx-2 bg-gray-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:text-white dark:bg-backgroundDark";
    },
  },
  methods: {
    showTitle() {
      if (this.currentStep == 1) {
        this.currentComponent = "OnboardStep1";
        return this.title.step1;
      } else if (this.currentStep == 2) {
        this.currentComponent = "OnboardStep2";
        return this.title.step2;
      } else if (this.currentStep == 3) {
        this.currentComponent = "OnboardStep3";
        return this.title.step3;
      } else if (this.currentStep == 4) {
        this.currentComponent = "OnboardStep4";
        return this.title.step4;
      }
      this.currentComponent = "";
      return "";
    },
    next() {
      this.currentStep += 1;
    },
    back() {
      this.currentStep -= 1;
    },
    async saveBasicInfo() {
      this.submitActive = true;
      function delay(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
      }
      await delay(200);
      const params = {
        firstName: this.$store.getters.getUserFirstName,
        surname: this.$store.getters.getUserSurname,
        dateOfBirth: this.$store.getters.getUserDateOfBirth,
        placeOfBirth: this.$store.getters.getUserPlaceOfBirth,
      }
      await this.$store.dispatch("editProfile", params);
      await this.$store.dispatch("fetchBasicProfile");
      await this.$store.dispatch("fetchProfile", this.$store.getters.getId);
      this.submitActive = false;
      this.next();
    },
    closeOnBoard() {
      console.log('checkbox'+this.checkedNames)
      this.editActive = false;
      this.$store.dispatch("setOnboardModal", {
        showOnboarding:(!this.checkedNames)
      });
    },
    async saveProfilePic() {
      this.submitActive = true;
      function delay(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
      }
      await delay(200);
      this.next();
      this.submitActive = false;
    },
  },
};
</script>
