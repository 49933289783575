import gql from "graphql-tag";

export const EducationFragment = gql`
    fragment EducationFragment on ProfileEducationType {
        id
        institution
        degree
        yearStarted
        yearFinished
    }
`
