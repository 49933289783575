import gql from "graphql-tag";
import { UserFragment, OverviewSettingsFragment } from "./user.basic.fragments";

export const EDIT_PROFILE = gql`
    mutation profileEdit($input: ProfileEditInput!) {
        profileEdit(input: $input) {
            profile {
            ...UserFragment 
            }
        }
    }${UserFragment}
`

export const EDIT_OVERVIEW_SETTINGS = gql`
    mutation profileOverviewSettingsMutation($input: ProfileOverviewSettingsInput!) {
        profileOverviewSettingsMutation(input: $input) {
            overviewSettings {
            ...OverviewSettingsFragment
            }
        }
    }${OverviewSettingsFragment}
`

