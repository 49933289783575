import capitalize from "@/utils/Capitalize"

export default {
    // This result list will contain nulls if something goes wrong, if it does there is a bug
    // somewhere in the fetching/saving of the top level categories
    getThing: state => state.thing,
    getTopCategories: (state, getters) => state.topLevelCategoryIds.map(id => getters.getCategory(id)),
    getCategory: state => id => state.categories[id.toString()],
    getCategoryCustomProperties: state => id => state.categoryCustomProperties[`${id}`] ?? [],
    getThingStatusOptions: state => state.statusOptions.map(capitalize),
    getThingAcquisitionOptions: state => state.acquisitionOptions.map(capitalize),

    hasTagsCached: (state, getters) => image => getters.getImageTags(image) !== null,
    // TODO: Change this into file hashing for more accurate results
    // It also needs to be changed in actions.
    getImageTags: state => image => state.tagCache[image.preview] ?? null,
}
