import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client/core";
import { ApolloLink, } from "@apollo/client/core";
import localStorage from "store2";

const LocalURI = "http://127.0.0.1:8000/"
const ApiURI = "https://api.showdeck.io/"

let local = false // Change this to switch api source

if (process.env.NODE_ENV == "production") 
    local = false

export const URI = ( local ? LocalURI : ApiURI )


const getHeaders = async() => {
    const headers = {};
    const token = await localStorage.get('accessToken')
    if (token) {
        headers.authorization = `JWT ${token}`;
    }
    return headers;
};


const httpLink = createHttpLink({ uri: URI + 'graphql'}) 

const middlewareLink = new ApolloLink(async(operation, forward) => {
    const headers = await getHeaders();
    operation.setContext({
        headers,
    });
    return forward(operation);
});


const link = middlewareLink.concat(httpLink);
const cache = new InMemoryCache();
export const apolloClient = new ApolloClient({ link, cache });



