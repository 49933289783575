import gql from 'graphql-tag';
import { MediaVideoFragment } from './video.fragments';

export const GET_THUMBNAIL = gql`
    query thumbnail($url:String!) {
        thumbnail(url:$url) {
            thumbnail
            error
        }
    }
`

export const GET_USER_MEDIA_VIDEOS = gql`
    query GET_ME {
        me {
            profile {
                medialibrary {
                    videos {
                        edges {
                            node {
                            ...MediaVideoFragment
                            }
                        }
                    }
                } 
            }
        }
    }${MediaVideoFragment}
`

export const GET_PROFILE_MEDIA_VIDEOS = gql`
    query getProfile($id: String){
        profile(id: $id){
            medialibrary {
                videos {
                    edges {
                        node {
                        ...MediaVideoFragment
                        }
                    }
                }
            } 
        }
    }${MediaVideoFragment}
`


export const GET_VIDEO_BY_UUID = gql`
    query GET_VIDEO_BY_UUID($uuid: String!) {
        mediaVideo(uuid: $uuid) {
        ...MediaVideoFragment
        }
    }${MediaVideoFragment}
`
