import {apolloClient} from "@/apollo/apolloClient";
import {CREATE_PHONE, EDIT_PHONE, SET_PHONE_PRIMARY} from "@/apollo/contact_info/contact_info.mutations";
import {singlePhoneNumberFormatter} from "@/store/formatters";
import ID_DECODER from '@/utils/ID_DECODER'

export default {
    async savePhoneNumbers(state){
        const phoneNumbers = state.getters.getPhoneNumbers
            .filter(item => item.label !== '' && item.phoneNumber !== '' && item.id === undefined) // Get new addresses with non empty fields
        const oldPhoneNumbers = state.getters.getPhoneNumbers
            .filter(item => item.id !== undefined)

        try{
            for(let i=0; i < phoneNumbers.length; i++){
                await apolloClient.mutate({
                    mutation: CREATE_PHONE,
                    variables: {input: {...phoneNumbers[i], profileId:state.getters.getId}}
                })
            }
            for(let i=0; i < oldPhoneNumbers.length; i++){
                await apolloClient.mutate({
                    mutation: EDIT_PHONE,
                    variables: {input: {label: oldPhoneNumbers[i].label, phoneNumber:oldPhoneNumbers[i].phoneNumber,  phoneId: oldPhoneNumbers[i].id}}
                })
            }
        }catch(err){
            console.log(err)
        }
    },

    async createPhoneNumber(state, params){
        try {
            const res = await apolloClient.mutate({
                mutation: CREATE_PHONE,
                variables: {input: {...params, profileId:state.getters.getId}}
            })
            state.commit("appendPhoneNumber", singlePhoneNumberFormatter(res.data.phoneCreate.phoneNumber))
        } catch (e) {
            console.error(e)
        }
    },

    async deletePhoneNumber(state, id){
        try{
            await apolloClient.mutate({
                mutation: EDIT_PHONE,
                variables: {input: {delete: true, phoneId: id}}
            })
        } catch(err){
            console.error(err)
        }
    },

    async setPhoneNumberPrimary(state, phoneIds){
        try{
            const res = await apolloClient.mutate({
                mutation: SET_PHONE_PRIMARY,
                variables: {input: {profileId: state.getters.getId, phoneIds}}
            })
            const primaryIds = res.data.phoneSetPrimaryMutation.phoneNumbers.map(phone => ID_DECODER(phone.id))
            state.commit("refreshPhonePrimaries", primaryIds)

        } catch (e) {
            console.error(e)
        }
    }
}
