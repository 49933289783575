import gql from "graphql-tag";
import { LanguageFragment } from "./user.language.fragments";


export const EDIT_LANGUAGE = gql`
    mutation profileLanguageEditMutation($input: ProfileLanguageInput!){
        profileLanguageEditMutation(input:$input){
            language{
            ...LanguageFragment
            }
        }
    }${LanguageFragment}
`


export const CREATE_LANGUAGE = gql`
    mutation profileLanguageCreateMutation($input: ProfileLanguageInput!){
        profileLanguageCreateMutation(input:$input){
            language{
            ...LanguageFragment
            }
        }
    }${LanguageFragment}
`
