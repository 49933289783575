<template>
  <div class="w-full grid grid-cols-2" :class="outerClasses">
    <SmallTextInput :tooltip="true" class="col-span-2  my-1 md:col-span-1" heading="First Name" placeholder="First Name"
      :value="basicInfo.firstName" @update:value="basicInfo.firstName = $event" />
    <!-- <SmallTextInput :tooltip="true" class="col-span-2 md:col-span-1 my-1" heading="Middle Name"
      placeholder="Middle Name" :value="basicInfo.middleName" @update:value="basicInfo.middleName = $event" /> -->
    <SmallTextInput :tooltip="true" class="col-span-2 md:col-span-1 my-1" heading="Surname" placeholder="Surname"
      :value="basicInfo.surname" @update:value="basicInfo.surname = $event" />
    <!-- <SmallTextInput :tooltip="true" class="col-span-2 md:col-span-1 my-1" heading="Prefix"
      placeholder="Prefix" :value="basicInfo.prefix" @update:value="basicInfo.prefix = $event" />
    <SmallTextInput :tooltip="true" class="col-span-2 md:col-span-1 my-1" heading="Suffix"
      placeholder="Suffix" :value="basicInfo.suffix" @update:value="basicInfo.suffix = $event" /> -->
    <!-- <SmallTextInput :tooltip="true" class="col-span-2 md:col-span-1 my-1" heading="Professional Name"
      placeholder="Professional Name" :value="basicInfo.professionalName"
      @update:value="basicInfo.professionalName = $event" /> -->
    <!-- <SmallTextInput :tooltip="true" class="col-span-2 md:col-span-1 my-1" heading="Nickname"
      placeholder="Nickname" :value="basicInfo.nickname" @update:value="basicInfo.nickname=$event" /> -->
    <!-- <SmallTextInput :tooltip="true" class="col-span-2 md:col-span-1 my-1" heading="Pronouns"
      placeholder="Pronouns" :value="basicInfo.pronouns" @update:value="basicInfo.pronouns=$event" /> -->
    <!-- <DateInput :tooltip="true" class="col-span-2 md:col-span-1 my-1" heading="Date of birth" placeholder="Date of Birth"
      :value="basicInfo.dateOfBirth" @update:value="basicInfo.dateOfBirth=$event" />
    <SmallTextInput :tooltip="true" class="col-span-2 md:col-span-1 my-1" heading="Place of birth"
      placeholder="Place of birth" :value="basicInfo.placeOfBirth" @update:value="basicInfo.placeOfBirth=$event" /> -->
      
    <slot></slot>
  </div>
  <div>
            <p class="mb-2 mt-2 whitespace-nowrap pr-2 font-bold text-sm dark:text-white">
                Jobs / Roles
            </p>
           
            <div class="mt-2">
                <MultiFieldInput 
                    :suggestions="jobSuggestion" 
                    :elements="secondaryJobs" 
                    @onChange='searchJobs' 
                    @addElement="addSecondaryJob" 
                    @removeElement="deleteSecondaryJob"
                    :tooltip="true" toolText="Add Jobs / Roles"
                />
            </div>
        </div>
</template>

<script>
import SmallTextInput from "@/components/inputs/SmallTextInput";
// import DateInput from "@/components/inputs/DateInput";
import MultiFieldInput from "@/components/inputs/MultiFieldInput";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  name: "OnBoardUserInfoForm",
  components: {  SmallTextInput,MultiFieldInput },
  props: {
    custom: {
      required: false,
      default: false
    }
  },
  computed: {
    paraClasses() {
      if (this.custom) return ""
      return "pl-10"
    },
    iconClasses() {
      if (this.custom) return "w-8 pr-1"
      return "w-20 pl-10 pr-4"
    },
    outerClasses() {
      if (this.custom) return "grid-cols-2 xl:grid-cols-2 gap-x-2";
      return "grid-cols-2 xl:grid-cols-3 gap-x-2"
    }
  },
  setup() {
    const store = useStore()
    return {
      basicInfo: computed(() => store.getters.basicInfo),
      jobSuggestion: computed(() => store.getters.getProfileJobs.slice(0, 5)),
      secondaryJobs: computed(() => store.getters.getUserSecondaryJobs.map(job => job.title)),
      searchJobs(val){
                store.dispatch("fetchProfileJobs", val)
            },
            addSecondaryJob(title){
                store.dispatch('createSecondaryJob', title)
            },
            deleteSecondaryJob(title){
                store.dispatch('deleteSecondaryJob', title)
            },      
    }
  }
}
</script>
