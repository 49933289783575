export default {
    setSocials(state, data) {
        state.socials = data
    },
    addNewSocial(state){
        state.socials.push({network: '', url: ''})
    },
    appendSocial(state, social){
        state.socials.push(social)
    },
    editSocialByIdx(state, {value, key, idx}) {
        const social = state.socials[idx]
        social[key] = value
    },
    removeSocialByIdx(state, idx) {
        state.socials = state.socials.filter((social, i) => idx !== i)
    },
    refreshSocialPrimaries(state, primaryIds){
        for(let i=0; i < state.socials.length; i++){
            let social = state.socials[i];
            if (primaryIds.includes(social.id)){
                social.isPrimary = true;
            } else {
                social.isPrimary = false;
            }
        }
    }
}
