import peopleState from './people/index'
import placesState from './places/index'
import companyState from './companies/index'
import productionState from './productions/index'
import thingState from './things/index'

export default {
    modules: {
        peopleState,
        placesState,
        companyState,
        productionState,
        thingState
    }
}