export default {
    isLoading: state => state.loading,
    getCountries: state => state.countries,
    getSocialTypes: state => state.socialTypes,
    getDarkMode: state => state.darkMode,
    getDocumentTypes: state => state.documentTypes,
    getDepartmentTypes: state => state.departmentTypes,
    getProfileJobs: state => state.profileJobs,
    getProfileSkillOptions: state => state.profileSkills,
    getEthnicities: state => state.ethnicities,
    getGenders: state => state.genders,
    getReligions: state => state.religions,
    getSexes: state => state.sexes,
    getSexualities: state => state.sexualities,
    getDisabilities: state => state.disabilities,
    getCaringResponsibilities: state => state.caringResponsibilities,
    getSearchBoxState: state => state.searchBox,
    getShouldOpenSearchBox: state => state.shouldOpenSearchBox
}
