import gql from "graphql-tag";

export const ProgrammeBiogFragment = gql`
    fragment ProgrammeBiogFragment on ProfileProgrammeBiogType {
        version1
        version2
        version3
        version4
    }
`
