<template>
  <div class="col-span-1 w-full flex flex-col items-center cursor-pointer border-b-2 border-l-2 shadow-md w-1/3 h-full border-gray-200" >
    <img :src="img" class="mt-4 rounded-full h-40 w-40 flex items-center justify-center object-cover"/>
    <p class="font-medium text-center">{{title}}</p>
    <p class="font-regular text-center text-gray-400">{{subtitle}}</p>
  </div>

</template>

<script>
export default {
  name: "CircleCard",
  props: {
    img: String,
    title: String,
    subtitle: String
  }
}
</script>
