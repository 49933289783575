export default {
    setAddresses(state, data){
        state.addresses = data
    },
    addNewAddress(state){
        state.addresses.push({city: '', country:'', address:'', address2:'', postcode:'', state:''})
    },
    appendAddress(state, address){
        state.addresses.push(address)
    },
    removeAddress(state, id){
        state.addresses = state.addresses.filter(address => address.id !== id)
    },
    removeAddressByIdx(state, idx){
        state.addresses = state.addresses.filter((address, i) => idx !== i)
    },
    removeAddressErrorProperty(state){
        state.addresses.forEach(obj => delete obj.errors)
    },
    refreshAddressPrimaries(state, primaryIds){
        for(let i=0; i < state.addresses.length; i++){
            let addr = state.addresses[i]
            if (primaryIds.includes(addr.id)) {
                addr.isPrimary = true
            } else {
                addr.isPrimary = false
            }
        }
    },
}
