import router from '@/router'
import {store} from '../../../store'

export default {
    getProfileEditState: state => {
       return (state.profileEditState && store.getters.isUserAuthorizedToEdit) 
    },
    isUserAuthorizedToEdit: () => {
        const visitingProfile = router.currentRoute.value.params.id;
        const currentUser =  store.getters.getId;
        if( visitingProfile == currentUser ) return true
        return false
    },
    getUserOnboardState: state => state.onboardState
}