import { apolloClient } from '@/apollo/apolloClient'
import {EDIT_USER_EDUCATION, CREATE_USER_EDUCATION } from '@/apollo/user/education/user.education.mutations'
import { singleEducationFormatter } from '@/store/formatters'
export default {
    
    async createEducation(state, education){
        try {
            const res = await apolloClient.mutate({
                mutation: CREATE_USER_EDUCATION,
                variables: { input: { ...education, profileId:state.getters.getId}}
            })
           state.commit('pushUserEducation', singleEducationFormatter(res.data.profileEducationCreate.education))
        } catch (e) {
            console.error(e)
        }
    },

    async editEducation(state, education){
        try {
            await apolloClient.mutate({
                mutation: EDIT_USER_EDUCATION,
                variables: { input: education }
            })
        } catch (e) {
            console.error(e)
        }
    },

    async deleteEducation(state, id){
        try {
            await apolloClient.mutate({
                mutation: EDIT_USER_EDUCATION,
                variables: { input: { id, delete:true}}
            })
        } catch (e) {
            console.error(e)
        }
    }

}
