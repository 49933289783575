export default {
    setCompany(state, data) {
		state.company.id = data.id
        state.company.name = data.name
        state.company.founded = data.founded
        state.company.dissolved = data.dissolved
        state.company.bio = data.bio
        state.company.englishName = data.englishName
        state.company.userIsAdmin = data.userIsAdmin
        state.company.country = data.country
        state.company.type = data.type
        state.company.level = data.level
        state.company.logo = data.logo
        state.company.createdBy = data.createdBy
        state.company.contact.website = data.website
        state.company.contact.email = data.email
        state.company.contact.phone = data.phone
        // state.company.companyMembers = data.companyMembers
        state.company.seasonSet = data.seasonSet
        state.company.awards = data.awards
        state.company.productions = data.productions
        state.company.header = data.header
        state.websites = data.contactinfo.websites
        state.emails = data.contactinfo.emails
        state.phoneNumbers = data.contactinfo.phoneNumbers
        state.addresses = data.contactinfo.addresses
        state.socials = data.contactinfo.socials.lines
		state.company.albums=data.medialibrary.images
    },
    setSeason(state, data) {
        state.season.id = data.id
        state.season.notes = data.notes
        state.season.startDate = data.startDate
        state.season.endDate = data.endDate
        state.season.name = data.name
        state.season.companyId = data.companyId
    },
    setCompanyMembers(state, data) {
        state.company.companyMembers = data
    },
    addCompanyMember(state, data) {
        state.company.companyMembers.push(data)
    },
    addCompanyMemberRole(state, data) {
        for (let i = 0; i < state.company.companyMembers.length; i++) {
            let mem = state.company.companyMembers[i]
            if (mem.member.id === data.profileId) {
                mem.job.push({
                    id: data.id,
                    title: data.role,
                    department: data.department,
                    dateStarted: data.started,
                    dateLeft: data.left
                })
            }
        }
    },
    removeCompanyMember(state, id) {
        state.company.companyMembers = state.company.companyMembers.filter(member => member.id !== id)
    },
    setCompanyEmail(state, data) {
        state.company.contact.email = data
    },
    setCompanyFilters(state, data) {
        state.filters.level.options = data.companyObjLevels
        state.filters.type.options = data.companyObjTypes
    },
    setCompanyTypes(state, data) {
        state.companyTypes = data
    },
    setCompanyLevels(state, data) {
        state.companyLevels = data
    },
    setCompanies(state, data) {
        state.companies = data
    },
    setCompaniesSortBy(state, data) {
        if (data === state.sortBy) {
            state.sortBy = `-${data}`
        } else {
            state.sortBy = data
        }
    },
    resetCompany(state) {
        state.company = {
            id: null,
            name: '',
            founded: '',
            dissolved: '',
            bio: '',
            country: '',
            englishName: '',
            type: '',
            level: '',
            logo: null,
            createdBy: '',
            seasonSet: {
                id: null,
                name: '',
                startDate: '',
                endDate: '',
                description: '',
                companyId: null,
            },
            contact: {
                website: {
                    id: null,
                    url: '',
                    title: ''
                },
                email: {
                    id: null,
                    email: '',
                    label: ''
                },
                phone: {
                    id: null,
                    phone: '',
                    label: ''
                },
            },
            websites: [],
            // emails:[],
            // phoneNumbers:[],
            // addresses:[],
            companyMembers: [],
            season: {
                id: null,
                name: '',
                startDate: '',
                endDate: '',
                notes: '',
                companyId: null,
            },
        }
        state.companyProduction = {
            productionViewSettings: {}
        }
    },

    setCompanyProductionSettings(state, data) {
        state.companyProduction.productionViewSettings = data
    },

    setCompnayImages(state, data) {
        state.companyMedia.images.images = data.images
        state.companyMedia.images.showRandom = data.showRandom
    },
	setCompanySocials(state, data) {
        state.socials = data
    },
    setCompanyWebsites(state, data) {
        state.websites = data
    },
    addNewCompanyWebsite(state) {

        state.websites.push({ title: '', url: '' })
    },
    appendCompanyWebsite(state, website) {
        state.websites.push(website)
    },
    editCompanyWebsiteByIdx(state, { value, key, idx }) {
        const website = state.websites[idx]
        website[key] = value
    },

    removeCompanyWebsiteErrorProperty(state) {
        const websitess = state.websites
        websitess.forEach(obj => delete obj.error)
    },
    removeCompanyWebsiteById(state, id) {
        state.websites = state.websites.filter(e => e.id !== id)
    },
    refreshCompanyWebsitePrimaries(state, primaryIds) {
        for (let i = 0; i < state.websites.length; i++) {
            let website = state.websites[i]
            if (primaryIds.includes(website.id)) {
                website.isPrimary = true
            } else {
                website.isPrimary = false
            }
        }
    },
    setAllCompanyWebsitePrimaryToFalse(state) {
        for (let i = 0; i < state.websites.length; i++) {
            state.websites[i].isPrimary = false;
        }
    },
    removeCompanyWebsiteByIdx(state, idx) {
        state.websites = state.websites.filter((website, i) => idx !== i)
    },
    setCompanyEmails(state, data) {
        state.emails = data
    },
    appendCompanyEmail(state, email) {
        state.emails.push(email)
    },
    removeCompanyEmailErrorProperty(state) {
        state.emails.forEach(obj => delete obj.error)
    },
    addNewCompanyEmail(state) {
        state.emails.push({ label: '', email: '' })
    },
    editCompanyEmailByIdx(state, { value, key, idx }) {
        const email = state.emails[idx]
        email[key] = value
    },
    removeCompanyEmailByIdx(state, idx) {
        state.emails = state.emails.filter((email, i) => idx !== i)
    },
    refreshCompanyEmailPrimaries(state, primaryIds) {
        for (let i = 0; i < state.emails.length; i++) {
            let email = state.emails[i]
            if (primaryIds.includes(email.id)) {
                email.isPrimary = true
            } else {
                email.isPrimary = false
            }
        }
    },
    setAllCompanyEmailPrimaryToFalse(state) {
        for (let i = 0; i < state.emails.length; i++) {
            state.emails[i].isPrimary = false
        }
    }
    , setCompanyPhoneNumbers(state, data) {
        state.phoneNumbers = data
    },
    addNewCompanyPhoneNumber(state) {
        state.phoneNumbers.push({ label: '', phoneNumber: '' })
    },
    appendCompanyPhoneNumber(state, phone) {
        state.phoneNumbers.push(phone)
    },
    removeCompanyPhoneNumber(state, id) {
        state.phoneNumbers = state.phoneNumbers.filter(number => number.id !== id)
    },
    editCompanyPhoneByIdx(state, { value, key, idx }) {
        const phone = state.phoneNumbers[idx]
        phone[key] = value
    },
    removeCompanyPhoneByIdx(state, idx) {
        state.phoneNumbers = state.phoneNumbers.filter((phone, i) => idx !== i)
    },
    refreshCompanyPhonePrimaries(state, primaryIds) {
        for (let i = 0; i < state.phoneNumbers.length; i++) {
            let phone = state.phoneNumbers[i]
            if (primaryIds.includes(phone.id)) {
                phone.isPrimary = true
            } else {
                phone.isPrimary = false
            }
        }
    },
    setAllCompanyPhonePrimaryToFalse(state) {
        for (let i = 0; i < state.phoneNumbers.length; i++) {
            state.phoneNumbers[i].isPrimary = false;
        }
    },
    setCompanyAddresses(state, data) {
        state.addresses = data
    },
    addNewCompanyAddress(state) {
        state.addresses.push({ city: '', country: '', address: '', address2: '', postcode: '', state: '' })
    },
    appendCompanyAddress(state, address) {
        state.addresses.push(address)
    },
    removeCompanyAddress(state, id) {
        state.addresses = state.addresses.filter(address => address.id !== id)
    },
    removeCompanyAddressByIdx(state, idx) {
        state.addresses = state.addresses.filter((address, i) => idx !== i)
    },
    removeCompanyAddressErrorProperty(state) {
        state.addresses.forEach(obj => delete obj.errors)
    },
    refreshCompanyAddressPrimaries(state, primaryIds) {
        for (let i = 0; i < state.addresses.length; i++) {
            let addr = state.addresses[i]
            if (primaryIds.includes(addr.id)) {
                addr.isPrimary = true
            } else {
                addr.isPrimary = false
            }
        }
    },

    addNewCompanySocial(state) {
        state.socials.push({ network: '', url: '' })
    },
    appendCompanySocial(state, social) {
        state.socials.push(social)
    },
    editCompanySocialByIdx(state, { value, key, idx }) {
        const social = state.socials[idx]
        social[key] = value
    },
    removeCompanySocialByIdx(state, idx) {
        state.socials = state.socials.filter((social, i) => idx !== i)
    },
    refreshCompanySocialPrimaries(state, primaryIds) {
        for (let i = 0; i < state.socials.length; i++) {
            let social = state.socials[i];
            if (primaryIds.includes(social.id)) {
                social.isPrimary = true;
            } else {
                social.isPrimary = false;
            }
        }
    },
    addCompanyImageToAlbum(state, image) {
        let album = state.company.albums.filter(a => a.title.toLowerCase() === image.album.toLowerCase())
        if (album.length < 1) // Make sure we have an album to work with
            return
        album = album[0]
        album.images.push(image)
    },
    setCompanyProduction(state, data) {
        state.CompnayProduction = data
    },
    setCompanyProfileImages: (state, data) => state.companyMedia.images.images = data,
    setCompanyProfilePicture: (state, data) => state.profilePicture = data,
    setCompanyPicture: (state, data) => state.profilePicture = data,
    replaceCompanyImage(state, img) {
        // find correct album
        let album = state.company.albums.filter(a => a.title.toLowerCase() === img.album.toLowerCase())
        if (album.length < 1)
            return
        album = album[0]
        let images = album.images
        // Loop until we find matching image
        for (let i = 0; i < images.length; i++) {
            if (images[i].uuid === img.uuid) {
                images[i] = img
                return
            }
        }
    },
}

