import gql from 'graphql-tag';
import { ProductionFragment } from './productions.fragments';

export const GET_WORK_TYPES = gql`
     query getWorkTypes{
      workTypes
    }
`

export const GET_PRODUCTION_CONTACT_LIST = gql`
    query getProductionList($id: ID!){
      productionContactList(id: $id){
        id
        file
      }
    }
`


export const GET_PRODUCTIONS = gql`
    query getProductions(
        $first: Int!
        $after: String!
        $before: String!
        $searchFilter: String!
        $sortBy: String!
      
    ){
        productions(
            first: $first
            after: $after
            before: $before
            search: $searchFilter
            sortBy: $sortBy
           
        ){
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
            }
            totalCount
            edges{
                node{
                ...ProductionFragment
                }
            }
        }
    }
    ${ProductionFragment}
`

export const GET_PRODUCTION = gql`
    query getProduction($id: ID){
    production(id: $id){
		   id
        name
        productionType
        company
        companyType
        role
        director
        venue
        space
        startDate
        endDate
	}
}
`

export const GET_PRODUCTION_MEMBERS = gql`
    query getProductionMembers(
        $productionId: Float
        $searchFilter: String!
    ){
    productionMembers(
        productionId: $productionId
        search: $searchFilter
    ) {
     pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                }
            totalCount
    edges{
            node{
              id
              isPrimary
              department {
                  name
              } 
              profile{
                id
                firstName
                middleName
                surname
                placeholder(size: "255x255")
              }
              job{
                id
                title
              }
            }
          }
  }
}
`

export const GET_PRODUCTION_DOCUMENTS = gql`
    query getProductionDocument(
      $productionId:String
      $documentTitle:String
      $documentDepartment:String
      $documentType:String
      $sortBy: String
    ){
      productionDocuments(
        productionId:$productionId
        title:$documentTitle
        department:$documentDepartment
        documentType:$documentType
        sortBy: $sortBy
      ){
       edges{
        node{
          id
          author
          date
          expirationDate
          file
          no
          version
          title
          documentType
          department{
            name
          }
        }
      }
        
  }
}
`

export const GET_PRODUCTION_FILTERS = gql`
    query getProductionFilters{
        workTypes
        countries
        cities
        companyNames
        venueNames
    }
`
