

export default {
    getUserHeight: state => state.height,
    getUserEyeColour: state => state.eyeColour,
    getUserWeight: state => state.weight,
    getUserPiercings: state => state.piercings,
    getUserBodyType: state => state.bodyType,
    getUserTattoos: state => state.tattoos,
    getUserShoeSize: state => state.shoeSize,
    getUserVoiceType: state => state.voiceType,
    getUserHairColour: state => state.hairColour,
    getUserVocalRangeLowest: state => state.vocalRangeLowest,
    getUserHairLength: state => state.hairLength,
    getUserVocalRangeHighest: state => state.vocalRangeHighest,
    getUserHairType: state => state.hairType,
    getUserAppearance: state => state.appearance,
    getUserBeardLength: state => state.beardLength,
    getUserBeardType: state => state.beardType,
    getUserBeardColour: state => state.beardColour,

}
