import axios from 'axios'
import localStorage from "store2";
import { apolloClient, URI } from '@/apollo/apolloClient';
import { imageFormatter, videoFormatter, albumFormatter, singleVideoFormatter } from '@/store/formatters';
import { EDIT_MEDIA_IMAGE, SET_PRIMARY_MEDIA_IMAGES } from '@/apollo/media/images/image.mutations'
import {
    GET_THUMBNAIL, GET_USER_MEDIA_VIDEOS, GET_PROFILE_MEDIA_VIDEOS,
    GET_VIDEO_BY_UUID
} from '@/apollo/media/videos/video.queries.js'
import { EDIT_MEDIA_VIDEO } from '@/apollo/media/videos/video.mutations.js'
import {
    GET_PROFILE_MEDIA_IMAGES, GET_USER_ALBUM, GET_PROFILE_ALBUM, GET_USER_PROFILE_PICTURE,
    GET_USER_MEDIA_IMAGES, GET_IMAGE_BY_UUID
} from '@/apollo/media/images/image.queries'
import { GET_USER_ALBUM_NAMES, GET_PROFILE_ALBUM_NAMES } from '@/apollo/media/queries';
import RemoveFalsy from "@/utils/RemoveFalsy"

export default {
    async fetchProfileMediaImages(state, profile_id) {
        try {
            const res = await apolloClient.query({
                query: GET_PROFILE_MEDIA_IMAGES,
                variables: { id: profile_id }
            })
            if (res.data.profile.medialibrary) {
                state.commit('setProfileImages', imageFormatter(res.data.profile.medialibrary.images))
            } else {
                state.commit('setProfileImages', [])
            }

        } catch (e) {
            console.error(e)
        }
    },

    async fetchUserMediaImages(state) {
        try {
            const res = await apolloClient.query({
                query: GET_USER_MEDIA_IMAGES,
            })
            if (res.data.me.profile.medialibrary) {
                state.commit('setUserImages', imageFormatter(res.data.me.profile.medialibrary.images))
            } else {
                state.commit('setUserImages', [])
            }
        } catch (e) {
            console.error(e)
        }
    },

    async fetchImageByUuid(state, uuid) {
        try {
            const res = await apolloClient.query({
                query: GET_IMAGE_BY_UUID,
                variables: { uuid: uuid }
            })
            return res.data.mediaImage
        } catch (e) {
            console.error(e);
        }
    },

    async createMediaImage(state, params) {
        try {

            params = RemoveFalsy(params)

            let formData = new FormData();
            if (params.profileId) formData.append('profile_id', params.profileId)
            if (params.thingId) formData.append('thing_id', params.thingId)

            if (params.file) formData.append('file', params.file)
            if (params.croppedFile) formData.append("cropped_file", params.croppedFile)
            if (params.crop) formData.append("crop", params.crop)
            if (params.title) formData.append('title', params.title)
            if (params.photographer) formData.append('photographer', params.photographer)
            if (params.caption) formData.append('caption', params.caption)
            if (params.date) formData.append('date', params.date)
            if (params.logo) formData.append('logo', params.logo)
            if (params.fetchTags) formData.append('fetch_tags', params.fetchTags)

            if (params.album) {
                formData.append("album", params.album)
                delete params['album']
            }

            let res = await axios.post(URI + "upload_image/", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": await localStorage.get('accessToken')
                }
            })

            if ("error" in res.data) {
                console.error(res.data.error);
                return;
            }
            if (res.data.success === false) {
                console.error(res.data.message)
                return
            }
            if (params.fetchTags) {
                return res.data.res.Labels
            }

            const img = await state.dispatch("fetchImageByUuid", res.data.uuid)

            // Returns image so that it can be handled correctly
            // somwhere else
            return img

        } catch (e) {
            console.error(e);
        }
    },

    async deleteMediaImage(state, { uuid, albums }) {
        try {
            state.commit('removeUserImageFromAlbumByUuid', { uuid, albums })
            await apolloClient.mutate({
                mutation: EDIT_MEDIA_IMAGE,
                variables: { input: { uuid, delete: true } }
            })
        } catch (e) {
            console.error(e)
        }
    },

    async editMediaImage(_, params) {
        try {
            const res = await apolloClient.mutate({
                mutation: EDIT_MEDIA_IMAGE,
                variables: { input: params }
            })

            // Returns image so that it can be handled correctly
            // somwhere else
            return res.data.mediaImageEditMutation.image

        } catch (e) {
            console.error(e)
        }
    },

    async setPrimaryMediaImages(state, params) {
        try {
            await apolloClient.mutate({
                mutation: SET_PRIMARY_MEDIA_IMAGES,
                variables: { input: params }
            })
        } catch (e) {
            console.error(e)
        }
    },

    async fetchUserProfilePicture(state) {
        if (!state.getters.isLoggedIn) return;
        try {
            const res = await apolloClient.query({
                query: GET_USER_PROFILE_PICTURE,
                fetchPolicy: 'no-cache'
            })

            let profile = res.data.me.profile
            if (profile.medialibrary) {
                state.commit('setUserProfilePicture',
                    profile.medialibrary.logo?.croppedImage || profile.medialibrary.placeholder)
            }
        } catch (e) {
            console.error(e)
        }
    },

    // VIDEO

    async fetchProfileMediaVideos(state, profileId) {
        try {
            const res = await apolloClient.query({
                query: GET_PROFILE_MEDIA_VIDEOS,
                variables: { id: profileId }
            })
            if (res.data.profile.medialibrary) {
                state.commit('setProfileVideos', videoFormatter(res.data.profile.medialibrary.videos))
            }
        } catch (e) {
            console.error(e);
        }
    },

    async fetchThumbnail(state, url) {
        try {
            const res = await apolloClient.query({
                query: GET_THUMBNAIL,
                variables: { url }
            })
            return res.data.thumbnail
        } catch (e) {
            console.error(e)
        }
    },


    async createMediaVideo(state, params) {
        try {
            let formData = new FormData();
            formData.append('file', params.file)
            formData.append('profile_id', params.profileId)
            formData.append('title', params.title)
            formData.append('caption', params.caption)
            formData.append('url', params.url)
            let res = await axios.post(URI + "upload_video/", formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": await localStorage.get('accessToken')
                    }
                })

            if ("error" in res.data) {
                console.error(res.data.error);
                return;
            }

            let vid = await apolloClient.query({
                query: GET_VIDEO_BY_UUID,
                variables: { uuid: res.data.uuid }
            })
            state.commit("addUserVideo", singleVideoFormatter(vid.data.mediaVideo))

        } catch (e) {
            console.error(e);
        }
    },

    async fetchUserMediaVideos(state) {
        try {
            const res = await apolloClient.query({
                query: GET_USER_MEDIA_VIDEOS
            })
            if (res.data.me.profile.medialibrary) {
                state.commit('setUserVideos', videoFormatter(res.data.me.profile.medialibrary.videos))
            }
        } catch (e) {
            console.error(e);
        }
    },

    async deleteUserMediaVideo(state, uuid) {
        try {
            await apolloClient.mutate({
                mutation: EDIT_MEDIA_VIDEO,
                variables: { input: { uuid, delete: true } }
            })

            state.commit("removeUserVideoByUuid", uuid)
        } catch (e) {
            console.error(e);
        }
    },

    // VCARD
    async fetchVcard(state, params) {
        try {
            // TODO Add other entity ids
            let formData = new FormData();
            formData.append('profile_id', params.profileId)
            let res = await axios.post(URI + "download_vcard/", formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
            return res

        } catch (e) {
            console.error(e)
        }
    },

    // ALBUM
    async fetchUserAlbum(state, albumTitle) {
        try {
            const res = await apolloClient.query({
                query: GET_USER_ALBUM,
                variables: { albumTitle: albumTitle },
                fetchPolicy: 'no-cache'
            })

            if (res.data.me.profile.medialibrary) {
                const album = albumFormatter(res.data.me.profile.medialibrary.albums)[0]
                if (album)
                    state.commit("pushUserAlbumByTitle", album)
				console.log(album)
            }
        } catch (e) {
            console.error(e)
        }
    },

    async fetchProfileAlbum(state, { albumTitle, profileId }) {
        try {
            if (!profileId) {
                return
            }
            const res = await apolloClient.query({
                query: GET_PROFILE_ALBUM,
                variables: { albumTitle, id: profileId }
            })
            if (res.data.profile.medialibrary) {
                const album = albumFormatter(res.data.profile.medialibrary.albums)[0]
                if (album)
                    state.commit("pushProfileAlbumByTitle", album)
            }
        } catch (e) {
            console.error(e)
        }
    },

    async fetchUserAlbumTitles(state) {
        try {
            const res = await apolloClient.query({
                query: GET_USER_ALBUM_NAMES
            })
            if (res.data.me?.profile.medialibrary?.albumNames)
                state.commit("setUserAlbumTitles", res.data.me.profile.medialibrary.albumNames)
        } catch (e) {
            console.error(e)
        }
    },

    async fetchProfileAlbumTitles(state, id) {
        try {
            const res = await apolloClient.query({
                query: GET_PROFILE_ALBUM_NAMES,
                variables: { id }
            })
            state.commit("setProfileAlbumTitles", res.data?.profile.medialibrary.albumNames)
        } catch (e) {
            console.error(e)
        }
    },

}
