import getters from './getters'
import mutations from './mutations'
import actions from './actions'


export default {
    state: () => ({
        profileProductions: [],
        sortKey: null
    }),
    mutations: {
        ...mutations
    },
    actions: {
        ...actions
    },
    getters: {
        ...getters
    }
}
