<template>
  <div class="border-2  border-gray py-4 flex flex-wrap rounded-lg mt-2" @click="$refs.input.focus()" >
    <div
        v-for="el, idx in elements"
        :key="idx"
        class="bg-gray-200  mx-3 my-2 shadow px-4 py-2 rounded-md flex items-center dark:text-gray-700"
    >
      {{ el }}
      <XCircleIcon class="w-5 h-5 ml-2 cursor-pointer" @click="deleteEntry(el)" />
    </div>
      <div
          class="flex flex-col items-center dark:text-white"
      >
            <input
                ref="input"
                type="text"
                v-model="currentValue"
                @keyup="$emit('onChange', currentValue)"
                @keyup.enter="submitNewEntry"
                @focus="inputFocused=true"
                @focusout="inputFocused=false"
                class="border-transparent mx-3 my-2 rounded dark:bg-primaryDark"
            />
            <!-- <p v-if="inputFocused" class="text-gray-500">enter to create</p> -->
      </div>
    <div v-if="currentValue.length > 0 && suggestions" class="w-full flex flex-col items-center rounded-lg ">
    <h2 class="text-gray-500">Suggestions:</h2>
    <div class="w-full  py-2 flex flex-wrap  ">
        <transition-group name="list-complete">
            <div
                @click="currentValue=suggestion; submitNewEntry()"
                class="list-complete-item bg-gray-500 dark:bg-gray-600 text-gray-100
                    mx-3 my-2 px-4 py-2 rounded-md flex cursor-pointer items-center"
                v-for="suggestion, idx in suggestions"
                :key="idx">
                {{suggestion}}
            </div>
        </transition-group>
        </div>
    </div>
  </div>
</template>
<script>
import { XCircleIcon } from '@heroicons/vue/solid/esm'
import { ref } from 'vue'
export default {
  name: "MultiFieldInput",
  components: { XCircleIcon },
  props: {
    elements: Array,
    suggestions: Array
  },
  setup(props, { emit }) {
        let currentValue = ref("")
        const inputFocused = ref(false)
        return {
          currentValue,
          inputFocused,
          submitNewEntry(){
            if (currentValue.value === "") return
                emit("addElement", currentValue.value)
                currentValue.value = ""
          },
          deleteEntry(title){
            emit("removeElement", title)
          }
        }
      }
}
</script>
<style scoped>
    .list-complete-item {
      transition: all 0.8s ease;
      display: inline-block;
      margin-right: 10px;
    }
    .list-complete-enter-from,
    .list-complete-leave-to {
      opacity: 0;
      transform: translateY(30px);
    }
    .list-complete-leave-active {
      position: absolute;
    }
    /* This removes all focus effects */
    input:focus {
        outline-style: none;
        box-shadow: none;
        border-color: transparent;
    }
</style>