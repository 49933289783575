import gql from 'graphql-tag';
import {ContactInfoFragment } from '@/apollo/contact_info/contact_info.fragments';
import { ProfileProductionFragment } from '@/apollo/user/profileProduction/user.profileProduction.fragments';
import { ProfileWorkExperienceFragment } from '@/apollo/user/workExperience/user.workExperience.fragments'
import { EducationFragment } from '@/apollo/user/education/education.fragments';
import { MediaImageMediumFragment } from '../../media/images/image.fragments';
import { MediaLogoAndPlaceholderFragment } from '@/apollo/media/fragments'
import { ProfileJobFragment } from '@/apollo/user/job/user.job.fragments';


export const ProfileFragment = gql `
    fragment ProfileFragment on ProfileType {
        id
        firstName
        middleName
        surname
        primaryJob {
            title
        }
        secondaryJobs {
            edges {
                node {
                    title
                }
            }
        }
        contactinfo {
        ...ContactInfoFragment
        }
        medialibrary{
        ...MediaLogoAndPlaceholderFragment
        }
    }
    ${ContactInfoFragment}
    ${MediaLogoAndPlaceholderFragment}
`

export const ProfileDetailedFragment = gql`
    fragment ProfileDetailedFragment on ProfileType {
            id
            firstName
            middleName
            surname
            dateOfBirth
            bio
            representation
            header
            about
            medialibrary{
                ...MediaLogoAndPlaceholderFragment
                images {
                    edges {
                        node {
                        ...MediaImageMediumFragment
                        }
                    }
                }
            }
            additionalSkills{
              edges{
                node{
                  title
                }
              }
            }
            profileWorkExperience{
                edges {
                    node{
                    ...ProfileWorkExperienceFragment
                    }
                }
            }
            primaryJob{
            ...ProfileJobFragment
            }
            contactinfo{
                ...ContactInfoFragment
            }
            profileProduction{
                edges{
                    node{
                    ...ProfileProductionFragment
                    }
                }
            }
            education{
                edges{
                    node{
                    ...EducationFragment
                    }
                }
            }
            overviewSettings {
                enableVcard
            }
            affiliateCompanies{
                edges{
                    node{
                        id
                        name
                         medialibrary{ 
                             logo{
                                image(size: "540x540", crop: false)
                                croppedImage
                                isCropped
                                uuid
                             }
                         }
                    }
                }
           }
    }
    ${EducationFragment}
    ${ProfileProductionFragment}
    ${ContactInfoFragment}
    ${ProfileWorkExperienceFragment}
    ${MediaImageMediumFragment}
    ${ProfileJobFragment}
    ${MediaLogoAndPlaceholderFragment}
`
