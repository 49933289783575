import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
    state: () => ({
        topLevelCategoryIds: [],
        categories: {},
        categoryCustomProperties: {},
        thing: {},
        statusOptions: [],
        acquisitionOptions: [],
        tagCache: {},
    }),
    mutations: {
        ...mutations
    },
    actions: {
        ...actions
    },
    getters: {
        ...getters
    }

}
