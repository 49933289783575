import gql from "graphql-tag";
import { MedicalContactFragment,MedicalConditionFragment } from "./user.medical.fragments";

export const GET_USER_MEDICAL_CONTACTS = gql`
    query GET_ME{
        me{
            profile{
                profilemedicalinfo{
                    medicalContacts{
                        totalCount
                        edges{
                            node{
                            ...MedicalContactFragment
                            }
                        }
                    }
                }
            }
        }
    }${MedicalContactFragment}
`
export const GET_USER_MEDICAL_CONDITIONS = gql`
    query GET_ME{
        me{
            profile{
                profilemedicalinfo{
                    medicalCondition{
                        totalCount
                        edges{
                            node{
                            ...MedicalConditionFragment
                            }
                        }
                    }
                }
            }
        }
    }${MedicalConditionFragment}
`

export const GET_MEDICAL_CONTACT_RELATIONSHIP_TYPES = gql`
    query GET_MEDICAL_CONTACT_RELATIONSHIP_TYPES{
        medicalContactRelationshipType
    }
`
