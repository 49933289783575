<template>
    <div class="p-40 top-10 w-full flex flex-col">
      <div class="w-full bg-red flex justify-center">
        <p class="text-4xl font-medium">Search results</p>

      </div>
      <CardContainer @onClick="onClickPerson" v-if="people.length > 0" :items="people" >
        <div class="flex my-2">
          <UserIcon class="w-6"/>
          <p class="text-2xl font-medium">People</p>
        </div>
      </CardContainer>
      <CardContainer @onClick="onClickProduction" v-if="productions.length > 0" :items="productions">
        <div class="flex my-2">
          <DocumentIcon class="w-6"/>
          <p class="text-2xl font-medium">Productions</p>
        </div>
      </CardContainer>
      <CardContainer @onClick="onClickCompany" v-if="companies.length > 0" :items="companies">
        <div class="flex my-2">
          <UsersIcon class="w-6"/>
          <p class="text-2xl font-medium">Companies</p>
        </div>
      </CardContainer>
      <CardContainer @onClick="onClickVenue" v-if="places.length > 0" :items="places">
        <div class="flex my-2">
          <UsersIcon class="w-6"/>
          <p class="text-2xl font-medium">Places</p>
        </div>
      </CardContainer>
      <div class="flex items-center justify-center">
        <button @click="closeSearch">Close<XIcon/></button>
      </div>

    </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, ref, watch} from "vue";
import {useRouter} from "vue-router";
import {UserIcon, DocumentIcon, UsersIcon, XIcon} from "@heroicons/vue/outline";
import CardContainer from "@/components/widgets/globalSearchBar/components/CardContainer";

export default {
  name: "SearchResultsPage",
  components: {CardContainer, UserIcon, DocumentIcon, UsersIcon, XIcon},
  props: {
    searchTerm: String,
    reload: Number
  },
  methods:{
    closeSearch(){
      this.$emit('onClose')
    }
  },
  setup(props, {emit}) {
    const store = useStore();
    const router = useRouter();
    const title = ref("")

    function fetchData(){
      store.dispatch('fetchPeople', {first:100, after:"", before:"", searchFilter:props.searchTerm})
      store.dispatch('fetchProductions', {first:100, after:"", before:"", searchFilter:props.searchTerm, sortBy:'', venue:'', space:'', prods: '', type:'', country:'', city:'', company:''})
      store.dispatch('fetchCompanies', {first:100, after:"", before:"", searchFilter:props.searchTerm, sortBy:'', name:'', level:'', type:''})
      store.dispatch('fetchVenues', {first:100, after:"", before:"", searchFilter:props.searchTerm, sortBy:'', venueType:'', country:'', name:''})
    }

    watch(
        () => props.reload,
        () => {
          fetchData()
        }
    )
    fetchData()

    function onClickProfile(name, id){
      router.push({ name:name, params:{id}})
      emit('onClose')
    }

    return {
      title,
      people: computed(() => store.getters.getCardFormattedPeople),
      productions: computed(() => store.getters.getCardFormattedProductions),
      companies: computed(() => store.getters.getCardFormattedCompanies),
      places: computed(() => store.getters.getCardFormattedVenues),
      onClickPerson:(id) => onClickProfile('Profile', id),
      onClickProduction:(id) => onClickProfile('Production', id),
      onClickCompany:(id) => onClickProfile('Company', id),
      onClickVenue:(id) => onClickProfile('Venue', id)

    }
  }
}
</script>
