import gql from 'graphql-tag';
import { REGULAR_THING_FRAGMENT, PROPERTIES_THING_FRAGMENT } from "./things.fragments"

export const CREATE_THING = gql`
    mutation CREATE_THING($input: ThingInput!) {
        thingCreate(input: $input) {
            message
            success
            thing {
                ...REGULAR_THING_FRAGMENT
                ...PROPERTIES_THING_FRAGMENT
            }
        }
    }
    ${REGULAR_THING_FRAGMENT}
    ${PROPERTIES_THING_FRAGMENT}
`

export const EDIT_THING = gql`
    mutation EDIT_THING($input: ThingInput!) {
        thingEdit(input: $input) {
            message
            success
            thing {
                ...REGULAR_THING_FRAGMENT
                ...PROPERTIES_THING_FRAGMENT
            }
        }
    }
    ${REGULAR_THING_FRAGMENT}
    ${PROPERTIES_THING_FRAGMENT}
`

export const ADD_PROP_VALUE = gql`
    mutation THING_ADD_PROPERTY_VALUE($input: ThingPropertyInput!) {
        thingAddPropertyValue(input: $input) {
            message
            success
            propertyValue {
                value
            }
        }
    }
`
