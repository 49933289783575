import gql from "graphql-tag";
import { ProfileJobFragment } from "./user.job.fragments";

export const EDIT_PROFILE_JOB = gql`
    mutation profileJobEdit($input: ProfileJobInput!) {
        profileJobEdit(input: $input) {
            job{
            ...ProfileJobFragment
            }
        }
    }${ProfileJobFragment}
`;

export const CREATE_PRIMARY_JOB = gql `
    mutation primaryJobCreate($input: ProfileJobInput!) {
        primaryJobCreate(input: $input) {
            job{
            ...ProfileJobFragment
            }
        }
    }${ProfileJobFragment}    
`

export const CREATE_SECONDARY_JOB = gql`
  mutation secondaryJobCreate($input: ProfileJobInput!) {
    secondaryJobCreate(input: $input){
      job{
      ...ProfileJobFragment
      }
    }
  }${ProfileJobFragment}
`

export const SET_SECONDARY_JOBS_PRIMARY = gql`
  mutation secondaryJobSetPrimaryMutation($input: ProfileJobInput!) {
    secondaryJobSetPrimaryMutation(input: $input){
      jobs{
      ...ProfileJobFragment
      }
    }
  }${ProfileJobFragment}
`
