import gql from 'graphql-tag'
import { AwardFragment } from './user.award.fragments'

export const CREATE_USER_AWARD = gql`
    mutation profileAwardCreateMutation($input: ProfileAwardInput!) {
        profileAwardCreateMutation(input: $input) {
            award {
            ...AwardFragment
            }
        }
    }${AwardFragment}
`

export const EDIT_USER_AWARD = gql`
    mutation profileAwardEditMutation($input: ProfileAwardInput!) {
        profileAwardEditMutation(input: $input) {
            award {
            ...AwardFragment
            }
        }
    }${AwardFragment}
`
