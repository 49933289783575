export default {
    setUserSkills(state, data) {
        state.skills = data
    },

   addUserSkill(state, skill) {
        state.skills.push(skill)
   },

  removeUserSkillByTitle(state, title){
        state.skills = state.skills.filter(el => el !== title)
   },

}
