import gql from 'graphql-tag';
import {
    FULL_CATEGORY_FRAGMENT,
    PROPERTY_CATEGORY_FRAGMENT,
    REGULAR_THING_FRAGMENT,
    PROPERTIES_THING_FRAGMENT,
    CATEGORIES_THING_FRAGMENT,
    THING_BIG_IMAGES,
    THING_OWNER_FRAGMENT,
    THING_LOCATION_FRAGMENT,
    THING_SMALL_IMAGES,
} from "./things.fragments";

export const GET_THING = gql`
    query GET_THING($uuid: String) {
        thing(uuid: $uuid) {
            ...REGULAR_THING_FRAGMENT
            ...PROPERTIES_THING_FRAGMENT
            ...CATEGORIES_THING_FRAGMENT
            ...THING_BIG_IMAGES
            ...THING_OWNER_FRAGMENT
            ...THING_LOCATION_FRAGMENT
        }
    }

    ${REGULAR_THING_FRAGMENT}
    ${PROPERTIES_THING_FRAGMENT}
    ${CATEGORIES_THING_FRAGMENT}
    ${THING_BIG_IMAGES}
    ${THING_OWNER_FRAGMENT}
    ${THING_LOCATION_FRAGMENT}
`

export const GET_CATEGORY_SEARCH = gql`
    query GET_CATEGORY_SEARCH($name: String) {
        thingCategoryTreeSearch(name: $name)
    }
`

export const GET_CATEGORY = gql`
    query GET_THING_CATEGORY($id: String) {
        thingCategory(id: $id) {
            ...FULL_CATEGORY_FRAGMENT
        }
    }

    ${FULL_CATEGORY_FRAGMENT}
`

export const GET_TOP_LEVEL_CATEGORIES = gql`
    query GET_THING_CATEGORIES{
        thingCategories {
            edges {
                node {
                    ...FULL_CATEGORY_FRAGMENT
                }
            }
        }
    }

    ${FULL_CATEGORY_FRAGMENT}
`

export const GET_CATEGORY_PROPERTIES = gql`
    query GET_THING_CATEGORY($id: String) {
        thingCategory(id: $id) {
            ...PROPERTY_CATEGORY_FRAGMENT
        }
    }

    ${PROPERTY_CATEGORY_FRAGMENT}
`


export const GET_THING_STATUS_OPTIONS = gql`
    query GET_THING_STATUS_UPTIONS{
        thingStatusChoices
    }
`

export const GET_THING_ACQUISITION_OPTIONS = gql`
    query GET_THING_ACQUISITION_OPTIONS{
        thingAcquisitionChoices
    }
`

export const GET_THINGS = gql`
    query GET_THINGS(
        $first: Int!
        $after: String!
        $before: String!
        $searchFilter: String
        $sortBy: String
        $categoryId: String
        $customProperty: String
    ) {
        things (
            first: $first
            after: $after
            before: $before
            search: $searchFilter
            sortBy: $sortBy
            categoryId: $categoryId
            customProperty: $customProperty
        ) {
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
            }
            totalCount
            edges {
                node {
                    ...REGULAR_THING_FRAGMENT
                    ...THING_SMALL_IMAGES
                    ...THING_OWNER_FRAGMENT
                }
            }
        }
    }

    ${REGULAR_THING_FRAGMENT}
    ${THING_SMALL_IMAGES}
    ${THING_OWNER_FRAGMENT}
`

export const THING_CATEGORY_PATH_EXISTS = gql`
    query THING_CATEGORY_PATH_EXISTS($path: String) {
        thingCategoryPathExists(path: $path)
    }
`
