import gql from 'graphql-tag';
import { VenueFragment, StorageFragment, VenueDetailedFragment, SpaceFragment } from './places.fragments';

export const GET_VENUES = gql`
query GET_VENUES(
    $first: Int!
    $after: String!
    $before: String!
    $searchFilter: String!
    $sortBy: String!
    $venueType: String!
    $country: String!
    $name: String!
){
  venues(
    first: $first
    after: $after
    before: $before
    search: $searchFilter
    sortBy: $sortBy
    name: $name
    types: $venueType
    country: $country
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        ...VenueFragment
      }
    }
  }
}${VenueFragment}
`

export const GET_USER_VENUE_NAMES = gql`
query GET_USER_VENUES {
  userVenues {
    edges {
      node {
        id
        name
      }
    }
  }
}
`

export const GET_SPACES = gql `
query GET_SPACES(
    $first: Int!
    $after: String!
    $before: String!
    $searchFilter: String!
    $sortBy: String!
    $configType: String!
){
  spaces(
    first: $first
    after: $after
    before: $before
    search: $searchFilter
    sortBy: $sortBy
    configuration: $configType
  ) {
    edges {
      node {
      ...SpaceFragment
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}${SpaceFragment}
`

export const GET_CAL_VENUES = gql `
 query getCalVenues{
    venues{
        edges{
            node{
            name
            id
         }}}}
`

export const GET_CAL_SPACES = gql `
 query getCalSpaces{
    spaces{
        edges{
            node{
            name
            id
         }}}}
`

export const GET_VENUE = gql `
    query getVenue($id: ID){
        venue(id:$id){
        ...VenueDetailedFragment
        }
    }${VenueDetailedFragment}
`

export const GET_VENUE_SPACES = gql`
query getVenue($id: ID) {
  venue(id: $id) {
    spaces {
      edges {
        node {
        ...SpaceFragment
        }
      }
    }
  }
}${SpaceFragment}
`

export const GET_SPACE_STORAGES = gql`
query GET_SPACE($id: ID) {
  space(id:$id) {
    storages {
    ...StorageFragment
    }
  }
}${StorageFragment}
`

export const GET_STORAGE = gql`
query GET_STORAGE($id: ID!) {
  storage(id: $id) {
    ...StorageFragment
  }
}${StorageFragment}
`

export const GET_VENUE_TYPES = gql `
    query getVenueTypes{
        venueTypes
    }
`

export const GET_SPACE_TYPES = gql`
    query getSpaceTypes{
        spaceTypes
    }
`

export const GET_VENUE_FILTERS = gql`
    query getVenueFilters{
        configTypes
        venueTypes
        countries
    }
`

export const GET_SPACE_FILTERS = gql`
    query getSpaceFilters{
        configTypes
        countries
    }
`
