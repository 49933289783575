
export default {
    basicVenue(state){
        return state.basicVenue
    },
    getTableFormattedVenues(state){
        return state.venues.map(item => {
            return {
                venue: item.name,
                id: item.id,
                type: item.venueType.name,
                location: item.country
            }
        })
    },
    getCardFormattedVenues(state){
        return state.venues.map(venue => {
            return {
                id: venue.id,
                image: venue.image,
                title: venue.name,
                subtitle: venue.country,
                url: '/venue/'+venue.id
            }
        })
    },

    getVenues(state){
        return state.venues
    },
    getBasicVenues(state) {
        return state.basicVenues === undefined ? [] : state.basicVenues
    },
    getUserVenueNames(state) {
        return state.userVenueNames === undefined ? [] : state.userVenueNames
    },
    getSpace(state) {
        return state.space
    },
    getSpaces(state){
        return state.spaces
    },
    getVenueSpaces: state => id => {
        const store = state.venueSpaces ?? {}
        return store[id] ?? []
    },
    getSpaceStorages: state => id => {
        const store = state.spaceStorages ?? {}
        return store[id] ?? []
    },
    getStorageStorages: state => id => {
        const store = state.storageStorages ?? {}
        return store[id] ?? []
    },
    getStorage: state => id => {
        // if (id === null || id === undefined) return undefined
        const storages = state.allStorages ?? {}
        return storages[id.toString()]
    },
    getCalVenues(state){
        return state.calVenues
    },
    getCalSpaces(state){
        return state.calSpaces
    },

    getVenueTypes(state) {
        return state.venueTypes
    },
    getSpaceTypes(state) {
        return state.spaceTypes
    },
    getVenueSortBy(state){
        return state.sortBy
    },
    getSpaceSortBy(state){
        return state.sortBy
    },
    getVenue(state){
        return state.venue
    },
    getVenueId(state) {
        return state.venue.id
    },
    getVenueHeader(state) {
        return {
            title: state.venue.name,
            subTitle: (state.venue.venueType)?state.venue.venueType.name:'Venue type',
            location: (state.venue.country)?state.venue.country:'Town/city',
            website:'showdeck.io',
            email:'info@showdeck.io',
            phone:'+1 7896541230',
            addresses:[{'city':'Town/city','isPrimary':true}],
            logo: state.venue.logo,
			socials:[{'id':'1','isPrimary':true,'network':'Facebook','logo':'https://s3.eu-west-1.amazonaws.com/showdeck.io/static/assets/img/social_media_icons/black/facebook.svg','url':'https://facebook.com/facebook','username':'Facebook'},{'id':'2','isPrimary':true,'network':'Twitter','url':'https://facebook.com/facebook','username':'Twitter','logo':'https://s3.eu-west-1.amazonaws.com/showdeck.io/static/assets/img/social_media_icons/black/twitter.svg'},{'id':'3','isPrimary':true,'network':'Pinterest','logo':'https://s3.eu-west-1.amazonaws.com/showdeck.io/static/assets/img/social_media_icons/black/pinterest.svg','url':'https://pinterest.com/','username':'Pinterest'}]
        }
    },

    getVenueEmail(state) {
        return state.venue.contact.email
    },
    getVenuePhone(state) {
        return state.venue.contact.phone
    },
    getVenueWebsite(state){
        return state.venue.contact.website
    },
    getVenueAddress(state){
        return state.venue.contact.address
    },
    getVenueName(state){
        return state.venue.name
    },
    getVenueCreatedBy(state){
        return state.venue.createdBy
    },
    getVenueFilters(state){
        return state.filters
    },
    getSpaceFilters(state){
        return state.filters
    },
    getVenueFilterValues(state){
        const obj = {}
        for(let i in state.filters){
            obj[state.filters[i].title] = state.filters[i].value
        }
        return obj
    },
    getSpaceFilterValues(state){
        const obj = {}
        for(let i in state.filters){
            obj[state.filters[i].title] = state.filters[i].value
        }
        return obj
    },

}