export default {
    setAddMemberWidgetSearchParam(state, data){
        state.searchParam = data
    },
    setAddMemberWidgetValues(state, data){
        state.totalCount = data.totalCount
        state.hasNextPage = data.hasNextPage
        state.endCursor = data.endCursor
    },
    resetAddMemberWidgetState(state){
            state.currentCursor = ''
            state.endCursor = ''
            state.after = ''
            state.before = ''
            state.hasNextPage = false
            state.page = 0
            state.totalCount = 0
            state.resultCount = 12
            state.searchParam = ''
            state.fields = []
            state.items = []
    }
}