export default {
    setEmails(state, data){
        state.emails = data
    },
    appendEmail(state, email){
        state.emails.push(email)
    },
    removeEmailErrorProperty(state){
        state.emails.forEach(obj => delete obj.error)
    },
    addNewEmail(state){
        state.emails.push({label: '', email: ''})
    },
    editEmailByIdx(state, {value, key, idx}){
        const email = state.emails[idx]
        email[key] = value
    },
    removeEmailByIdx(state, idx){
        state.emails = state.emails.filter((email, i) => idx !== i)
    },
    refreshEmailPrimaries(state, primaryIds) {
        for(let i=0; i<state.emails.length; i++) {
            let email = state.emails[i]
            if(primaryIds.includes(email.id)) {
                email.isPrimary = true
            } else {
                email.isPrimary = false
            }
        }
    },
    setAllEmailPrimaryToFalse(state){
        for (let i = 0; i < state.emails.length; i++) {
            state.emails[i].isPrimary = false
        }
    }
}
