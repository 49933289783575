import { apolloClient } from '@/apollo/apolloClient'
import {
    GET_VENUE,
    GET_VENUE_TYPES,
    GET_VENUES,
    GET_VENUE_FILTERS,
    GET_SPACES,
    GET_SPACE_FILTERS,
    GET_CAL_SPACES,
    GET_CAL_VENUES,
    GET_SPACE_TYPES,
    GET_USER_VENUE_NAMES,
    GET_VENUE_SPACES,
    GET_SPACE_STORAGES,
    GET_STORAGE,
} from "../../../apollo/entities/places/places.queries";
import {
    mediaEncoder,
    venueFormatter,
    singleVenueFormatter,
    spaceFormatter,
    singleSpaceFormatter,
    calVenueFormatter,
    calSpaceFormatter,
    singleStorageFormatter,
} from "../../formatters";
import RemoveFalsy from "../../../utils/RemoveFalsy";
import { CREATE_SPACE, CREATE_STORAGE, CREATE_VENUE, EDIT_VENUE } from "../../../apollo/entities/places/places.mutations";
import {
    CREATE_EMAIL,
    CREATE_PHONE,
    CREATE_WEBSITE,
    EDIT_EMAIL,
    EDIT_PHONE,
    EDIT_WEBSITE
} from "@/apollo/contact_info/contact_info.mutations";
import ID_DECODER from "../../../utils/ID_DECODER";
import {CREATE_ADDRESS} from "../../../apollo/contact_info/contact_info.mutations";
import AutoIdDecoder from '../../../utils/AutoIdDecoder';
import AutoTypenameRemover from '../../../utils/AutoTypenameRemover.js';


export default {
    async getBasicVenues(state) {
        const res = await apolloClient.query({
            query: GET_VENUES, variables: {
                first: 5,
                after: "",
                before: "",
                searchFilter: "",
                sortBy: "",
                venueType: "",
                country: "",
                name: "",
            }
        })
        if (res.data.venues) {
            state.commit('setBasicVenues', venueFormatter(res.data.venues))
        }
    },

    async fetchVenues(state,params){
        try {
            const res = await apolloClient.query({
                query: GET_VENUES,
                variables: params
            })
		
            await state.commit('setVenues', venueFormatter(res.data.venues))
            await state.commit('setDynamicTableItems', state.getters.getVenues)
            return {venue:venueFormatter(res.data.venues),pageInfo: res.data.venues.pageInfo, totalCount:res.data.venues.totalCount}
        }catch (e) {
            console.error(e)
        }
    },
    async fetchCalVenues(state,params){
        try {
            const res = await apolloClient.query({
                query: GET_CAL_VENUES,
                variables: params
            })
            await state.commit('setCalVenues', calVenueFormatter(res.data.venues))
        }catch (e) {
            console.error(e)
        }
    },

    async fetchUserVenueNames(state) {
        const res = await apolloClient.query({
            query: GET_USER_VENUE_NAMES, variables: {
                first: 100,
                after: "",
                before: "",
                searchFilter: "",
                sortBy: "",
                venueType: "",
                country: "",
                name: "",
            }
        })
        if (res.data.userVenues) {
            const edges = res.data.userVenues.edges
            state.commit('setUserVenueNames', AutoIdDecoder(edges.map(item => item.node)))
        }
        else {
            console.log("res", res)
            throw new Error("Couldn't fetch the user venues.")
        }
    },

    async fetchPlaceSpaceStorageChildren(state, { id, type }) {
        if (type === "venue") {
            const res = await apolloClient.query({ query: GET_VENUE_SPACES, variables: { id: id } })
            if (res.data.venue) {
                const data = AutoTypenameRemover(AutoIdDecoder(res.data.venue.spaces.edges.map(item => item.node)))
                state.commit("setVenueSpaceStorageChildren", { id, type, data })
            }
        }
        else if (type === "space") {
            const res = await apolloClient.query({ query: GET_SPACE_STORAGES, variables: { id: id } })
            if (res.data.space) {
                const data = res.data.space.storages.map(item => singleStorageFormatter(item))
                state.commit("setVenueSpaceStorageChildren", { id, type, data })
            }
        }
        else if (type === "storage") {
            const storage = await state.dispatch("fetchStorage", id)
            state.commit("setVenueSpaceStorageChildren", { id, type, data: storage.children })
        }
        else {
            throw new Error(`Unknown type "${type}". Expected one of "venue", "space" or "storage"`)
        }
    },

    async fetchSpaces(state,params){
        try {
            const res = await apolloClient.query({
                query: GET_SPACES,
                variables: params
            })
            await state.commit('setSpaces', spaceFormatter(res.data.spaces))
            await state.commit('setDynamicTableItems', state.getters.getSpaces)
            return {...res.data.spaces.pageInfo, totalCount: res.data.spaces.totalCount}
        }catch (e) {
            console.error(e)
        }
    },

    async fetchCalSpaces(state,params){
        try {
            const res = await apolloClient.query({
                query: GET_CAL_SPACES,
                variables: params
            })
            await state.commit('setCalSpaces', calSpaceFormatter(res.data.spaces))
        }catch (e) {
            console.error(e)
        }
    },

    async getVenue(state, id){
        console.log('getVenue. id:', id);
        if (id === null) return;
        try {
            const res = await apolloClient.query({
                query: GET_VENUE,
                variables: {id: id},
            })
            state.commit('setVenue', singleVenueFormatter(res.data.venue))

        } catch (e) {
            console.error(e)
        }
    },

    async getVenueTypes(state){
        try {

            const res = await apolloClient.query({
                query: GET_VENUE_TYPES

            })
            if (res.data.venueTypes){
                state.commit('setVenueTypes', res.data.venueTypes)
            }

        } catch (e) {
            console.error(e)
        }

    },

    async createVenue(state, form){
        let data = RemoveFalsy({...form})
        if('logo' in data){
            try {
                data = {...data, file: await mediaEncoder(data['logo'])}
            } catch (e) {
                data
            }

            delete data['logo']
        }
            delete data['contact']
            delete data['email']
            delete data['phone']
            delete data['website']
            delete data['address']

        if (state.getters.getVenue.id !== null){
            await state.dispatch('editVenue', {data, form})
            return
        }
        try {
            data.profileId = state.getters.getId
            const res = await apolloClient.mutate({
                mutation: CREATE_VENUE,
                variables: {input: data}
            })
            if(res.data.venueCreate.venue){
                await state.commit('setVenue', singleVenueFormatter(res.data.venueCreate.venue))
                await state.dispatch('saveVenueEmail', {email:form.contact.email, id:ID_DECODER(res.data.venueCreate.venue.id)})
                await state.dispatch('saveVenuePhone', {phone:form.contact.phone, id:ID_DECODER(res.data.venueCreate.venue.id)})
                //await state.dispatch('saveVenueAddress', {address:form.contact.address, id:ID_DECODER(res.data.venueCreate.venue.id)})
                await state.dispatch('saveVenueWebsite', {url:form.contact.website, id:ID_DECODER(res.data.venueCreate.venue.id)})
            } else {
                console.log('SOMETHING WENT WRONG')
            }

        } catch (e) {
            console.error(e)
        }
    },

    async editVenue(state, input){
        const { data, form } = input
        const id = state.getters.getVenueId
        try {
            const res = await apolloClient.mutate({
                mutation: EDIT_VENUE,
                variables: {input: {...data, id:id}}
            })
            if(res.data.venueEdit.venue){
                await state.dispatch('editVenueEmail', form.contact.email)
                await state.dispatch('editVenuePhone', form.contact.phone)
                await state.dispatch('editVenueWebsite', form.contact.website)
                //await state.dispatch('editVenueAddress', form.contact.address)
            } else {
                console.log('SOMETHING WENT WRONG')
            }

        } catch (e) {
            console.error(e)
        }
    },

    async venuesPageNext(state){
        await state.commit('setNextPage')
        await state.dispatch('dynamicTableLoadVenues')
    },

    async venuesPagePrevious(state){
        await state.commit('setPreviousPage')
        await state.dispatch('dynamicTableLoadVenues')
    },

    async saveVenueEmail(state, bundle){
        const { email, id } = bundle
        const name = state.getters.getVenueName
        try{
            await apolloClient.mutate({
                mutation: CREATE_EMAIL,
                variables: {input: {label:name, venueId:id, email:email}}
            })
        }catch (e) {
            console.error(e)
        }
    },

    async editVenueEmail(state, email){
        const id = state.getters.getVenueEmail.id

        try {
            await apolloClient.mutate({
                mutation: EDIT_EMAIL,
                variables: {input: { emailId: id, email:email}}
            })
        }catch (e) {
            console.error(e)
        }
    },

    async saveVenuePhone(state, bundle){
        const { phone, id } = bundle
        const name = state.getters.getVenueName
        try{
            await apolloClient.mutate({
                mutation: CREATE_PHONE,
                variables: {input: {label:name, venueId:id, phoneNumber:phone}}
            })
        }catch (e) {
            console.error(e)
        }
    },

    async editVenuePhone(state, phone){
        const id = state.getters.getVenuePhone.id
        try {
            await apolloClient.mutate({
                mutation: EDIT_PHONE,
                variables: {input: { phoneId: id, phoneNumber:phone}}
            })
        }catch (e) {
            console.error(e)
        }
    },

    async saveVenueWebsite(state, bundle){
        const { url, id } = bundle
        const name = state.getters.getVenueName
        try{
            await apolloClient.mutate({
                mutation: CREATE_WEBSITE,
                variables: {input: {title:name, venueId:id, url:url}}
            })
        }catch (e) {
            console.error(e)
        }
    },

    async editVenueWebsite(state, url){
        const id = state.getters.getVenueWebsite.id
        try {
            await apolloClient.mutate({
                mutation: EDIT_WEBSITE,
                variables: {input: { websiteId: id, url:url}}
            })
        }catch (e) {
            console.error(e)
        }
    },

    async saveVenueAddress(state, bundle){
        const { address, id } = bundle
        const name = state.getters.getVenueName
        try{
            await apolloClient.mutate({
                mutation: CREATE_ADDRESS,
                variables: {input: {label:name, venueId:id, address:address}}
            })
        }catch (e) {
            console.error(e)
        }
    },

    async fetchVenueFilters(state){
        try{
            const res = await apolloClient.query({
                query: GET_VENUE_FILTERS
            })

            state.commit('setVenueFilters', res.data)
        }catch (e) {
            console.error(e)
        }
    },

    async createSpace(state, form) {
        let data = RemoveFalsy({ ...form })

        // Add the image if it is in the JSON, currently not implemented in the graphql API
        if ('logo' in data) {
            try {
                data = { ...data, file: await mediaEncoder(data['logo']) }
            } catch (e) {
                data
            }

            delete data['logo']
        }

        // Remove the accessDesc if the space has level access
        if (data.access && data.accessDesc !== undefined)
            delete data.accessDesc

        // TODO: Implement editSpace
        // Switch to editing a space if a ID was supplied
        if (state.getters.getSpace.id !== null) {
            console.error("Unimplemented editSpace")
            // await state.dispatch('editSpace', { data, form })
        }
        // Continue creating a new space if there is no ID
        else {
            try {
                const res = await apolloClient.mutate({
                    mutation: CREATE_SPACE,
                    variables: { input: data }
                })
                if (res.data.spaceCreate.space) {
                    await state.commit('setSpace', singleSpaceFormatter(res.data.spaceCreate.space))
                } else {
                    console.error('SOMETHING WENT WRONG', res)
                }

            } catch (e) {
                console.error(e)
            }
        }
    },


    async getSpaceTypes(state) {
        try {
            const res = await apolloClient.query({ query: GET_SPACE_TYPES })
            if (res.data.spaceTypes) state.commit('setSpaceTypes', res.data.spaceTypes)
            else console.error("Failed to fetch spaces.", res)
        } catch (e) {
            console.error(e)
        }
    },

    async fetchSpaceFilters(state){
        try{
            const res = await apolloClient.query({
                query: GET_SPACE_FILTERS
            })

            state.commit('setSpaceFilters', res.data)
        }catch (e) {
            console.error(e)
        }
    },

    async spacesPageNext(state){
        await state.commit('setNextPage')
        await state.dispatch('dynamicTableLoadSpaces')
    },

    async spacesPagePrevious(state){
        await state.commit('setPreviousPage')
        await state.dispatch('dynamicTableLoadSpaces')
    },

    async fetchStorage(state, id) {
        const res = await apolloClient.query({ query: GET_STORAGE, variables: { id } })
        if (res.data.storage) {
            const formattedData = singleStorageFormatter(res.data.storage)
            state.commit("setStorage", formattedData)
            // Cache the children as well in case the storage needs to be used in a tree
            state.commit("setVenueSpaceStorageChildren", { id, type: "storage", data: formattedData.children })
            return formattedData
        }
        else throw new Error(`Failed to fetch storage with the ID "${id}".`)
    },

    async createStorage(state, form) {
        let data = RemoveFalsy({ ...form })

        // Remove the venueId as it isn't needed anymore
        if (data.venueId) {
            delete data["venueId"]
        }

        // Add the image if it is in the JSON, currently not implemented in the graphql API
        if ('logo' in data) {
            try {
                data = { ...data, file: await mediaEncoder(data['logo']) }
            } catch (e) {
                data
            }

            delete data['logo']
        }

        // Save the storage
        try {
            const res = await apolloClient.mutate({
                mutation: CREATE_STORAGE,
                variables: { input: data }
            })
            if (res?.data?.storageCreate?.storage) {
                return res.data.storageCreate.storage
                // Save the new storage in the cache
                // await state.commit('setStorage', singleStorageFormatter(res.data.storageCreate.storage))
            } else {
                console.error('SOMETHING WENT WRONG', res)
            }

        } catch (e) {
            console.error(e)
        }
    },
}
