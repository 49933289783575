import gql from 'graphql-tag'

export const AwardFragment = gql`
    fragment AwardFragment on ProfileAwardType {
        id
        production
        award 
        category
        date
        won
    }
`
