import gql from "graphql-tag";
import { ProfileProductionFragment } from "./user.profileProduction.fragments";

export const CREATE_PROFILE_PRODUCTION = gql`
    mutation profileProductionCreateMutation($input: ProfileProductionInput!){
        profileProductionCreateMutation(input:$input){
            production{
            ...ProfileProductionFragment
            }
        }
    }${ProfileProductionFragment}
`



export const EDIT_PROFILE_PRODUCTION = gql`
    mutation profileProductionEditMutation($input: ProfileProductionInput!){
        profileProductionEditMutation(input:$input){
            production{
            ...ProfileProductionFragment
            }
        }
    }${ProfileProductionFragment}
`


