
export default {
    async editUserMediaImage(state, params) {
        const img = await state.dispatch("editMediaImage", params)
        state.commit('replaceUserImage', img)
        if (params.logo) {
            state.commit('setUserProfilePicture', img.croppedImage)
            state.commit('setProfilePicture', img.croppedImage)
        }
    },
    async createUserMediaImage(state, params) {
        const img = await state.dispatch("createMediaImage", params)
        if (params.logo==true) {
            state.commit('setUserProfilePicture', img.croppedImage)
            state.commit('setProfilePicture', img.croppedImage)
        }
        state.commit("addUserImageToAlbum", img)
    }

}



