import getters from './getters'
import mutations from './mutations'

export default {
    state: () => ({
        currentCursor: '',
        endCursor: '',
        after: '',
        before: '',
        hasNextPage: false,
        page: 0,
        totalCount: 0,
        resultCount: 12,
        searchParam: '',
        fields: [],
        items: []
    }),
    mutations: {
        ...mutations
    },
    getters: {
        ...getters
    }
}