import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
    state: () => ({
        loading: false,
        darkMode: false,
        searchBox: false,
        shouldOpenSearchBox: true,
        countries: [],
        socialTypes: [],
        documentTypes: [],
        departmentTypes: [],
        profileJobs: [],
        profileSkills: [],
        ethnicities: [],
        religions: [],
        sexualities: [],
        sexes: [],
        genders: [],
        disabilities: [],
        caringResponsibilities: []
    }),
    mutations: {
        ...mutations
    },
    getters: {
        ...getters
    },
    actions: {
        ...actions
    }
}
