import gql from "graphql-tag";
import { DiversityFragment } from "./diversity.fragments";

export const EDIT_USER_DIVERSITY = gql`
    mutation profileDiversityEdit($input: ProfileDiversityInput!) {
        profileDiversityEdit(input: $input){
            diversity{
            ...DiversityFragment
            }
        }
    }${DiversityFragment}
`
