import gql from 'graphql-tag';

export const MediaVideoFragment = gql`
    fragment MediaVideoFragment on MediaVideoType {
        uuid
        customThumbnail
        thumbnail
        player
        title
        caption
        url
    }
`
