import gql from 'graphql-tag';
import { CompanyMemberFragment, CompanyFragment, CompanyDetailedFragment, SeasonFragment } from './company.fragments';
import { MediaLogoAndPlaceholderFragment } from "@/apollo/media/fragments";

export const GET_COMPANIES = gql`
    query getCompanies(
        $first: Int!
        $after: String!
        $before: String!
        $searchFilter: String!
        $sortBy: String!
        $name: String!
        $level: String!
        $type: String!
    ){ 
        companies(
            first: $first
            after: $after
            before: $before
            search: $searchFilter
            sortBy: $sortBy
            name: $name
            levels: $level
            types: $type
        ){
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
            }
            totalCount
            edges{
                node{
                    ...CompanyFragment
                }
            }
        }
    }${CompanyFragment}
`

export const GET_COMPANY = gql`
    query getCompany(
        $id: ID
    ){
        company(id:$id){
            ...CompanyDetailedFragment
        }
    }${CompanyDetailedFragment}
`

export const GET_SEASON = gql`
    query getSeason(
        $id: ID
    ){
        season(id:$id){
            ...SeasonFragment
        }
    }${SeasonFragment}
`


export const GET_COMPANY_TYPES = gql`
    query getCompanyTypes{
        companyObjTypes
    }
`

export const GET_COMPANY_LEVELS = gql`
    query getCompanyTypes{
        companyObjLevels
    }
`

export const GET_COMPANY_FILTERS = gql`
    query getCompanyFilters{
        companyObjTypes
        companyObjLevels
    }
`

export const GET_COMPANY_MEMBERS_DISTINCT = gql`
    query getCompanyMembersDistinct(
        $companyId: Float!
        $first: Int!
        $after: String!
        $before: String!
        $searchFilter: String
){
  companyMembersDistinct(
            companyId: $companyId
            first: $first
            after: $after
            before: $before
            search: $searchFilter
  ){
    pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          }
    totalCount
    edges{
      node{
        id
        companymemberjobSet{
            edges{
                node{
                    job{
                    title
                    }
                }
            }
        }
        department{
            name
        }
        member{
            profile{
                id
                firstName
                middleName
                surname
                placeholder(size: "255x255")
                medialibrary{
                    ...MediaLogoAndPlaceholderFragment
                }
            }
        }
        allJobs(companyId: $companyId) {
            edges {
              node {
                id
                job {
                  title
                }
                member {
                  dateStarted
                  dateLeft
                  department {
                    name
                  }
                }
              }
            }
          }

        }          
      }              
    } 
  }${MediaLogoAndPlaceholderFragment}
`

export const GET_COMPANY_MEMBERS = gql`
   query getCompanyMembers(
        $companyId: Float!
        $first: Int!
        $after: String!
        $before: String!
        $searchFilter: String
    ){
        companyMembers(
            companyId: $companyId
            first: $first
            after: $after
            before: $before
            search: $searchFilter
        ){
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                }
            totalCount
            edges{
                node{
                    ...CompanyMemberFragment                  
                }
            }
        }
    }${CompanyMemberFragment}
`
