<template>
  <div id="modal">
    <transition name="modal-animation">
      <teleport :to="teleport">
        <div
          v-if="modalActive"
          class="fixed w-screen inset-0 inset-0 top-0 left-0 right-0 overflow-x-hidden overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
          :class="modalOverFlow"
        >
          <div
            class="flex items-center h-screen justify-center text-center sm:block sm:p-0"
          >
            <div
              @click="closeModal"
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <!-- This element is to trick the browser into centering the modal contents. -->
            <span
              class="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
              >&#8203;</span
            >
            <!-- overflow-scroll overflow-y-scroll max-h-4/5 h-auto -->
            <div
              @click.stop
              :class="modalClasses()"
              class="p-4 lg:p-0 lg:w-2/5 sm:w-full overflow-hidden inline-block rounded-lg transform w-screen transition-all sm:align-middle overflow-visible"
            >
              <slot class=""></slot>
            </div>
          </div>
        </div>
      </teleport>
    </transition>
  </div>
</template>
<script>
import { watch } from "vue";
import registerEvents from "../../utils/DisableSearchBoxInInputs";
export default {
  props: {
    modalActive: Boolean,
    version: {
      required: false,
      default: "1",
    },
    backdrop: {
      required: false,
      default: true,
    },
    teleport: {
      required: false,
      default: "#root",
    },
    modalOverFlow:{
      type: String,
        default:'z-5',
    }
  },
  mounted() {
    // Close modal with 'esc' key
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 27) {
        this.$emit("close");
      }
    });
  },
  methods: {
    modalClasses() {
      setTimeout(registerEvents, 500);
      if (this.version == "2") return "lg:w-4/5 xl:w-2/5";
      if (this.version == "3") return "lg:w-3/5 2xl:w-2/5";
      if (this.version == "4") return "lg:w-4/5 2xl:w-3/5";
      if (this.version == "5") return "max-w-4xl lg:w-full 2xl:w-full";
      if (this.version == "6") return "max-w-2xl lg:w-full 2xl:2/6";
      return "lg:w-2/5";
    },
    closeModal() {
      if (this.backdrop) this.$emit("close");
    },
  },
  setup(props, { emit }) {
    function escPress() {
      emit("close");
    }
    watch(
      () => props.modalActive,
      (modalOn) => {
        if (modalOn) {
          window.addEventListener("keydown", (e) => {
            if (e.key == "Escape") escPress();
          });
        } else {
          window.removeEventListener("keydown", (e) => {
            if (e.key == "Escape") escPress();
          });
        }
      }
    );
    return {
      test() {
        console.log("ran");
      },
    };
  },
};
</script>
<style scoped>
.modal-animation-enter-active,
.modal-animation-leave-active {
  transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-animation-enter-from,
.modal-animation-leave-to {
  opacity: 0;
}
.modal-animation-inner-enter-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}
.modal-animation-inner-leave-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-animation-inner-enter-from {
  opacity: 0;
  transform: scale(0.8);
}
.modal-animation-inner-leave-to {
  transform: scale(0.8);
}
.newClass {
  overflow: hidden;
  overflow-y: scroll;
  height: auto;
  /* min-height: 200px; */
  max-height: calc(100vh - 158px);
}

</style>
