import gql from 'graphql-tag';

export const EDIT_EVENT = gql`
    mutation deleteEvent($id: ID!) {
      eventDelete(id: $id) {
          event {
          id
          title
          }
      }
    }
    `

export const DELETE_EVENT = gql`
    mutation deleteEvent($id: ID!) {
      eventDelete(id: $id) {
          event {
          id
          title
          }
      }
    }
    `

export const CREATE_EVENT = gql `
    mutation createEvent($input: EventInput!){
        eventCreate(input: $input){
            event{
                id
                title
                notes
                startTime
                eventType
                endTime
                public
                company{
                    id
                    name
                }
                space{
                    id
                    name
                }
                venue{
                    id
                    name
                } 
                production{
                    id
                    title
                }
            }
        }
    }
`