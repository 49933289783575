import gql from 'graphql-tag';
import { StorageFragment, SpaceFragment, VenueFragment } from './places.fragments';

export const CREATE_VENUE = gql `
    mutation createVenue($input: VenueInput!){
        venueCreate(input: $input){
            venue{
            ...VenueFragment
            }
        }
    }${VenueFragment}
`

export const EDIT_VENUE = gql`
    mutation editVenue($input: VenueInput!){
        venueEdit(input: $input){
            venue{
            ...VenueFragment
            }
        }
    }${VenueFragment}
`

export const CREATE_SPACE = gql`
    mutation createSpace($input: SpaceInput!) {
        spaceCreate(input: $input) {
            space {
            ...SpaceFragment
            }
        }
    }${SpaceFragment}
`

export const CREATE_STORAGE = gql`
    mutation CREATE_STORAGE($input: StorageInput!){
        storageCreate(input: $input){
            success
            message
            storage{
            ...StorageFragment
            }
        }
    }${StorageFragment}
`
