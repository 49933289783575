import { apolloClient } from "@/apollo/apolloClient";
import {
    GET_COUNTRIES, GET_PROFILE_SKILLS,
    GET_DEPARTMENT_TYPES, GET_DIVERSITY_OPTIONS,
    GET_DOCUMENT_TYPES, GET_PROFILE_JOBS,
    GET_SOCIALS
} from "@/apollo/application/application.queries";
import {departmentTypeFormatter, documentTypeFormatter} from "@/store/formatters";

export default {
    async fetchCountries(state){
        try {
            const res = await apolloClient.query({
                query: GET_COUNTRIES
            })

            state.commit('setCountries', res.data.countries)
        } catch (e) {
            console.error(e)
        }
    },

    async fetchSocialTypes(state){
        try{
            const res = await apolloClient.query({
                query: GET_SOCIALS
            })
            state.commit('setSocialTypes', res.data.socials)
        } catch (e) {
            console.error(e)
        }
    },

    async fetchDocumentTypes(store){
        try{
            const res = await apolloClient.query({
                query: GET_DOCUMENT_TYPES
            })
            store.commit('setDocumentTypes', documentTypeFormatter(res.data.documentTypes))
        } catch (e) {
            console.error(e)
        }
    },

    async fetchDepartmentTypes(store){
        try{
            const res = await apolloClient.query({
                query: GET_DEPARTMENT_TYPES
            })
            store.commit('setDepartmentTypes', departmentTypeFormatter(res.data.departmentTypes.edges))
        } catch (e) {
            console.error(e)
        }
    },

    async fetchProfileJobs(store, searchTerm=""){
        try{
            const res = await apolloClient.query({
                query: GET_PROFILE_JOBS,
                variables: { title: searchTerm }
            })
            store.commit('setProfileJobs', res.data.profileJobs)
        } catch (e) {
            console.error(e)
        }
    },

    async fetchProfileSkills(store, searchTerm=""){
        try{
            const res = await apolloClient.query({
                query: GET_PROFILE_SKILLS,
                variables: { title: searchTerm }
            })
            store.commit("setProfileSkills", res.data.profileSkills)
        } catch (e){
            console.error(e)
        }
    },

    async fetchDiversityOptions(store){
        try {
            const res = await apolloClient.query({
                query: GET_DIVERSITY_OPTIONS
            })
            store.commit('setDiversityOptions', res.data)
        } catch (e) {
            console.error(e)
        }
    },

   async changeSearchBoxState(store){
       await store.commit('setSearchBoxState');
    },

    async setShouldOpenSearchBox(store , data){
       await store.commit('setShouldOpenSearchBox', data);
    }
}
