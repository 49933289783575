import gql from "graphql-tag";


export const SIGNUP = gql`
    mutation Signup($input: SignupInput!) {
        signup(input: $input) {
            message
            success
        }
    }
`;

// Old login
export const LOGIN_USER = gql`
    mutation LoginUser($email: String!, $password: String!) {
        loginUser(email: $email, password: $password) {
            token
            message
        }
    }
`;

// New login
export const LOGIN = gql`
    mutation Login($email: String!, $password: String!) {
        tokenAuth(email: $email, password: $password) {
            token
            payload
            refreshToken
            refreshExpiresIn
        }
    }
`

export const FORGOT_PASSWORD = gql`
    mutation ForgotPassword($input: ForgotPasswordInput!) {
        forgotPassword(input: $input) {
            success
            message
        }
    }
`
export const RESET_PASSWORD = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input) {
            success
            message
        }
    }
`
export const SHOW_ONBOARDING_MUTATION = gql`
mutation SHOW_ONBOARDING_MUTATION ($input: ShowOnboardingInput!) {
    showOnboardingMutation(input: $input) {
        user {
            showOnboarding
        }
    }
}`

export const VERIFY_EMAIL = gql`
mutation VerifyEmail($input: VerifyEmailInput!) {
  verifyEmailMutation(input: $input) {
      success
      message
    }
}`

