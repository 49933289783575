<template>
            <!-- IMAGE MODAL -->
          <Modal version="6"  @close="$emit('close');" :modalOverFlow="modalOverFlow" :modalActive="imagePoiSelectorOn"   @change="openModal=true">
              <div class="dark:bg-black no-scroll-bar overflow-y-scroll overflow-hidden h-auto max-h-100 lg:max-h-auto bg-white rounded-lg flex flex-col p-7 flex flex-col z-90  overflow-y-scroll overflow-hidden h-auto max-h-100 ">
                  <LoadingSpinner v-if="isLoading" />
                    <div v-else>
                      <!-- <h1 class="text-2xl font-semibold text-gray-700">Edit Profile Picture</h1> -->
                     
                      <ImagePoiInput
                            @submit="submitPhoto"
                            @close="closePhotoWidget"
                            @change="openModal=true"
                           
                        />
                      <div class="grid grid-cols-4   md:grid-cols-6 gap-4 mb-4"  v-if="!openModal">
                      <div class="relative main_block  h-16	sm:h-24	"  v-for="img in profilePictures" :key="img.uuid" v-show="img.croppedImage">
                        <div  class="absolute hidden action_button right-0 p-0 cursor-pointer">
                            <div class="flex text-white dark:text-white">
                                <TrashIcon @click="deleteEntry(img.uuid)" class="w-4 mt-1 mx-1" />
                            </div>
                        </div>
                          <img
                          
                            :key="img.uuid"
                            :src="img.croppedImage"
                            @click="selectPhotoAsLogo(img.uuid)"
                            class="col-span-1 cursor-pointer w-full h-full object-cover	"
                            />
                      </div>
                      </div>
                      <button @click="$emit('close');" v-if="!openModal"  class="inline-flex items-center px-6 py-2 border border-gray-200 text-base font-medium rounded-md shadow-sm text-gray-700 bg-gray-100 hover:bg-white float-right">Close</button>
                  </div>
              </div>
          </Modal>
</template>

<script>
/* eslint-disable no-unreachable */
import { useStore } from 'vuex'
import { reactive, ref, computed } from 'vue'
import ImagePoiInput from '@/components/inputs/ImageProfilePoiInput'
import Modal from '@/components/widgets/Modal'
import LoadingSpinner from '@/components/widgets/LoadingSpinner'
import { PhotoAlbumNames } from '@/constants'
import { TrashIcon } from "@heroicons/vue/outline/esm"
import { createToaster } from "@meforma/vue-toaster";

// import {
//     Dialog,
//     DialogPanel,
//     DialogTitle,
//     DialogDescription,
//   } from '@headlessui/vue'

export default {
  name: "ProfilePhotoEdit",
  components: {  Modal, LoadingSpinner,ImagePoiInput,TrashIcon },
  props:{
    modalOverFlow:{
        type: String,
        default:'z-5',
    }
  },
  data(){
     return{
        
     }
  },
    setup(props,{emit}) {
        const store = useStore()
        const toast = createToaster({ position: "top-right" });
        store.dispatch("fetchUserAlbum", PhotoAlbumNames.ProfilePhotos) // Fetch profile pictures

        const form = reactive({
            image: {
                preview: store.getters.getProfilePicture,
                image: null,
                coordinates: {
                    width: 0,
                    height: 0,
                    left: 0,
                    top: 0
                }
            },
            professionalName: store.getters.getUserDisplayProfessionalName,
            givenName: !store.getters.getUserDisplayProfessionalName
        })
        const submitActive = ref(false)
        const fileToBig = ref(false)
        const imagePoiSelectorOn = ref(false)
        const openModal = ref(false)

        function closePhotoWidget() {
           
            form.image = {
                preview: store.getters.getProfilePicture,
                image: null,
                coordinates: {
                    width: 0,
                    height: 0,
                    left: 0,
                    top: 0
                }
            }
            imagePoiSelectorOn.value = false
            openModal.value=false
            emit('close')
        }

        return {
            openModal,
            closePhotoWidget,
            store,
            form,
            submitActive,
            imagePoiSelectorOn,
            fileToBig,
            profilePicture: computed(() => store.getters.getUserProfilePicture),
            profilePictures: computed(() => store.getters.getUserAlbum(PhotoAlbumNames.ProfilePhotos)),
            isLoading: computed(() => store.getters.isLoading),
            async submitPhoto({ file, crop, croppedFile }) {
                if (file || croppedFile) {
                    await store.dispatch("createUserMediaImage",
                         { file,
                           crop,
                           croppedFile,
                           logo: true,
                           profileId: store.getters.getId,
                           album: PhotoAlbumNames.ProfilePhotos
                        })

                //store.dispatch("fetchUserAlbum", PhotoAlbumNames.ProfilePhotos) // Fetch profile pictures
                closePhotoWidget()
                toast.success(`Image has been added successfully`);
                }

            },
            async selectPhotoAsLogo(uuid) {
                await store.dispatch("editUserMediaImage", { uuid, logo: true })
                //store.dispatch("fetchUserProfilePicture")
                imagePoiSelectorOn.value = false

            },
            async deleteEntry(uuid){
        const result = confirm("Are you sure you want to delete?")
        try {
          
          if (uuid && result) {
            await store.dispatch("editUserMediaImage", { uuid,delete: true,logo:false})
            toast.success(`Image has been deleted successfully`);
           
            }
            await store.dispatch("fetchUserAlbum", PhotoAlbumNames.ProfilePhotos);
          
        } catch (e) {
          console.error(e)
        }
    },
    onChange(){
        openModal.value=true
    }

    }
  }
}


</script>
