import getters from './getters'
import mutations from './mutations'
import actions from './actions'


export default {
    state: () => ({
        people: [],
        searchPeople:[],
        profile: {
            id: null,
            firstName: null,
            middleName: null,
            surname: null,
            primaryJob: null,
            dateOfBirth: null,
            bio: null,
            representation: null,
            profilePhoto: null,
            image: null,
            video: null,
            aud: null,
            header: {},
            about: null,
            emails: [],
            phoneNumbers: [],
            addresses: [],
            websites: [],
            education: [],
            media: [],
            documents: [],
            socials: [],
            videos: [],
            images: [],
            albums: [],
            albumTitles: [],
            audio: [],
            skills: [],
            workExperience: [],
            profileProductions: [],
			affiliateCompanies:[]
        },
        filterOptions: {},
        rangeFilters: {
            height: {
                title: 'Height',
                backTitle: "cm",
                min: 0,
                max: 300,
                value: [0, 300]
            },
            age: {
                title: 'Age',
                backTitle: null,
                min: 1,
                max: 100,
                value: [1, 100]
            }
        },
        sortBy: 'first_name'
    }),
    mutations: {
        ...mutations
    },
    actions: {
        ...actions
    },
    getters: {
        ...getters
    }
}
