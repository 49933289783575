export default {
    setVenue(state, data) {
        state.venue.id = data.id
        state.venue.name = data.name
        state.venue.country = data.country
        state.venue.venueType = data.venueType
        state.venue.description = data.description
        state.venue.built = data.built
        state.venue.logo = data.logo
        state.venue.contact.website = data.website
        state.venue.contact.email = data.email
        state.venue.contact.phone = data.phone
        state.venue.contact.address = data.address
        state.venue.createdBy = data.createdBy
        state.venue.venueSpaces = data.spaces
    },
    setVenues(state, data){
        state.venues = data
    },
    setBasicVenues(state, data) {
        state.basicVenues = data
    },
    setUserVenueNames(state, data) {
        state.userVenueNames = data
    },
    setSpace(state, data) {
        state.space = data
    },
    setSpaces(state, data) {
      state.spaces = data
    },
    setCalVenues(state, data){
        state.calVenues = data
    },
    setCalSpaces(state, data){
        state.calSpaces = data
    },
    setVenueTypes(state, data){
        state.venueTypes = data
    },
    setVenueSpaceStorageChildren(state, { id, type, data }) {
        if (type === "venue") {
            if (!state.venueSpaces) state.venueSpaces = {}
            state.venueSpaces[id] = data
        }
        else if (type === "space") {
            if (!state.spaceStorages) state.spaceStorages = {}
            state.spaceStorages[id] = data
        }
        else if (type === "storage") {
            if (!state.storageStorages) state.storageStorages = {}
            state.storageStorages[id] = data
        }
        else {
            throw new Error(`Unknown type "${type}". Expected one of "venue", "space" or "storage"`)
        }
    },
    setStorage(state, data) {
        state.allStorages = state.allStorages ?? {}
        state.allStorages[data.id.toString()] = data
    },

    setSpaceTypes(state, data){
        state.spaceTypes = data
    },
    setCountries(state, data) {
        state.countries = data
    },
    setVenueFilters(state, data){
        state.filters.venueType.options = data.venueTypes
        state.filters.country.options = data.countries
        state.filters.configType.options = data.configTypes
    },
    setSpaceFilters(state, data){
        state.filters.configType.options = data.configTypes
        state.filters.country.options = data.countries
        state.filters.spaceType.options = data.spaceTypes
    },
    setVenueSortBy(state, data){
        if (data === state.sortBy){
            state.sortBy = `-${data}`
        } else {
            state.sortBy = data
        }
    },
    setSpaceSortBy(state, data){
        if (data === state.sortBy){
            state.sortBy = `-${data}`
        } else {
            state.sortBy = data
        }
    },
    resetVenue(state) {
        state.venue = {
            id: null,
            name: '',
            built: '',
            country: '',
            venueType: '',
            description: '',
            createdBy: '',
            contact: {
                website: {
                    id: null,
                    url: '',
                    label: ''
                },
                email: {
                    id: null,
                    email: '',
                    label: ''
                },
                phone: {
                    id: null,
                    phone: '',
                    label: ''
                },
                address: {
                    id: null,
                    address: '',
                    label: ''
                }
            },
        }
    },
    resetSpace(state) {
        state.space = {
            id: null,
            venueId: null,
            name: "",
            spaceType: "",
            maxCapacity: null,
            roomNumber: "",
            level: "",
            description: "",
            access: null,
            accessDesc: "",
            area: null,
        }
    },
    resetStorage(state) {
        state.storage = {
            id: null,
            name: "",
            parent: {
                id: null,
                name: ""
            },
            space: {
                id: null,
                name: ""
            }
        }
    }

}
