import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
    state: () => ({
        currentCursor: '',
        endCursor: '',
        after: '',
        before: '',
        hasNextPage: false,
        page: 1,
        totalCount: 0,
        resultCount: 12,
        searchParam: '',
        fields: [],
        photoFields: [],
        items: []
    }),
    mutations: {
        ...mutations
    },
    getters: {
        ...getters
    },
    actions: {
        ...actions
    }
}