import ID_DECODER from "./ID_DECODER";
import ObjMap from "./ObjMap";

function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

/**
 * Goes through a list or array and makes a deep copy that has applied the ID_DECODER to all object
 * parameters with the name id.
 */
const AutoIdDecoder = data => {
    const dataStringType = Object.prototype.toString.call(data);
    // If we're dealing with a list, call this function on all items in it and return the new list
    if (dataStringType === "[object Array]") {
        return data.map(AutoIdDecoder)
    }
    // If we're dealing with an object, call this function on all its values, generate a new object
    // from that and if it has an ID, encode it and add it to the new object before returning it
    else if (dataStringType === "[object Object]") {
        if (data.id) {
            const newObj = ObjMap(data, AutoIdDecoder)

            // Skip decoding IDs that are probably in base 10
            if (!isNumeric(data.id)) newObj.id = ID_DECODER(data.id)
            else if (data.id > 1000000) console.warn(`AutoIdDecoder: Skipped decoding ID "${data.id} as it was numeric but based on its size that was probably an error."`)

            return newObj
        } else {
            return ObjMap(data, AutoIdDecoder)
        }
    }
    // Were dealing with something unknown, probably a number/string/... so we just return it.
    else {
        return data
    }
}

export default AutoIdDecoder
