export default {
    setUserDiversity: (state, data) => {
        state.ethnicity = data.ethnicity
        state.religion = data.religion
        state.sex = data.sex
        state.gender = data.gender
        state.sexuality = data.sexuality
        state.caringResponsibilities = data.caringResponsibilities
        state.disabilities = data.disabilities
    }
}