import { nameFormatter } from '@/store/formatters'
export default {
    basicInfo: state => state,
    getUserProfessionalName: state => state.professionalName,
    getUserDisplayProfessionalName: state => state.displayProfessionalName,
    getUserFirstName:(state) => state.firstName,
    getUserMiddleName: state => state.middleName,
    getUserSurname: state => state.surname,
    getUserBio: state => state.bio,
    getUserHeader: state => state.header,
    getUserOverviewSettings: state => state.overviewSettings,
    getUserAbout: state => state.about,
    getUserFullName(state){
        return nameFormatter(state.firstName, state.middleName, state.surname)
    },
    getUserDateOfBirth: state => state.dateOfBirth,
    getUserPlaceOfBirth: state => state.placeOfBirth,
    getUserPronouns: state => state.pronouns,
}
