import {apolloClient} from "@/apollo/apolloClient";
import {CREATE_WEBSITE, EDIT_WEBSITE} from "@/apollo/contact_info/contact_info.mutations";
import { singleWebsiteFormatter } from '@/store/formatters'

export default {
    async createWebsite(state, data){
        try {
            const res = await apolloClient.mutate({
                mutation: CREATE_WEBSITE,
                variables: {input: data}
            })
            let website = singleWebsiteFormatter(res.data.websiteCreate.website)
            state.commit("appendWebsite", website)
            return website
        } catch (e) {
            console.error(e)
        }
    },

    async editWebsite(state, data) {
        try {
            await apolloClient.mutate({
                mutation: EDIT_WEBSITE,
                variables: {input:data}
            })
        } catch (e) {
            console.error(e)
        }
    },

    async removeWebsite(state, id){
        try {
            await apolloClient.mutate({
                mutation: EDIT_WEBSITE,
                variables: {input:{websiteId:id, delete:true}}
            })
        } catch (e) {
            console.error(e)
        }
    }


}
