/**
 * A function like map but for objects instead of arrays.
 * 
 * The function that is passed in gets the value as the first parameter, the key as the second
 * parameter and the index as the third. The value returned from the user provided function is
 * then set as the value and the key doesn't change.
 */
export default (obj, fn) =>
    Object.fromEntries(
        Object.entries(obj).map(
            ([k, v], i) => [k, fn(v, k, i)]
        )
    )
