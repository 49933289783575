export default {
    getMedia(state){
        return state.media
    },
    getUserProfilePicture(state){
        return state.profilePicture
    },
    getUserDocuments: state => state.documents,
    getUserImages: state => state.images,
    getUserVideos: state => state.videos,
    getUserAlbums: state => state.albums,
    getUserAlbumTitles: state => state.albumTitles,
    getUserAlbum: state => title => {
        const albums = state.albums.filter(a => a.title.toLowerCase() === title.toLowerCase())
        if (albums.length > 0)
            return albums[0].images
        return albums
    }
}
