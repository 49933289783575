import { apolloClient } from "@/apollo/apolloClient";
import {
    GET_THING,
    GET_CATEGORY,
    GET_TOP_LEVEL_CATEGORIES,
    GET_CATEGORY_PROPERTIES,
    GET_THING_STATUS_OPTIONS,
    GET_THING_ACQUISITION_OPTIONS,
    GET_THINGS,
    THING_CATEGORY_PATH_EXISTS,
    GET_CATEGORY_SEARCH,
} from "../../../apollo/entities/things/things.queries";
import { CREATE_THING, EDIT_THING, ADD_PROP_VALUE } from "../../../apollo/entities/things/things.mutations";
import { singleCategoryFormatter, categoryFormatter, singleThingFormatter, thingFormatter } from "../../formatters";
import RemoveFalsy from "../../../utils/RemoveFalsy";
import AutoIdDecoder from "../../../utils/AutoIdDecoder";
import AutoEdgeNodeRemover from "@/utils/AutoEdgeNodeRemover";

const zip = (a, b) => a.map((k, i) => [k, b[i]]);

export default {
    async getBasicThings(_, queryParams) {
        const res = await apolloClient.query({ query: GET_THINGS, variables: queryParams })
        if (res?.data?.things?.edges == null) {
            console.log("Response", res)
            throw new Error(`Failed to things with query params:`, queryParams)
        }

        return { things: thingFormatter(res.data.things), pageInfo: res.data.things.pageInfo }
    },
    async getThing(state, uuid) {
        const res = await apolloClient.query({ query: GET_THING, variables: { uuid } })
        if (res?.data?.thing == null) {
            console.log("Response", res)
            throw new Error(`Failed to fetch thing with UUID: "${uuid}"`)
        }

        return singleThingFormatter(res.data.thing)
    },
    async fetchThing(state, uuid) {
        const thing = await state.dispatch("getThing", uuid)
        state.commit("setThing", thing)
    },
    async getCategoryTreeSearch(state, searchStr) {
        // Cache all the categories in the search so they can be displayed by other systems on the site
        const categoryTreeToStateCats = (parentId, id, obj) => {
            const childEntries = Object.entries(obj.children ?? [])
            // Turn the current node into the format we get from the server
            const curr = {
                id,
                label: { name: obj.label },
                parent: { id: parentId },
                children: childEntries.map(([c_id, c_obj]) => ({
                    id: c_id,
                    parent: { id },
                    label: { name: c_obj.label },
                })),
            }

            // Return it along with all its child nodes
            const childCats = childEntries
                .map(([c_id, c_obj]) => categoryTreeToStateCats(id, c_id, c_obj))
                .flat(1) // Flatten it to merge all of the child items into a single array
            childCats.push(curr)
            return childCats
        }

        // Get the category tree
        const res = await apolloClient.query({ query: GET_CATEGORY_SEARCH, variables: { name: searchStr.toUpperCase() } })
        if (res?.data?.thingCategoryTreeSearch == null) {
            console.log("Response", res)
            throw new Error(`Failed to search for category with search string: "${searchStr}"`)
        }
        // Store the categories in the cache with the earlier function
        const cacheCategories = Object
            .entries(res.data.thingCategoryTreeSearch)
            .map(([id, obj]) => categoryTreeToStateCats(null, id, obj))
            .flat(1)
        state.commit("setCategories", cacheCategories)

        // Return the tree
        return res.data.thingCategoryTreeSearch
    },
    async fetchCategory(state, id) {
        const res = await apolloClient.query({ query: GET_CATEGORY, variables: { id } })
        if (res?.data?.thingCategory == null) {
            console.log("Response", res)
            throw new Error(`Failed to fetch category with ID: "${id}"`)
        }

        state.commit("setCategory", singleCategoryFormatter(res.data.thingCategory))
    },
    async fetchCategoryCustomProperties(state, id) {
        const res = await apolloClient.query({ query: GET_CATEGORY_PROPERTIES, variables: { id } })
        if (res?.data?.thingCategory?.allProperties == null) {
            console.log("Response", res)
            throw new Error(`Failed to fetch custom properties of category with ID: "${id}"`)
        }

        const properties = AutoIdDecoder(AutoEdgeNodeRemover(res.data.thingCategory.allProperties))
        state.commit("setCategoryCustomProperties", { id, properties })
    },
    async fetchTopLevelCategories(state) {
        const res = await apolloClient.query({ query: GET_TOP_LEVEL_CATEGORIES })
        if (res?.data?.thingCategories == null) {
            console.log("Response", res)
            throw new Error(`Failed to fetch top level categories`)
        }

        const categories = categoryFormatter(res.data.thingCategories)
        categories.forEach(category => state.commit("setCategory", category))
        state.commit("setTopLevelCategoryIds", categories.map(category => category.id))
    },
    async saveCustomProps(state, { thingId, customProps }) {
        // Save all the custom properties in parallel
        const serverPropStructs = customProps.map(prop =>
            ({ propertyId: prop.id, thingId: thingId, value: prop.value }))
        const queries = serverPropStructs.map(prop =>
            apolloClient.mutate({ mutation: ADD_PROP_VALUE, variables: { input: prop } }))
        const results = await Promise.all(queries)
        // Make sure they all succeeded
        zip(serverPropStructs, results).forEach(([prop, res]) => {
            if (res?.data?.thingAddPropertyValue?.success !== true) {
                console.log("Property", prop)
                console.log("Response", res)
                throw new Error(`Failed to save property with ID "${prop.propertyId}" and value "${prop.value}"`)
            }
        })
    },
    async createThing(state, form) {
        const data = RemoveFalsy(form)

        // Add the profile ID
        data.profileId = state.getters.getId

        // Remove the custom properties before saving the thing
        const customProps = data.customProps ?? []
        if (data.customProps != null) delete data.customProps

        // Create the thing
        const res = await apolloClient.mutate({ mutation: CREATE_THING, variables: { input: data } })
        if (res?.data?.thingCreate?.thing == null) {
            console.log("Response", res)
            throw new Error(`Failed to create thing.`)
        }
        const thing = singleThingFormatter(res.data.thingCreate.thing)

        // TODO: Remove all the properties from customProps that don't fit on the thing

        await state.dispatch("saveCustomProps", { thingId: thing.id, customProps })

        // TODO: Add the properties to the thing before returning it or saving it to the cache
        return thing
    },
    async editThing(state, form) {
        const data = RemoveFalsy(form)

        // Remove the custom properties before saving the thing
        const customProps = data.customProps ?? []
        if (data.customProps != null) delete data.customProps

        // Create the thing
        const res = await apolloClient.mutate({ mutation: EDIT_THING, variables: { input: data } })
        if (res?.data?.thingEdit?.thing == null) {
            console.log("Response", res)
            throw new Error(`Failed to edit thing.`)
        }
        const thing = singleThingFormatter(res.data.thingEdit.thing)

        // TODO: Remove all the properties from customProps that don't fit on the thing

        await state.dispatch("saveCustomProps", { thingId: thing.id, customProps })

        // TODO: Add the properties to the thing before returning it or saving it to the cache
        return thing
    },

    async fetchThingStatusOptions(state) {
        const res = await apolloClient.query({
            query: GET_THING_STATUS_OPTIONS
        })
        state.commit("setThingStatusOptions", res.data.thingStatusChoices)
    },

    async fetchThingAcquisitionOptions(state) {
        const res = await apolloClient.query({
            query: GET_THING_ACQUISITION_OPTIONS
        })
        state.commit("setThingAcquisitionOptions", res.data.thingAcquisitionChoices)
    },

    async isValidCategoryPath(state, path) {
        const res = await apolloClient.query({
            query: THING_CATEGORY_PATH_EXISTS, variables: { path: path.join("/") }
        })
        if (res?.data?.thingCategoryPathExists) {
            return {
                valid: res.data.thingCategoryPathExists.valid ?? false,
                categoryId: res.data.thingCategoryPathExists.categoryId ?? null,
            }
        }
        else {
            console.log("res", res)
            throw new Error("Failed to check if the category path is valid.")
        }
    },

    // Tag stuff, probably doesn't belong in thing
    async getTagsForNewImages(store, images) {
        // Make sure we don't request tags for the same image multiple times
        const nonCachedImages = images.filter(img => !store.getters.hasTagsCached(img))

        // Fetch the tags from the server
        const tasks = nonCachedImages
            .map(img => store.dispatch("createMediaImage", {
                profileId: store.getters.getId,
                file: img.file,
                fetchTags: true,
            }))
        const results = await Promise.all(tasks)

        // Cache the results
        zip(nonCachedImages, results).map(([image, fullTags]) => {
            const hash = image.preview
            const tags = fullTags
                .filter(tag => tag.Confidence > 50) //  Make sure we're confident
                .map(tag => tag.Name) // We don't need all the data we get
            store.commit("setImageTags", { hash, tags })
        })
    }
}
