import gql from 'graphql-tag';
import { MediaImageMediumFragment } from './image.fragments';


export const EDIT_MEDIA_IMAGE = gql `
    mutation mediaImageEditMutation($input: MediaImageInput!) {
        mediaImageEditMutation(input: $input){
            success
            message
            image {
            ...MediaImageMediumFragment
            }
        }
    }${MediaImageMediumFragment}
`


export const SET_PRIMARY_MEDIA_IMAGES = gql `
    mutation mediaImageSetPrimaryMutation($input: MediaImagePrimaryInput!) {
        mediaImageSetPrimaryMutation(input: $input) {
            success
            message
            images{
                id
            }
        }
    }
`
