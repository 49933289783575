import axios from 'axios'
import localStorage from "store2";
import { apolloClient, URI } from '@/apollo/apolloClient';
import {
    GET_COMPANIES,
    GET_COMPANY, GET_COMPANY_FILTERS, GET_COMPANY_LEVELS,
    GET_SEASON,
    GET_COMPANY_MEMBERS_DISTINCT,
    GET_COMPANY_TYPES,
    GET_COMPANY_MEMBERS
} from '@/apollo/entities/companies/company.queries'
import {
    CREATE_COMPANY,
    CREATE_SEASON,
    EDIT_SEASON,
    EDIT_COMPANY,
    CREATE_COMPANY_MEMBER,
    EDIT_COMPANY_MEMBER
} from '@/apollo/entities/companies/company.mutations'
// import {
//     CREATE_EMAIL,
//     CREATE_PHONE,
//     CREATE_WEBSITE,
//     EDIT_EMAIL,
//     EDIT_PHONE,
//     EDIT_WEBSITE
// } from "@/apollo/contact_info/contact_info.mutations"

import {
    singleCompanyFormatter,
    mediaEncoder,
    companyFormatter,
    singleSeasonFormatter,
    companyMemberFormatter,
    singlePhoneNumberFormatter,
    singleEmailFormatter,
    singleAddressFormatter,
    singleSocialFormatter,
    CompanyPeopleFormatters,
    companyPeopleFormatterItem,
} from "@/store/formatters";
import ID_DECODER from "@/utils/ID_DECODER";
import RemoveFalsy from '@/utils/RemoveFalsy'
import CompanyAwards from '@/assets/dummyData/CompanyAwards.json'
import CompanyProductions from '@/assets/dummyData/CompanyProductions.json'
import CompanyImgaes from "../../../assets/dummyData/CompanyImages.json"
import { SET_ADDRESS_PRIMARY, EDIT_ADDRESS, CREATE_ADDRESS, CREATE_EMAIL, EDIT_EMAIL, SET_EMAIL_PRIMARY, SET_SOCIAL_PRIMARY, EDIT_WEBSITE, CREATE_WEBSITE, SET_WEBSITE_PRIMARY, CREATE_PHONE, EDIT_PHONE, SET_PHONE_PRIMARY, CREATE_SOCIAL, EDIT_SOCIAL } from "@/apollo/contact_info/contact_info.mutations";

export default {

    async fetchCompanies(state, params) {
        try {
            const res = await apolloClient.query({
                query: GET_COMPANIES,
                variables: params
            })
            await state.commit('setCompanies', companyFormatter(res.data.companies))
            return { ...res.data.companies.pageInfo, totalCount: res.data.companies.totalCount }
        } catch (e) {
            console.error(e)
        }
    },

    async fetchCompany(state, id) {
        try {
            const res = await apolloClient.query({
                query: GET_COMPANY,
                variables: { id },
                fetchPolicy: 'no-cache'
            })
            console.log(res)

            const awards = CompanyAwards;
            const data = {
                ...singleCompanyFormatter(res.data.company),
                awards: awards,
                productions: CompanyProductions
            }
            state.commit('setCompany', data)
            state.commit("setCompanyPicture", '')
            state.dispatch("fetchCompnayImages")
        } catch (e) {
            console.error(e)
        }
    },

    async fetchSeason(state, id) {
        try {
            const res = await apolloClient.query({
                query: GET_SEASON,
                variables: { id }
            })
            state.commit('setSeason', singleSeasonFormatter(res.data.season))
        } catch (e) {
            console.error(e)
        }
    },

    async fetchCompanyTypes(state) {
        try {

            const res = await apolloClient.query({
                query: GET_COMPANY_TYPES
            })
            if (res.data.companyObjTypes) {
                state.commit('setCompanyTypes', res.data.companyObjTypes)
            }
        } catch (e) {
            console.error(e)
        }

    },


    async fetchCompanyLevels(state) {
        try {
            const res = await apolloClient.query({
                query: GET_COMPANY_LEVELS
            })
            if (res.data.companyObjLevels) {
                state.commit('setCompanyLevels', res.data.companyObjLevels)
            }
        } catch (e) {
            console.error(e)
        }
    },

    async fetchCompanyFilters(state) {
        try {
            const res = await apolloClient.query({
                query: GET_COMPANY_FILTERS
            })

            state.commit('setCompanyFilters', res.data)
        } catch (e) {
            console.error(e)
        }
    },
    async fetchCompanyMembers(state, params) {
        try {
            const res = await apolloClient.query({
                query: GET_COMPANY_MEMBERS_DISTINCT,
                variables: params
            })
            state.commit('setCompanyMembers', companyMemberFormatter(res.data.companyMembersDistinct))
            state.commit('setPeople', CompanyPeopleFormatters(res.data.companyMembersDistinct.edges))
            return { ...res.data.companyMembersDistinct.pageInfo, totalCount: res.data.companyMembersDistinct.totalCount }
        } catch (e) {
            console.error(e)
        }
    },
    async fetchCompanyPeople(state, params) {
        try {
            const res = await apolloClient.query({
                query: GET_COMPANY_MEMBERS,
                variables: params
            })

            state.commit('setCompanyMembers', companyPeopleFormatterItem(res.data.companyMembers))
            state.commit('setPeople', companyPeopleFormatterItem(res.data.companyMembers.edges))
            return { ...res.data.companyMembers.pageInfo, totalCount: res.data.companyMembers.totalCount }
        } catch (e) {
            console.error(e)
        }
    },
    async createCompany(state, form) {
        let data = RemoveFalsy({ ...form })
        if ('logo' in data) {
            try {
                data = { ...data, file: await mediaEncoder(data['logo']) }
            } catch (e) {
                console.error(e)
            }

            delete data['logo']
        }
        delete data['addresses']
        delete data['emails']
        delete data['phoneNumbers']
        delete data['websites']
        delete data['place']
        delete data['socials']
        delete data['file']
        if (state.getters.getCompany.id !== null) {
            await state.dispatch('editCompany', { data, form })
            return
        }
        try {
            const res = await apolloClient.mutate({
                mutation: CREATE_COMPANY,
                variables: { input: data }
            })

            if (res.data.companyCreate.company) {
                await state.commit('setCompany', singleCompanyFormatter(res.data.companyCreate.company))
                await state.dispatch('saveCompanyWebsites')
                await state.dispatch('saveCompanyEmails')
                await state.dispatch('saveCompanyPhoneNumbers')
                await state.dispatch('saveCompanyAddresses')
                await state.dispatch('saveCompanySocials')

            } else {
                console.log('SOMETHING WENT WRONG')
            }

        } catch (e) {
            console.error(e)
        }
    },

    async editCompany(state, input) {
        let data = RemoveFalsy({ ...input })
        const id = state.getters.getCompanyId
        try {
            const res = await apolloClient.mutate({
                mutation: EDIT_COMPANY,
                variables: { input: { ...data, id: id } }
            })
            console.log(res)
            // await state.commit("setCompany", res.data.companyEdit)
            if (res.data.companyEdit.company) {

                // await state.dispatch('editCompanyWebsites',form.websites)
                // await state.dispatch('editCompanyEmails',form.emails)
                // await state.dispatch('editCompanyPhoneNumbers',form.phoneNumbers)
                // await state.dispatch('editCompanyAddresses',form.addresses)
                // await state.dispatch('editCompanySocials',form.socials)

            } else {
                console.log('SOMETHING WENT WRONG')
            }

        } catch (e) {
            console.error(e)
        }
    },

    async createSeason(state, form) {
        let data = RemoveFalsy({ ...form })
        if ('image' in data) {
            try {
                data = { ...data, file: await mediaEncoder(data['image']) }
            } catch (e) {
                data
            }

            delete data['image']
        }

        if (state.getters.getSeason.id !== null) {
            await state.dispatch('editSeason', { data, form })
            return
        }

        try {
            const res = await apolloClient.mutate({
                mutation: CREATE_SEASON,
                variables: { input: data }
            })

            if (res.data.seasonCreate.season) {
                await state.commit('setSeason', singleSeasonFormatter(res.data.seasonCreate.season))
            } else {
                console.log('SOMETHING WENT WRONG')
            }

        } catch (e) {
            console.error(e)
        }
    },

    async editSeason(state, input) {
        const { data } = input
        const id = state.getters.getSeasonID
        try {
            const res = await apolloClient.mutate({
                mutation: EDIT_SEASON,
                variables: { input: { ...data, id: id } }
            })

            if (res.data.seasonCreate.season) {
                await state.commit('setSeason', singleSeasonFormatter(res.data.seasonCreate.season))
            } else {
                console.log('SOMETHING WENT WRONG')
            }

        } catch (e) {
            console.error(e)
        }
    },

    async saveCompanyEmail(state, bundle) {
        const { email, id } = bundle
        const name = state.getters.getCompanyName
        try {
            await apolloClient.mutate({
                mutation: CREATE_EMAIL,
                variables: { input: { label: name, companyId: id, email: email } }
            })
        } catch (e) {
            console.error(e)
        }
    },

    async editCompanyEmail(state, email) {
        const id = state.getters.getCompanyEmail.id

        try {
            await apolloClient.mutate({
                mutation: EDIT_EMAIL,
                variables: { input: { emailId: id, email: email } }
            })
        } catch (e) {
            console.error(e)
        }
    },

    async saveCompanyPhone(state, bundle) {
        const { phone, id } = bundle
        const name = state.getters.getCompanyName
        try {
            await apolloClient.mutate({
                mutation: CREATE_PHONE,
                variables: { input: { label: name, companyId: id, phoneNumber: phone } }
            })
        } catch (e) {
            console.error(e)
        }
    },

    async editCompanyPhone(state, phone) {
        const id = state.getters.getCompanyPhone.id
        try {
            await apolloClient.mutate({
                mutation: EDIT_PHONE,
                variables: { input: { phoneId: id, phoneNumber: phone } }
            })
        } catch (e) {
            console.error(e)
        }
    },

    async saveCompanyWebsite(state, bundle) {
        const { url, id } = bundle
        const name = state.getters.getCompanyName
        try {
            await apolloClient.mutate({
                mutation: CREATE_WEBSITE,
                variables: { input: { title: name, companyId: id, url: url } }
            })
        } catch (e) {
            console.error(e)
        }
    },

    async editCompanyWebsite(state, url) {
        const id = state.getters.getCompanyWebsite.id
        try {
            await apolloClient.mutate({
                mutation: EDIT_WEBSITE,
                variables: { input: { websiteId: id, url: url } }
            })
        } catch (e) {
            console.error(e)
        }
    },

    async saveCompanyMember(state, data) {
        try {
            const res = await apolloClient.mutate({
                mutation: CREATE_COMPANY_MEMBER,
                variables: { input: data }
            })

            await state.commit('addCompanyMember', res.data.companyMemberCreate.member);
            await state.dispatch('dynamicTableLoadCompanyMembers');
            return state.commit('addCompanyMemberRole', { ...data, id: ID_DECODER(res.data.companyMemberCreate.member.id) })
        } catch (e) {
            console.error(e)
        }
    },

    async deleteCompanyMember(state, data) {
        try {
            await apolloClient.mutate({
                mutation: EDIT_COMPANY_MEMBER,
                variables: { input: data }
            })

            state.commit('removeCompanyMember', data.id)
        } catch (e) {
            console.error(e)
        }
    },

    async companiesPageNext(state) {
        await state.commit('setNextPage')
        await state.dispatch('dynamicTableLoadCompanies')
    },

    async companiesPagePrevious(state) {
        await state.commit('setPreviousPage')
        await state.dispatch('dynamicTableLoadCompanies')
    },

    async companyMembersPageNext(state) {
        await state.commit('setNextPage')
        await state.dispatch('dynamicTableLoadCompanyMembers')
    },

    async companyMembersPagePrevious(state) {
        await state.commit('setPreviousPage')
        await state.dispatch('dynamicTableLoadCompanyMembers')
    },

    async companyMembersUnifiedPageNext(state) {
        await state.commit('setNextPage')
        await state.dispatch('dynamicTableLoadUnifiedCompanyMembers')
    },

    async companyMembersUnifiedPagePrevious(state) {
        await state.commit('setPreviousPage')
        await state.dispatch('dynamicTableLoadUnifiedCompanyMembers')
    },

    async productionMembersPageNext(state) {
        await state.commit('setNextPage')
        await state.dispatch('dynamicTableLoadProductionMembers')
    },

    async productionMembersPagePrevious(state) {
        await state.commit('setPreviousPage')
        await state.dispatch('dynamicTableLoadProductionMembers')
    },

    async fetchCompanyProductionSettings(state) {
        console.log("NEED TO FETCH SETTINGS FROM API");
        let settings = {
            __typeName: "CompnayProdutionViewSettings",
            sixLatestCurrent: true,
            sixLatestCurrentAndIncoming: false,
            random: false
        }
        await state.commit("setCompanyProductionSettings", settings)
    },

    async updateCompanyProductionSettings(state, paylod) {
        console.log("NEED TO UPDATE SETTINGS IN API");

        const settings = state.getters.getCompanyProductionSettings;
        Object.keys(settings).map((key) => {
            if (key === paylod) {
                settings[key] = true
            } else if (!key.includes('__')) {
                settings[key] = false
            }
        })

        console.log(settings);
        await state.commit("setCompanyProductionSettings", settings)
    },

    async fetchCompnayImages(state) {
        console.log("NEED TO FETCH IMAGES FROM API")
        console.log("NEED TO FETCH STATE OF IMAGES FROM API (showRandom)")
        let data = {
            images: CompanyImgaes,
            showRandom: false
        }
        await state.commit("setCompnayImages", data)
    },

    async updateCompnayImages(state, paylod) {
        console.log("NEED TO UPDATE IMAGES FROM API")
        console.log("NEED TO UPDATE STATE OF IMAGES FROM API (showRandom)")
        await state.commit("setCompnayImages", paylod)
    },

    async setCompanyAddressPrimary(state, addressIds) {
        try {
            const res = await apolloClient.mutate({
                mutation: SET_ADDRESS_PRIMARY,
                variables: { input: { companyId: state.getters.getCompanyId, addressIds } }
            })
            console.log(res)
            // const primaryIds = res.data.addressSetPrimaryMutation.addresses.map(addr => ID_DECODER(addr.id))
            // state.commit("refreshAddressPrimaries", primaryIds)
        } catch (e) {
            console.error(e)
        }
    },

    async setCompanyPhonePrimary(state, phoneIds) {
        try {
            const res = await apolloClient.mutate({
                mutation: SET_PHONE_PRIMARY,
                variables: { input: { companyId: state.getters.getCompanyId, phoneIds } }
            })
            console.log(res)
            // const primaryIds = res.data.phoneSetPrimaryMutation.phoneNumbers.map(phone => ID_DECODER(phone.id))
            // state.commit("refreshPhonePrimaries", primaryIds)

        } catch (e) {
            console.error(e)
        }
    },

    async setCompanyEmailPrimary(state, emailIds) {
        try {
            const res = await apolloClient.mutate({
                mutation: SET_EMAIL_PRIMARY,
                variables: { input: { companyId: state.getters.getCompanyId, emailIds } }
            })
            console.log(res)

        } catch (err) {
            console.log(err)
        }
    },

    // async setCompanyWebsitePrimary(state, websiteIds) {
    //     try {
    //         const res = await apolloClient.mutate({
    //             mutation: SET_WEBSITE_PRIMARY,
    //             variables: { input: { companyId: state.getters.getCompanyId, websiteIds } }
    //         })
    //         console.log(res)
    //     } catch (err) {
    //         console.log(err)
    //     }
    // },
    async setCompanySocialPrimary(state, socialIds) {
        try {
            console.log(socialIds);
            const res = await apolloClient.mutate({
                mutation: SET_SOCIAL_PRIMARY,
                variables: { input: { companyId: state.getters.getCompanyId, lineIds: socialIds } }
            })
            console.log(res)
        } catch (err) {
            console.log(err)
        }
    }, async saveCompanyWebsites(state) {
        const websites = state.getters.getCompanyWebsites
            .filter(item => item.url !== '' && item.id === undefined)
        const oldWebsites = state.getters.getCompanyWebsites
            .filter(item => item.id !== undefined)

        try {
            for (let i = 0; i < websites.length; i++) {
                await apolloClient.mutate({
                    mutation: CREATE_WEBSITE,
                    variables: { input: { url: websites[i].url, title: websites[i].title, isPrimary: true, companyId: state.getters.getCompanyId } }
                })
            }
            for (let j = 0; j < oldWebsites.length; j++) {
                await apolloClient.mutate({
                    mutation: EDIT_WEBSITE,
                    variables: { input: { url: oldWebsites[j].url, title: oldWebsites[j].title, id: oldWebsites[j].id } }
                })
            }
        } catch (e) {
            console.error(e)
        }
    },
    async deleteCompanyWebsite(state, id) {
        try {
            await apolloClient.mutate({
                mutation: EDIT_WEBSITE,
                variables: { input: { delete: true, id: id } }
            })
            state.commit("removeCompanyWebsiteById", id)
        } catch (e) {
            console.error(e)
        }
    },

    async setCompanyWebsitePrimary(state, websiteIds) {
        try {
            const res = await apolloClient.mutate({
                mutation: SET_WEBSITE_PRIMARY,
                variables: { input: { companyId: state.getters.getCompanyId, websiteIds } }
            })
            const primaryIds = res.data.websiteSetPrimaryMutation.websites.map(w => ID_DECODER(w.id))
            state.commit("refreshCompanyWebsitePrimaries", primaryIds)
        } catch (e) {
            console.error(e)
        }
    }, async saveCompanyPhoneNumbers(state) {
        const phoneNumbers = state.getters.getCompanyPhoneNumbers
            .filter(item => item.label !== '' && item.phoneNumber !== '' && item.id === undefined) // Get new addresses with non empty fields
        const oldPhoneNumbers = state.getters.getCompanyPhoneNumbers
            .filter(item => item.id !== undefined)
        try {
            for (let i = 0; i < phoneNumbers.length; i++) {
                await apolloClient.mutate({
                    mutation: CREATE_PHONE,
                    variables: { input: { label: phoneNumbers[i].label, phoneNumber: phoneNumbers[i].phoneNumber, isPrimary: true, companyId: state.getters.getCompanyId } }
                })
            }
            for (let j = 0; j < oldPhoneNumbers.length; j++) {
                await apolloClient.mutate({
                    mutation: EDIT_PHONE,
                    variables: { input: { label: oldPhoneNumbers[j].label, phoneNumber: oldPhoneNumbers[j].phoneNumber, phoneId: oldPhoneNumbers[j].id } }
                })
            }
        } catch (err) {
            console.log(err)
        }
    },

    async createCompanyPhoneNumber(state, params) {
        try {
            const res = await apolloClient.mutate({
                mutation: CREATE_PHONE,
                variables: { input: { ...params, companyId: state.getters.getCompanyId } }
            })
            state.commit("appendCompanyPhoneNumber", singlePhoneNumberFormatter(res.data.phoneCreate.phoneNumber))
        } catch (e) {
            console.error(e)
        }
    },

    async deleteCompanyPhoneNumber(state, id) {
        try {
            await apolloClient.mutate({
                mutation: EDIT_PHONE,
                variables: { input: { delete: true, phoneId: id } }
            })
        } catch (err) {
            console.error(err)
        }
    },

    async setCompanyPhoneNumberPrimary(state, phoneIds) {
        try {
            const res = await apolloClient.mutate({
                mutation: SET_PHONE_PRIMARY,
                variables: { input: { companyId: state.getters.getCompanyId, phoneIds } }
            })
            const primaryIds = res.data.phoneSetPrimaryMutation.phoneNumbers.map(phone => ID_DECODER(phone.id))
            state.commit("refreshCompanyPhonePrimaries", primaryIds)

        } catch (e) {
            console.error(e)
        }
    },
    async saveCompanyEmails(state) {
        const emails = state.getters.getCompanyEmails
            .filter(item => item.email !== '' && item.id === undefined) // Get new addresses with non empty email field
        const oldEmails = state.getters.getCompanyEmails
            .filter(item => item.id !== undefined)
        try {
            for (let i = 0; i < emails.length; i++) {
                await apolloClient.mutate({
                    mutation: CREATE_EMAIL,
                    variables: { input: { label: emails[i].label, email: emails[i].email, isPrimary: true, companyId: state.getters.getCompanyId } }
                })
            }
            for (let j = 0; j < oldEmails.length; j++) {
                await apolloClient.mutate({
                    mutation: EDIT_EMAIL,
                    variables: { input: { label: oldEmails[j].label, email: oldEmails[j].email, emailId: oldEmails[j].id } }
                })
            }
        } catch (err) {
            console.log(err)
        }
    },

    async createCompanyEmail(state, params) {
        try {
            const res = await apolloClient.mutate({
                mutation: CREATE_EMAIL,
                variables: { input: { ...params, companyId: state.getters.getCompanyId } }
            })
            state.commit("appendCompanyEmail", singleEmailFormatter(res.data.emailCreate.email))
        } catch (e) {
            console.error(e)
        }
    },

    async deleteCompanyEmail(state, id) {
        try {
            await apolloClient.mutate({
                mutation: EDIT_EMAIL,
                variables: { input: { delete: true, emailId: id } }
            })
        } catch (err) {
            console.log(err)
        }
    },

    async setCompanyEmailPrimarys(state, emailIds) {
        try {
            const res = await apolloClient.mutate({
                mutation: SET_EMAIL_PRIMARY,
                variables: { input: { companyId: state.getters.getCompanyId, emailIds } }
            })
            const primaryIds = res.data.emailSetPrimaryMutation.emails.map(email => ID_DECODER(email.id))
            state.commit("refreshCompanyEmailPrimaries", primaryIds)
        } catch (err) {
            console.log(err)
        }
    },
    async saveCompanyAddresses(state) {
        const addresses = state.getters.getCompanyAddresses
            .filter(item => item.city !== '' && item.country !== '' && item.id === undefined) // Get new addresses with non empty fields
        const oldAddresses = state.getters.getCompanyAddresses
            .filter(item => item.id !== undefined)
        try {
            for (let i = 0; i < addresses.length; i++) {
                await apolloClient.mutate({
                    mutation: CREATE_ADDRESS,
                    variables: { input: { ...addresses[i], isPrimary: true, companyId: state.getters.getCompanyId } }
                })
            }
            for (let i = 0; i < oldAddresses.length; i++) {
                oldAddresses[i].addressId = oldAddresses[i].id
                delete oldAddresses[i].id
                await apolloClient.mutate({
                    mutation: EDIT_ADDRESS,
                    variables: { input: { ...oldAddresses[i] } }
                })
            }
        } catch (err) {
            console.log(err)
        }
    },

    async createCompanyAddress(state, params) {
        try {
            const res = await apolloClient.mutate({
                mutation: CREATE_ADDRESS,
                variables: { input: { ...params, companyId: state.getters.getCompanyId } }
            })
            state.commit("appendAddress", singleAddressFormatter(res.data.addressCreate.address))
        } catch (e) {
            console.error(e)
        }
    },

    async deleteCompanyAddress(state, id) {
        try {
            await apolloClient.mutate({
                mutation: EDIT_ADDRESS,
                variables: { input: { delete: true, addressId: id } }
            })
        } catch (err) {
            console.log(err)
        }
    },

    async setCompanyAddressPrimarys(state, addressIds) {
        try {
            const res = await apolloClient.mutate({
                mutation: SET_ADDRESS_PRIMARY,
                variables: { input: { companyId: state.getters.getCompanyId, addressIds } }
            })
            const primaryIds = res.data.addressSetPrimaryMutation.addresses.map(addr => ID_DECODER(addr.id))
            state.commit("refreshAddressPrimaries", primaryIds)
        } catch (e) {
            console.error(e)
        }
    }, async saveCompanySocials(state) {
        const socials = state.getters.getCompanySocials
            .filter(item => item.network !== '' && item.username !== '' && item.id === undefined)
        const oldSocials = state.getters.getCompanySocials
            .filter(item => item.id !== undefined)
        try {
            for (let i = 0; i < socials.length; i++) {
                await apolloClient.mutate({
                    mutation: CREATE_SOCIAL,
                    variables: { input: { ...socials[i], companyId: state.getters.getCompanyId } }
                })
            }
            for (let i = 0; i < oldSocials.length; i++) {
                oldSocials[i].lineId = oldSocials[i].id
                delete oldSocials[i].id
                await apolloClient.mutate({
                    mutation: EDIT_SOCIAL,
                    variables: { input: { ...oldSocials[i] } }
                })
            }
        } catch (e) {
            console.error(e)
        }
    },

    async createCompanySocial(state, params) {
        try {
            const res = await apolloClient.mutate({
                mutation: CREATE_SOCIAL,
                variables: { input: { ...params, companyId: state.getters.getCompanyId } }
            })
            state.commit("appendSocial", singleSocialFormatter(res.data.socialsLineCreate.socialLine))
        } catch (e) {
            console.error(e)
        }
    },

    async deleteCompanySocial(state, id) {
        try {
            await apolloClient.mutate({
                mutation: EDIT_SOCIAL,
                variables: { input: { delete: true, lineId: id } }
            })
        } catch (e) {
            console.error(e)
        }
    },
    async editCompanyMediaImage(state, params) {
        const img = await state.dispatch("editMediaImage", params)

        state.commit('replaceCompanyImage', img)
        if (params.logo == true) {
            state.commit('setCompanyProfilePicture', img.croppedImage)
            state.commit('setCompanyPicture', img.croppedImage)

        } else {
            state.commit("setCompanyPicture", '')
        }
    },

    async createCompanyMediaImage(state, params) {
        console.log(params)
        const img = await state.dispatch("createCompanysMediaImage", params)
        if (img) {
            let imageData = (img.isCropped) ? img.croppedImage : img.image;
            state.commit("setCompanyPicture", imageData)
            //state.commit("addCompanyImageToAlbum", img.image)
        }

    },
    replaceCompanyImage(state, img) {
        // find correct album
        let album = state.company.albums.filter(a => a.title.toLowerCase() === img.album.toLowerCase())
        if (album.length < 1)
            return
        album = album[0]
        let images = album.images
        // Loop until we find matching image
        for (let i = 0; i < images.length; i++) {
            if (images[i].uuid === img.uuid) {
                images[i] = img
                return
            }
        }
    },
    setCompanyProfilePicture: (state, image) => state.profilePicture = image,
    setCompanyPicture: (state, data) => state.profilePicture = data,
    async createCompanysMediaImage(state, params) {
        try {

            params = RemoveFalsy(params)

            let formData = new FormData();
            if (params.companyId) formData.append('company_id', params.companyId)
            if (params.thingId) formData.append('thing_id', params.thingId)

            if (params.file) formData.append('file', params.file)
            if (params.croppedFile) formData.append("cropped_file", params.croppedFile)
            if (params.crop) formData.append("crop", params.crop)
            if (params.title) formData.append('title', params.title)
            if (params.photographer) formData.append('photographer', params.photographer)
            if (params.caption) formData.append('caption', params.caption)
            if (params.date) formData.append('date', params.date)
            if (params.logo) formData.append('logo', params.logo)

            if (params.album) {
                formData.append("album", params.album)
                delete params['album']
            }


            let res = await axios.post(URI + "upload_image/", formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": await localStorage.get('accessToken')
                    }
                })
            if ("error" in res.data) {
                console.error(res.data.error);
                return;
            }

            if (res.data.success === false) {
                console.error(res.data.message)
                return
            }

            const img = await state.dispatch("fetchImageByUuid", res.data.uuid)

            // Returns image so that it can be handled correctly
            // somwhere else
            return img

        } catch (e) {
            console.error(e);
        }
    },

}