export default {
    getProductions(state) {
        return state.productions
    },
    getProduction(state) {
        return state.production
    },
    getProductionFilters(state) {
        return state.filters
    },
    getProductionFilterValues(state) {
        const obj = {}
        for (let i in state.filters) {
            obj[state.filters[i].name] = state.filters[i].value
        }
        return obj
    },
    getProductionBasicInfo(state) {
        return {
            id: state.production.id,
            name: state.production.name,
            productionType: state.production.productionType,
            company: state.production.company,
            companyType: state.production.companyType,
            role: state.production.role,
            director: state.production.director,
            venue: state.production.venue,
            space: state.production.space,
            startDate: state.production.startDate,
            endDate: state.production.endDate
        }
    },
    getCardFormattedProductions(state) {
        return state.productions.map(prod => {
            return {
                id: prod.id,
                title: prod.title,
                image: prod.logo,
                url: '/production/' + prod.id
            }
        }
        )
    },
    getEvent(state) {
        return state.event
    },
    getEvents(state) {
        return state.production.eventSet
    },
    getEventTypes(state) {
        return state.eventTypes
    },
    getProductionSortBy(state) {
        return state.sortBy
    },
    getProductionDocuments(state) {
        return state.production.documents
    },
    getProductionImages(state) {
        return state.production.images
    },
    getPreviewProductionImages(state) {
        return state.production.images.filter(img => img.enablePreview)
    },
    getProductionVideos(state) {
        return state.production.videos
    },

    getProductionMemberProfile: state => prodMemId => {
        for (let i = 0; i < state.production.productionMembers.length; i++) {
            if (state.production.productionMembers[i].id === prodMemId) {
                return state.production.productionMembers[i].profile
            }
        }
    },
    getProductionMemberRoles: state => profileId => {
        let res = []
        for (let i = 0; i < state.production.productionMembers.length; i++) {
            let mem = state.production.productionMembers[i]
            if (mem.profile.id === profileId) {
                res.push({ ...mem.job, prodMemId: mem.id })
            }
        }
        return res
    },

    getTableFormattedProductions(state) {
        return state.productions.map(prod => {
            return {
                id: prod.id,
                image: prod.logo,
                //  workType: prod.workType,
                name: prod.name,
                director: prod.director,
                venue: prod.venue,
            }
        })
    },

    getCalEvents(state) {
        return state.production.eventSet
    },

    getTableFormatterProductionMembers(state) {
        return state.production.productionMembers.map(member => {
            return {
                id: member.id,
                profileId: member.profile.id,
                name: member.profile.fullName,
                department: member.job.department,
                role: member.job.title,
                image: member.profile.profilePhoto
            }
        })
    },
    getProductionId: state => state.production.id,
    getProductionName: state => state.production.title,
    getProductionDescription: state => state.production.description,
    getProductionShortName: state => state.production.shortName,
    getProductionLogo: state => state.production.logo,
    getProductionDatePremiered: state => state.production.datePremiered,
    getProductionFinalPerformance: state => state.production.finalPerformance,
    getProductionTotalAudience: state => state.production.totalAudience,
    getProductionTotalPerformances: state => state.production.totalPerformances,
    getProductionVenue: state => state.venue,
    getProductionWorkType: state => state.production.workType,
    getProductionCompanies: state => state.production.productionCompanies,
    getProductionMembers: state => state.production.productionMembers,
    getProductionSeason: state => state.production.season,
    getWorkTypes: state => state.workTypes

}