export default {
    getEmails: state => state.emails,
    getEmailByIdx: state => idx => state.emails[idx],
    getUserPrimaryEmail(state) {
        if(state.emails.length < 1) return ''

        for(let i=0; i<state.emails.length; i++) {
            let email = state.emails[i]
            if(email.isPrimary) return email
        }
        return state.emails[0]
    }
}