import gql from "graphql-tag";

export const ProfileJobFragment = gql`
    fragment ProfileJobFragment on ProfileJobType {
        id
        title
        company
        department
        profileJobDetails{
            isPrimary
        }
    }
`
