export default {
    setUserMedicalContacts: (state, data) => state.medicalContacts = data,
    setUserMedicalConditions: (state, data) => state.medicalConditions = data,
    setMedicalRelationshipTypes: (state, data) => state.relationshipTypes = data,
    pushUserMedicalContact: (state, contact) => {
        // If contact with matching id exists, replace it
        let replaced = false;
        state.medicalContacts.forEach(c => {
            if (c.id == contact.id) {
                c.country = contact.country;
                c.name = contact.name;
                c.relationship = contact.relationship;
                c.phoneNumber = contact.phoneNumber;

                replaced = true;
            }
        })
        // If the contact is new
        if (!replaced) {
            state.medicalContacts.push(contact)
        }
    },
    pushUserMedicalCondition: (state, condition) => {
        // If contact with matching id exists, replace it
        let replaced = false;
        state.medicalConditions.forEach(c => {
            if (c.id == condition.id) {
                c.severity = condition.severity;
                c.name = condition.name;
                c.country = condition.country;
                c.doseSize = condition.doseSize;
                c.frequencyOfUse = condition.frequencyOfUse;
                replaced = true;
            }
        })
        // If the contact is new
        if (!replaced) {
            state.medicalConditions.push(condition)
        }
    },
    removeUserMedicalContact: (state, id) => {
        state.medicalContacts = state.medicalContacts.filter(m => m.id !== id);
    },
	removeUserMedicalCondition: (state, id) => {
        state.medicalConditions = state.medicalConditions.filter(m => m.id !== id);
    }
}