import gql from 'graphql-tag';
import { MediaImageLargeFragment, MediaLogoFragment } from './images/image.fragments';
import { MediaVideoFragment } from './videos/video.fragments';


export const MediaLibraryFragment = gql`
    fragment MediaLibraryFragment on MediaLibraryType {
        placeholder
        logo {
        ...MediaLogoFragment
        }
        images {
            edges {
                node {
                ...MediaImageLargeFragment
                }
            }
        }
        videos {
            edges {
                node {
                ...MediaVideoFragment
                }
            }
        }
    }
    ${MediaImageLargeFragment}
    ${MediaVideoFragment}
    ${MediaLogoFragment}
`

export const MediaLogoAndPlaceholderFragment = gql`
    fragment MediaLogoAndPlaceholderFragment on MediaLibraryType {
    logo{
    ...MediaLogoFragment
    }
    placeholder
    }${MediaLogoFragment}
`

export const MediaLibraryAlbumNamesFragment = gql`
    fragment MediaLibraryAlbumNamesFragment on MediaLibraryType {
        albumNames
    }
`
