import ObjMap from "./ObjMap";

const type = obj => Object.prototype.toString.call(obj)

/**
 * TODO: Write description
 */
const AutoTypenameRemover = data => {
    const dataStringType = type(data);

    // If we're dealing with a list, call this function on each item
    if (dataStringType === "[object Array]") {
        return data.map(item => AutoTypenameRemover(item))
    }
    // Object handling, here is the magic
    else if (dataStringType === "[object Object]") {
        // Copy the object but skip __typename if it is present
        const new_data = ObjMap(data, AutoTypenameRemover)
        if (new_data.__typename != null)
            delete new_data.__typename
        return new_data
    }
    // We hit one of the ends, a string, int or something like that, so we can stop recursing now.
    else {
        return data
    }
}

export default AutoTypenameRemover
