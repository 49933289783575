import { apolloClient } from "@/apollo/apolloClient";
import { EDIT_PHYSICAL } from "@/apollo/user/physical/user.physical.mutations";
import { performerPhysicalInfoFormatter } from "@/store/formatters";
import { GET_PERFORMER_INFO } from "@/apollo/user/physical/user.physical.queries";
// import { notify } from "@kyvg/vue3-notification";



export default {

    async editPhysicalInfo(state, params) {
        try {
            const res = await apolloClient.mutate({
                mutation: EDIT_PHYSICAL,
                variables: { input: params },
                fetchPolicy: 'no-cache'
            })
            await state.commit('setUserPhysicalInformation', performerPhysicalInfoFormatter(res.data.performerInfoEdit.performerInfo))
            if (res.data.performerInfoEdit.height) {
                await state.commit('setUserHeight', res.data.performerInfoEdit.height.value)
            }
            if (res.data.performerInfoEdit.weight) {
                await state.commit('setUserWeight', res.data.performerInfoEdit.weight.value)
            }
            if (res.data.performerInfoEdit.shoeSize) {
                await state.commit('setUserShoeSize', res.data.performerInfoEdit.shoeSize.value)
            }

            // notify({
            // title: "Success",
            // text: "Your data has been stored successfully",
            // type: 'success',
            // duration: 2000
            // })
        } catch (e) {
            console.error(e)
        }
    },

    async fetchPhysicalInfo(state) {
        try {
            const res = await apolloClient.query({
                query: GET_PERFORMER_INFO
            })
            for (let idx in res.data.me.profile.measures.edges) {
                let item = res.data.me.profile.measures.edges[idx].node;
                if (item.measure === "HEIGHT") {
                    state.commit('setUserHeight', item.value)
                } else if (item.measure === 'WEIGHT') {
                    state.commit('setUserWeight', item.value)
                } else if (item.measure === 'SHOE_SIZE') {
                    state.commit('setUserShoeSize', item.value)
                }
            }
            if (res.data?.me?.profile?.performerinformation) {
                state.commit('setUserPhysicalInformation', performerPhysicalInfoFormatter(res.data.me.profile.performerinformation))
            }

        } catch (e) {
            console.error(e)
        }
    }

}
