import getters from './getters'
import mutations from './mutations'
import actions from './actions'


export default {
    state: () => ({
        venueMembers: [],
        venues: [],
        calVenues: [{
            id: null,
            name: '',
        }],
        venueTypes: [],
        spaceTypes: [],
        countries: [],
        spaces: [],
        calSpaces: [{
            id: null,
            name: '',
            venue: '',
        }],
        configTypes: [],
        venue: {
            id: null,
            name: '',
            built: '',
            country: '',
            venueType: '',
            description: '',
            createdBy: '',
            venueSpaces: [{
                id: null,
                name: '',
                maxCapacity: '',
                spaceType: '',
                description: '',
                level: '',
                storage: '',
                spaceId: null,
            }],
            contact: {
                website: {
                    id: null,
                    url: '',
                    label: ''
                },
                email: {
                    id: null,
                    email: '',
                    label: ''
                },
                phone: {
                    id: null,
                    phone: '',
                    label: ''
                },
                address: {
                    id:null,
                    label: '',
                    address: ''
                },
            },
        },
        filters: {
            venueType: {
                title: 'venueType',
                options: [],
                value: ''
            },
            country: {
                title: 'country',
                options: [],
                value: ''
            },
            configType: {
                title: 'configType',
                options: [],
                value: ''
            },
            spaceType: {
                title: 'spaceType',
                options: [],
                value: ''
            },
        },
        sortBy: 'name'
    }),
    mutations: {
        ...mutations
    },
    actions: {
        ...actions
    },
    getters: {
        ...getters
    }

}