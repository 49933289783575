import { apolloClient } from '@/apollo/apolloClient'
import { GET_USER_PROFILE } from '@/apollo/user/basic/user.basic.queries'
import { EDIT_PROFILE, EDIT_OVERVIEW_SETTINGS } from "@/apollo/user/basic/user.basic.mutations";
import {
    singleBasicInfoFormatter,
    emailFormatter,
    addressFormatter,
    secondaryJobFormatter,
    phoneNumberFormatter,
    skillFormatter,
    profileProductionFormatter,
    educationFormatter,
    workExperienceFormatter,
    jobFormatter, websiteFormatter, socialFormatter, languageFormatter 
} from '../../formatters'



export default {
    async fetchBasicProfile(state){
        const res = await apolloClient.query({query: GET_USER_PROFILE, fetchPolicy: 'no-cache'})
        if(res.data.me){
            const data = res.data.me.profile
            state.commit("setUserLanguages", languageFormatter(data.languages))
            state.commit('setUserPrimaryJob', jobFormatter(data.primaryJob))
            state.commit('setUserSecondaryJobs', secondaryJobFormatter(data.secondaryJobs))
            state.commit('setAddresses', addressFormatter(data.contactinfo.addresses))
            state.commit('setSocials', socialFormatter(data.contactinfo.socials.lines))
            state.commit('setBasicInfo', singleBasicInfoFormatter(data))
            state.commit('setPhoneNumbers', phoneNumberFormatter(data.contactinfo.phoneNumbers))
            state.commit('setEmails', emailFormatter(data.contactinfo.emails))
            state.commit('setUserWebsites', websiteFormatter(data.contactinfo.websites))
            state.commit('setUserSkills', skillFormatter(data.additionalSkills))
            state.commit('setUserEducation', educationFormatter(data.education))
            state.commit('setUserProfileProductions', profileProductionFormatter(data.profileProduction))
            state.commit('setWorkExperience', workExperienceFormatter(data.profileWorkExperience.edges))
        }
    },
    async saveBasicInfo(state){
        // await state.dispatch('saveUserPrimaryJob')
        await state.dispatch('saveWebsites')
        await state.dispatch('saveEmails')
        await state.dispatch('savePhoneNumbers')
        await state.dispatch('saveAddresses')
        await state.dispatch('saveSocials')
        try{
            const info = {...state.getters.basicInfo}
            delete info["header"]
            delete info["overviewSettings"]
            delete info["about"]
            const res = await apolloClient.mutate({
                mutation: EDIT_PROFILE,
                variables: {input: {...info, id:state.getters.getId}}
            })

            const data = res.data.profileEdit.profile
            await state.commit('setBasicInfo', singleBasicInfoFormatter(data))
        } catch (err){
            await state.dispatch('fetchBasicProfile')
            console.log(err)
        }
    },

    async editProfile(state, params){
        try {
            await apolloClient.mutate({
                mutation: EDIT_PROFILE,
                variables: {input: {...params, id:state.getters.getId}}
            })
            
        } catch (e) {
            console.error(e)
        }
    },

    async editProfileOverviewSettings(state, params){
        try {
            const res = await apolloClient.mutate({
                mutation: EDIT_OVERVIEW_SETTINGS,
                variables: {input: {...params, profileId:state.getters.getId}}
            })
            state.commit('setUserOverviewSettings', res.data.profileOverviewSettingsMutation.overviewSettings)
        } catch (e) {
            console.error(e)
        }
    }


    

}
