
export default {
    setUserProgrammeBiog(state, data) {
        state.biog.version1 = data.version1
        state.biog.version2 = data.version2
        state.biog.version3 = data.version3
        state.biog.version4 = data.version4
    },

    setSingleUserProgrammeBiog(state, { version, data }) {
        if (state.biog[`version${version}`] !== null && state.biog[`version${version}`] !== undefined) {
            state.biog[`version${version}`] = data
        }
    }
}
