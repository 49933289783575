import getters from './getters'
import mutations from './mutations'
import actions from './actions'


export default {
    state: () => ({
        primaryJob: {
            id: null,
            title: null,
            company: null,
            department: null
        },
      secondaryJobs: []
    }),
    mutations: {
        ...mutations
    },
    actions: {
        ...actions
    },
    getters: {
        ...getters
    }
}
