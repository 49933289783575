import gql from "graphql-tag";

export const GET_COUNTRIES = gql`
    query countries{
        countries
    }  
`

export const GET_SOCIALS = gql`
    query socials{
        socials
    }
`

export const GET_DOCUMENT_TYPES = gql`
    query documentTypes{
        documentTypes
    }
`

export const GET_DEPARTMENT_TYPES = gql `
    query departmentTypes{
        departmentTypes{
            edges{
                node{
                    name
                }
            }
        }
    }
`

export const GET_PROFILE_SKILLS = gql `
    query profileSkills($title:String!){
        profileSkills(title:$title)
    }
`

export const GET_PROFILE_JOBS = gql `
    query profileJobs($title:String!){
        profileJobs(title:$title)
    }
`


export const GET_DIVERSITY_OPTIONS = gql`
    query diversityOptions {
        ethnicities
        religions
        sexualities
        genders
        sexes 
        disabilities
        caringResponsibilities
}
`
