import {apolloClient} from "@/apollo/apolloClient";
import { EDIT_WEBSITE, CREATE_WEBSITE, SET_WEBSITE_PRIMARY } from "@/apollo/contact_info/contact_info.mutations";
import ID_DECODER from '@/utils/ID_DECODER'

export default {
    async saveWebsites(state){
        const websites = state.getters.getUserWebsites
            .filter(item => item.url !== '' && item.id === undefined)
        const oldWebsites = state.getters.getUserWebsites
            .filter(item => item.id !== undefined)

        try{
            for(let i=0; i < websites.length; i++){
                await apolloClient.mutate({
                    mutation: CREATE_WEBSITE,
                    variables: {input: {...websites[i], profileId:state.getters.getId}}
                })
            }
            for(let i=0; i < oldWebsites.length; i++){
                await apolloClient.mutate({
                    mutation: EDIT_WEBSITE,
                    variables: {input: {url: oldWebsites[i].url, title: oldWebsites[i].title, id: oldWebsites[i].id}}
                })
            }
        } catch (e) {
            console.error(e)
        }
    },
    async deleteWebsite(state, id){
        try {
            await apolloClient.mutate({
                mutation: EDIT_WEBSITE,
                variables: {input: {delete:true, id:id}}
            })
            state.commit("removeWebsiteById", id)
        } catch (e) {
            console.error(e)
        }
    },

    async setWebsitePrimary(state, websiteIds){
        try {
            const res = await apolloClient.mutate({
                mutation: SET_WEBSITE_PRIMARY,
                variables: {input: {profileId:state.getters.getId, websiteIds}}
            })
            const primaryIds = res.data.websiteSetPrimaryMutation.websites.map(w => ID_DECODER(w.id))
            state.commit("refreshWebsitePrimaries", primaryIds)
        } catch (e) {
            console.error(e)
        }
    }
}
