import gql from 'graphql-tag';
import { ProductionFragment, ReviewFragment, ProductionMemberFragment } from './productions.fragments';

export const CREATE_PRODUCTION = gql`
    mutation createProduction($input: ProductionInput!){
        productionCreateMutation(input: $input){
            production{
            ...ProductionFragment
            }
        }
    }${ProductionFragment}
`

export const EDIT_PRODUCTION = gql`
    mutation editProduction($input: ProductionInput!){
        productionEditMutation(input: $input){
            production{
            ...ProductionFragment
            }
        }
    }${ProductionFragment}
`

export const CREATE_PRODUCTION_MEMBER = gql`
    mutation productionMemberCreate($input:ProductionMemberInput!){
        productionMemberCreate(input:$input) {
            productionMember{
            ...ProductionMemberFragment
            }
        }
    }${ProductionMemberFragment}
`

export const EDIT_PRODUCTION_MEMBER = gql`
    mutation editProductioMember($input: ProductionMemberInput!){
        productionMemberEdit(input:$input){
            productionMember{
            ...ProductionMemberFragment
            }
        }
    }${ProductionMemberFragment}
`


export const CREATE_REVIEW = gql`
    mutation createReview($input:ReviewInput!){
          reviewCreate(input:$input){
            review {
            ...ReviewFragment
            }
        }
    }${ReviewFragment}
`

export const EDIT_REVIEW = gql `
    mutation editReview($input:ReviewInput!){
          reviewEdit(input:$input){
            review {
            ...ReviewFragment
            }
        }
    }${ReviewFragment}
`
