import { createApp, h, provide } from 'vue';
import App from './App.vue';
import { store } from './store'
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from "@sentry/tracing";
import { ApolloClients } from '@vue/apollo-composable';
import { apolloClient } from "@/apollo/apolloClient";
import { createHead } from '@vueuse/head'
import Notifications from '@kyvg/vue3-notification'
import router from './router'
import './index.css'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
    faCalendarCheck,faAddressBook,faCircleLeft
} from '@fortawesome/free-regular-svg-icons';

import clickOutside from 'vue-detect-click-outside';


/* import specific icons */
import {
    faUser,
    faMasksTheater,
    faHotel,
    faBuilding,
    faBoxOpen,
    faUsers,
    faFileLines,
    faScroll,
    faBookOpen,
    faLocationDot,
    faPlus,
    faPeopleGroup,
    faEye,
    faEyeSlash,
    faCameraRetro,
    faCamera,
    faPhone,
    faGlobe,
    faFile,
    faImagePortrait,
    faAt,
    faEnvelope,
    faLink,
    faChevronUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faPencil,
    faCircle,
    faXmark,
    faCircleXmark,
    faHeart,
    faHeartCrack,
    faCheck,
    faMagnifyingGlass,
    faAnglesUp,
    faAnglesDown,
    faAnglesRight,
    faAnglesLeft,
    faHouse,
    faFilter,
    faCircleExclamation,
    faTriangleExclamation,
    faPenToSquare,
    faPerson,
    faPlaneDeparture,
    faWrench,
    faFolderPlus,
    faTrophy,
    faCakeCandles,
    faTransgender,
    faUserSecret,
    faLaptopFile,
    faWeightScale,
    faArrowDown,
    faBriefcase,
    faEarthAmericas,
    faAnchor
} from '@fortawesome/free-solid-svg-icons'
import {
    faCircle as faCircleR,
    faHeart as faHeartOutline,
    faBell,
} from '@fortawesome/free-regular-svg-icons'
import {
    faFacebook,
    faFacebookF,
    faApple,
    faGoogle,
    faTwitter,
    faAccessibleIcon,
} from '@fortawesome/free-brands-svg-icons'
/* add icons to the library */
library.add(
    faUser,
    faMasksTheater,
    faHotel,
    faBuilding,
    faBoxOpen,
    faUsers,
    faFileLines,
    faScroll,
    faBookOpen,
    faLocationDot,
    faPlus,
    faPeopleGroup,
    faEye,
    faEyeSlash,
    faCameraRetro,
    faCamera,
    faPhone,
    faGlobe,
    faFile,
    faImagePortrait,
    faAt,
    faEnvelope,
    faLink,
    faChevronUp,
    faChevronDown,
    faPencil,
    faCircle,
    faCircleR,
    faXmark,
    faCircleXmark,
    faHeartOutline,
    faHeart,
    faHeartCrack,
    faCheck,
    faMagnifyingGlass,
    faChevronRight,
    faChevronLeft,
    faAnglesUp,
    faAnglesDown,
    faAnglesRight,
    faAnglesLeft,
    faHouse,
    faFilter,
    faCircleExclamation,
    faTriangleExclamation,
    faPenToSquare,
    faFacebook,
    faFacebookF,
    faApple,
    faGoogle,
    faTwitter,
    faAddressBook,
    faCalendarCheck,
    faPerson,
    faAccessibleIcon,
     faPlaneDeparture,
     faWrench,
     faFolderPlus,
     faCircleLeft,
     faTrophy,
     faCakeCandles,
    faTransgender,
    faUserSecret,
    faLaptopFile,
    faWeightScale,
    faArrowDown,
    faBriefcase,
    faBell,
    faEarthAmericas,
    faAnchor
    )

const app = createApp({
    setup() {
        provide(ApolloClients, {
            default: apolloClient
        });
    },
    render() {
        return h(App);
    }
})

const head = createHead()

app.directive('click-outside', {
    mounted(el, binding) {
        el.clickOutsideEvent = event => {
            // Make sure we don't get an error trying to call something other then a function
            if (typeof binding.value !== "function")
                throw new Error("Code in v-click-outside must return a function, not " + typeof binding.value + ". See usage in src/components/inputs/DropdownTree.vue")

            // Call the binding value if the click was outside the element
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el)
            }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unmounted(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    }
})

app.use(router).use(store).use(Notifications).use(head).use(clickOutside).component('font-awesome-icon', FontAwesomeIcon).mount('#app');

if (process.env.NODE_ENV == "production") {
    Sentry.init({
        app,
        dsn: "https://cba091c8bbe141a8871f43100dd3c131@o1236297.ingest.sentry.io/6386396",
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["develop.showdeck.io", /^\//],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}




