import gql from "graphql-tag";
import { ProgrammeBiogFragment } from "./user.programmeBiog.fragments";

export const EDIT_USER_PROGRAMME_BIOGS = gql`
    mutation programmeBiogMutation($input: ProfileProgrammeBiogInput!) {
        profileProgrammeBiogMutation(input: $input) {
            success
            message
            programmeBiog {
            ...ProgrammeBiogFragment
            }
        }
    }${ProgrammeBiogFragment}
`
