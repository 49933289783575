
export default {
    async dynamicTableLoadPeople(store){
        await store.dispatch('fetchProfileFilters')
        const params = store.getters.getDynamicTableParams
        const rangeFilterValues = store.getters.getPeopleRangeFilterValues
        const variables = {...params, ...rangeFilterValues, name:params.searchFilter,
            ...store.getters.getPeopleFilterValues, sortBy: store.getters.getPeopleSortBy}
        const res = await store.dispatch('fetchPeople', variables)
		store.commit('setBaseDynamicTableValues', res)
        store.commit('setDynamicTableFields', ['Name', 'Job', 'Location'])
        store.commit('setDynamicTablePhotoFields', ['name', 'job'])
        store.commit('setDynamicTableItems', store.getters.getTableFormattedPeople)
    },

    async dynamicTableLoadCompanies(store){
        await store.dispatch('fetchCompanyFilters')
        const params = store.getters.getDynamicTableParams
        const res = await store.dispatch('fetchCompanies', {...params, name:params.searchFilter, ...store.getters.getCompanyFilterValues, sortBy: store.getters.getCompaniesSortBy})
        store.commit('setBaseDynamicTableValues', res)
        store.commit('setDynamicTableFields', ["Name", 'Type', 'Country'])
        store.commit('setDynamicTablePhotoFields', ['name', 'type'])
        store.commit('setDynamicTableItems', store.getters.getTableFormattedCompanies)

    },
    async dynamicTableLoadCompanyMembers(store){
        if(store.getters.getCompanyId === null) return
        const res = await store.dispatch('fetchCompanyMembers', {...store.getters.getDynamicTableParams, companyId: store.getters.getCompanyId })
        store.commit('setBaseDynamicTableValues', res)
        store.commit('setDynamicTableFields', ['Name', 'Role','Department','Email'])
        store.commit('setDynamicTablePhotoFields', ['name', 'role','department','email'])
        store.commit('setDynamicTableItems', store.getters.getTableFormattedCompanyMembers)
    },
    async dynamicTableLoadUnifiedCompanyMembers(store){
        if(store.getters.getCompanyId === null) return
        const res = await store.dispatch('fetchCompanyMembers', {...store.getters.getDynamicTableParams, companyId: store.getters.getCompanyId })
        store.commit('setBaseDynamicTableValues', res)
        store.commit('setDynamicTableFields', ['Name', 'Role'])
        store.commit('setDynamicTablePhotoFields', ['name', 'role'])

        store.commit('setDynamicTableItems', store.getters.getTableFormattedCompanyMembers)
    },
    async dynamicTableLoadVenues(store){
        await store.dispatch('fetchVenueFilters')
        const params = store.getters.getDynamicTableParams
        const res = await store.dispatch('fetchVenues', {...params, name:params.searchFilter,...store.getters.getVenueFilterValues, sortBy: store.getters.getVenueSortBy})
        store.commit('setBaseDynamicTableValues', res)
        store.commit('setDynamicTableFields', ["Name", 'venueType', 'Country'])
        store.commit('setDynamicTablePhotoFields', ['name', 'venueType'])

        store.commit('setDynamicTableItems', store.getters.getVenues)
    },
    async dynamicTableLoadSpaces(store){
        await store.dispatch('fetchSpaceFilters')
        const params = store.getters.getDynamicTableParams
        const res = await store.dispatch('fetchSpaces',{...params, name:params.searchFilter,...store.getters.getSpaceFilterValues, sortBy: store.getters.getSpaceSortBy})
        store.commit('setBaseDynamicTableValues', res)
        store.commit('setDynamicTableFields', ["Name", 'maxCapacity', 'Venue', 'spaceType', ])
        store.commit('setDynamicTableItems', store.getters.getSpaces)
    },
    async dynamicTableLoadProductions(store){
        const params = store.getters.getDynamicTableParams
        const res = await store.dispatch('fetchProductions',{...params, name:params.searchFilter,...store.getters.getProductionFilterValues,sortBy: store.getters.getProductionSortBy}) 
        store.commit('setBaseDynamicTableValues', res)
		console.log(store.getters.getDynamicTableItems);
        store.commit('setDynamicTableFields', ["Name","Venue","Director"])
        store.commit('setDynamicTablePhotoFields', ["Name","Venue","Director"])
        store.commit('setDynamicTableItems', store.getters.getTableFormattedProductions)
    },
    async dynamicTableLoadProductionMembers(store){
        const params = store.getters.getDynamicTableParams
        const res = await store.dispatch('fetchProductionMembers', {...params, productionId: store.getters.getProductionId, sortBy:store.getters.getProductionSortBy})
        store.commit('setBaseDynamicTableValues', res)
        store.commit('setDynamicTableFields', ["Name", 'Department', 'Role'])
        store.commit('setDynamicTablePhotoFields', ['name', 'role'])
        store.commit('setDynamicTableItems', store.getters.getTableFormatterProductionMembers)
    }



}

