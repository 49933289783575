import AutoIdDecoder from "@/utils/AutoIdDecoder";
import AutoEdgeNodeRemover from "@/utils/AutoEdgeNodeRemover";
import AutoTypenameRemover from "@/utils/AutoTypenameRemover";
import capitalize from "@/utils/Capitalize";
import underscoreToSpace from "@/utils/UnderscoreToSpace";


export const singleBasicInfoFormatter = (data) => {
    return { ...data, primaryJob: data.primaryJob ? data.primaryJob.title : "" }
}

export const addressFormatter = ({ edges }) => {
    return edges.map(item => singleAddressFormatter(item.node))
}

export const singleAddressFormatter = data => AutoTypenameRemover(AutoIdDecoder(data))

export const awardFormatter = ({ edges }) => {
    return edges.map(item => singleAwardFormatter(item.node))
}

export const singleAwardFormatter = data => AutoTypenameRemover(AutoIdDecoder(data))

export const singleLanguageFormatter = node => {
    const data = AutoTypenameRemover(AutoIdDecoder(node))
    return { ...data, language: data.language?.name ?? "" }
}

export const languageFormatter = ({ edges }) => {
    return edges.map(node => singleLanguageFormatter(node.node))
}

export const workExperienceFormatter = data => {
    return data.map(el => singleWorkExperienceFormatter(el.node))
}

export const singleWorkExperienceFormatter = exp => AutoTypenameRemover(AutoIdDecoder(exp))

export const albumFormatter = ({ edges }) => {
    return edges.map(edge => singleAlbumFormatter(edge.node))
}

export const singleAlbumFormatter = album => AutoTypenameRemover(AutoIdDecoder(AutoEdgeNodeRemover(album)))

export const imageFormatter = ({ edges }) => {
    return edges.map(edge => singleImageFormatter(edge.node))
}

export const singleImageFormatter = image => AutoTypenameRemover(AutoIdDecoder(image))

export const videoFormatter = ({ edges }) => {
    return edges.map(edge => singleVideoFormatter(edge.node))
}

export const singleVideoFormatter = video => {
    return { ...video, thumbnail: video.customThumbnail || video.thumbnail }
}

export const educationFormatter = ({ edges }) => {
    return edges.map(el => singleEducationFormatter(el.node))
}

export const singleEducationFormatter = education => AutoTypenameRemover(AutoIdDecoder(education))

export const skillFormatter = ({ edges }) => {
    return edges.map(el => el.node.title)
}

export const profileProductionFormatter = ({ edges }) => {
    return edges.map(el => singleProfileProductionFormatter(el.node))
}

export const singleProfileProductionFormatter = prod => AutoTypenameRemover(AutoIdDecoder(prod))

export const socialFormatter = ({ edges }) => {
    return edges.map(item => singleSocialFormatter(item.node))
}

export const singleSocialFormatter = data => AutoTypenameRemover(AutoIdDecoder(data))

export const singleSecondaryJobFormatter = job => {
    const data = AutoTypenameRemover(AutoIdDecoder(job))
    return { ...data, isPrimary: data.profileJobDetails.isPrimary }
}

export const secondaryJobFormatter = ({ edges }) => {
    return edges.map(el => singleSecondaryJobFormatter(el.node))
}

export const jobFormatter = data => {
    if (!data) return { company: "", title: "", department: "" }
    return AutoTypenameRemover(AutoIdDecoder(data))
}

export const phoneNumberFormatter = ({ edges }) => {
    return edges.map(item => singlePhoneNumberFormatter(item.node))
}

export const singlePhoneNumberFormatter = data => AutoTypenameRemover(AutoIdDecoder(data))

export const emailFormatter = ({ edges }) => {
    return edges.map(item => singleEmailFormatter(item.node))
}

export const singleEmailFormatter = data => AutoTypenameRemover(AutoIdDecoder(data))

export const websiteFormatter = ({ edges }) => {
    return edges.map(item => singleWebsiteFormatter(item.node))
}

export const singleWebsiteFormatter = website => AutoTypenameRemover(AutoIdDecoder(website))

export const eventFormatter = ({ edges }) => {
    return edges.map(item => singleEventFormatter(item.node))
}

export const singleEventFormatter = data => {
    const event = AutoTypenameRemover(AutoIdDecoder(data))
    return {
        ...event,
        productionId: event.production?.id ?? "",
        venueId: event.venue?.id ?? "",
        spaceId: event.space?.id ?? "",
    }
}

export const seasonFormatter = ({ edges }) => {
    return edges.map(item => singleSeasonFormatter(item.node))
}

export const singleSeasonFormatter = data => {
    const season = AutoTypenameRemover(AutoIdDecoder(data))
    return { ...season, companyId: season.company?.id ?? "" }
}

export const calVenueFormatter = data => {
    const a = AutoEdgeNodeRemover(data)
    const b = AutoTypenameRemover(AutoIdDecoder(a))
    return b
}

export const singleSpaceFormatter = space => {
    const data = AutoTypenameRemover(AutoIdDecoder(space))
    return {
        ...data,
        storage: space.storage?.name ?? "",
        venue: space.venue?.name ?? "",
        spaceId: space.spaceId,
        image: space.medialibrary?.logo?.croppedImage || space.medialibrary?.placeholder
    }
}

export const spaceFormatter = ({ edges }) => {
    return edges.map(item => singleSpaceFormatter(item.node))
}

export const singleStorageFormatter = data => AutoEdgeNodeRemover(AutoTypenameRemover(AutoIdDecoder(data)))

export const calSpaceFormatter = data => AutoTypenameRemover(AutoIdDecoder(AutoEdgeNodeRemover(data)))

export const singleVenueFormatter = data => {
    const venue = AutoTypenameRemover(AutoIdDecoder(AutoEdgeNodeRemover(data)))

    return {
        ...venue,
        email: venue.contactinfo?.emails?.[0] ?? "",
        phone: venue.contactinfo?.phoneNumbers?.[0] ?? "",
        website: venue.contactinfo?.websites?.[0] ?? "",
        createdBy: venue.createdBy?.profile?.id ?? "",
        logo: venue.medialibrary?.logo?.croppedImage || venue.medialibrary?.placeholder,
        venueSpaces: data.venueSpaces ? spaceFormatter(data.venueSpaces) : ""
    }
}

// This could be changed to use the singleVenueFormatter in the future, however, as it moves
// venueType.name into venueType it may not be compatible if the code using the two formatters is
// expecting different behavior from venue.venueType.
export const venueFormatter = ({ edges }) => {
    return edges.map(item => {
        const venue = AutoTypenameRemover(AutoIdDecoder(item.node))
        return {
            ...venue,
            venueType: venue.venueType?.name ?? "",
            image: venue.medialibrary?.logo?.croppedImage || venue.medialibrary?.placeholder
        }
    })
}

export const peopleFormatter = (data) => {
    // console.log('peopleFormatter. data:', data)
    return data.map(item => {
        item = AutoTypenameRemover(AutoIdDecoder(AutoEdgeNodeRemover(item.node)))
        return {
            ...item,
            fullName: nameFormatter(item.firstName, item.middleName, item.surname),
            primaryJob: item.primaryJob?.title ?? "",
            secondaryJobs: item.secondaryJobs,
            profilePhoto: item.medialibrary?.logo?.croppedImage ?? item.medialibrary?.placeholder ?? "",
            location: item.contactinfo?.addresses?.[0]?.country ?? "",
            email: item.contactinfo?.emails?.[0]?.email ?? ""
        }
    })
}

export const CompanyPeopleFormatters = (data) => {
    // console.log('peopleFormatter. data:', data)
    //random people
    // item = AutoTypenameRemover(AutoIdDecoder(AutoEdgeNodeRemover(item.node)))
    return data.map(item => {
        item = AutoTypenameRemover(AutoIdDecoder(AutoEdgeNodeRemover(item.node.member.profile)))
        return {
            ...item,
            fullName: nameFormatter(item.firstName, item.middleName, item.surname),
            primaryJob: item.primaryJob?.title ?? "",
            secondaryJobs: item.secondaryJobs,
            profilePhoto: item.medialibrary?.logo?.croppedImage ?? item.medialibrary?.placeholder ?? "",
            location: item.contactinfo?.addresses?.[0]?.country ?? "",
            email: item.contactinfo?.emails?.[0]?.email ?? ""
        }
    })
}

//  Joins together first, middle and last names into a single name
//  and then remove extra whitespaces from the full name
//  (which happens when the middle name is " ")
export const nameFormatter = (first, middle, last) => [first, middle, last].join(" ").replace(/\s+/g, " ").trim()


export const singleCompanyFormatter = data => {
    data = AutoTypenameRemover(AutoIdDecoder(AutoEdgeNodeRemover(data)))
    const socials = data.contactinfo?.socials?.lines ?? data.contactinfo?.socials ?? [];
    socials.map((s) => {
        if (!s.logo) {
            s.logo = "https://s3.eu-west-1.amazonaws.com/showdeck.io/static/assets/img/social_media_icons/black/" + s.network.toLowerCase() + ".svg";
        }
        if (!s.wLogo) {
            s.wLogo = "https://s3.eu-west-1.amazonaws.com/showdeck.io/static/assets/img/social_media_icons/white/" + s.network.toLowerCase() + ".svg"
        }
    })
    return {
        ...data,
        // companyMembers: data.companyMembers ?  companyMemberFormatter(data.companyMembers) : [],
        email: data.contactinfo?.emails?.[0] ?? "",
        phone: data.contactinfo?.phoneNumbers?.[0] ?? "",
        website: data.contactinfo?.websites?.[0] ?? "",
        createdBy: data.createdBy?.profile?.id ?? "",
        logo: data.medialibrary?.logo?.croppedImage || data.medialibrary?.placeholder,
        type: data.type?.name,
        seasonSet: (data.seasonSet ?? []).map(season => ({ ...season, companyId: season.company?.id ?? "" })),
        level: data.level?.name ?? "",
        header: {
            ...data.contactinfo,
            socials
        }
    }
}
export const companyFormatter = ({ edges }) => {
    return edges.map(item => singleCompanyFormatter(item.node))
}

export const productionFormatter = ({ edges }) => {
    return edges.map((item) => singleProductionFormatter(item.node))
}
export const singleProductionFormatter = data => {
    data = AutoTypenameRemover(AutoIdDecoder(AutoEdgeNodeRemover(data)))
    return {
        ...data,
        workType: data.workType?.name ?? "",
        productionCompanies: data.productionCompanies ?? [],
        productionMembers: [],
        //   logo: data.medialibrary.logo?.croppedImage || data.medialibrary.placeholder,
        season: data.season ?? [],
        eventSet: (data.eventSet ?? []).map(event => ({
            ...event,
            productionId: event.production?.id ?? "",
            venueId: event.venue?.id ?? "",
            spaceId: event.space?.id ?? ""
        })),
        websites: data.contactinfo?.websites ?? [],
        reviews: data.reviews ?? []
    }
}


// This formatter takes in input that has already been run through the AutoIdDecoder and
// AutoEdgeNodeRemover. Other input will not give the correct result.
export const productionVideoFormatter = videos => {
    return videos.map(node => {
        let parts = node.link.split('/')
        parts = parts[parts.length - 1]
        let embedLink = parts.split('=')[1]
        return { ...node, embedLink }
    })
}

export const singleProductionDocumentFormatter = doc => {
    doc = AutoTypenameRemover(AutoIdDecoder(doc))
    return { ...doc, department: doc.department?.name ?? "" }
}

export const productionDocumentFormatter = ({ edges }) => {
    return edges.map(node => singleProductionDocumentFormatter(node.node))
}

export const singleProductionMemberFormatter = node => {
    node = AutoTypenameRemover(AutoIdDecoder(AutoEdgeNodeRemover(node)))
    return {
        ...node,
        job: {
            ...node.job,
            department: node.department?.name ?? "",
            isPrimary: node.isPrimary
        },
        profile: {
            ...node.profile,
            fullName: nameFormatter(node.profile.firstName, node.profile.middleName, node.profile.surname),
            profilePhoto: node.profile.profilePhotos?.[0]?.image ?? node.profile.placeholder
        }
    }
}

export const productionMemberFormatter = ({ edges }) => {
    return edges.map(({ node }) => singleProductionMemberFormatter(node))
}

export const singleProfileFormatter = data => {
    data = AutoTypenameRemover(AutoIdDecoder(AutoEdgeNodeRemover(data)))
    return {
        ...data,
        primaryJob: data.primaryJob ?? "",
        profilePhoto: data.medialibrary?.logo?.croppedImage ?? data.medialibrary?.placeholder ?? "",
        images: data.medialibrary?.images ?? [],
        addresses: data.contactinfo?.addresses ?? [],
        emails: data.contactinfo?.emails ?? [],
        phoneNumbers: data.contactinfo?.phoneNumbers ?? [],
        websites: data.contactinfo?.websites ?? [],
        socials: data.contactinfo?.socials?.lines ?? [],
        education: data.education ?? [],
        skills: (data.additionalSkills ?? []).map(skill => skill.title),
        workExperience: data.profileWorkExperience ?? [],
        profileProductions: data.profileProduction ?? [],
        enableVcard: data.overviewSettings.enableVcard
    }
}

export const performerPhysicalInfoFormatter = data => {
    data = AutoTypenameRemover(AutoIdDecoder(data))
    return {
        ...data,
        beardLength: capitalize(data.beardLength),
        beardType: capitalize(data.beardType),
        beardColour: capitalize(data.beardColour),
        bodyType: capitalize(data.bodyType),
        eyeColour: capitalize(data.eyeColour),
        hairColour: capitalize(data.hairColour),
        hairLength: underscoreToSpace(capitalize(data.hairLength)),
        hairType: underscoreToSpace(capitalize(data.hairType)),
        voiceType: capitalize(data.voiceType),
    }
}

export const medicalContactFormatter = data => {
    return data.map(e => singleMedicalContactFormatter(e.node))
}

export const singleMedicalContactFormatter = data => AutoTypenameRemover(AutoIdDecoder(data))

export const diversityFormatter = data => {
    return {
        ethnicity: data.ethnicity?.name ?? "",
        religion: data.religion?.name ?? "",
        sex: data.sex?.name ?? "",
        gender: data.gender?.name ?? "",
        sexuality: data.sexuality?.name ?? "",
        caringResponsibilities: data.caringResponsibilities ?? "",
        disabilities: data.disabilities ?? "",
    }
}

export const documentTypeFormatter = data => {
    if (data === null)
        return [];
    const re = /([^"',]+)(\w*?)([^"',]+)/g
    return data.match(re)
}

export const departmentTypeFormatter = data => {
    return data.map(dep => dep.node.name)
}

export const mediaEncoder = async (file) => {
    try {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    } catch (e) {
        return null
    }
}

export const singleCategoryFormatter = data =>
    AutoTypenameRemover(AutoIdDecoder(AutoEdgeNodeRemover(data)))

export const categoryFormatter = ({ edges }) =>
    edges.map(item => singleCategoryFormatter(item.node))

export const thingFormatter = ({ edges }) =>
    edges.map(item => singleThingFormatter(item.node))

export const singleThingFormatter = data => {
    const formatted = AutoTypenameRemover(AutoIdDecoder(AutoEdgeNodeRemover(data)))

    // Grab basic info no matter if a person or company is the owner of the thing
    if (formatted.owner != null) {
        let type, fullName
        // Handling if the owner is a user
        if (formatted.owner.profile != null) {
            const profile = formatted.owner.profile
            type = "user"
            fullName = nameFormatter(profile.firstName, profile.middleName, profile.surname)
        }
        // Handling if the owner is a company
        else {
            type = "company"
            fullName = formatted.owner.company.name
        }

        formatted.owner = { ...formatted.owner, type, basic: { fullName } }
    }

    return formatted
}
export const companyMemberFormatter = ({ edges }) => {
    return edges.map(item => singleCompanyMemberFormatter(item.node))
}
export const singleCompanyMemberFormatter = node => {
    const item = AutoTypenameRemover(AutoIdDecoder(node))
    const profile = item.member?.profile
    const media = profile.medialibrary
    return {
        jobs: item.allJobs.edges?.map(job => ({ job: job.node?.job?.title ?? "", department: item.department?.name ?? "" })),
        member: {
            id: profile?.id,
            name: nameFormatter(profile?.firstName ?? "", profile?.middleName ?? "", profile?.surname ?? ""),
            image: media.logo ? (media.logo.croppedImage ? media.logo.croppedImage : media.logo.image) : media.placeholder
        }
    }
}
export const companyPeopleFormatterItem = ({ edges }) => {
    return edges.map(item => singleCompanyPeopleFormatter(item.node))
}
export const singleCompanyPeopleFormatter = node => {
    const item = AutoTypenameRemover(AutoIdDecoder(node))
    const profile = item.member?.profile
    const media = profile.medialibrary
    return {
        member: {
            id: profile?.id,
            name: nameFormatter(profile?.firstName ?? "", profile?.middleName ?? "", profile?.surname ?? ""),
            image: media.logo ? (media.logo.croppedImage ? media.logo.croppedImage : media.logo.image) : media.placeholder
        }
    }
}


