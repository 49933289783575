import { apolloClient } from "@/apollo/apolloClient";
import {
    GET_MEDICAL_CONTACT_RELATIONSHIP_TYPES,
    GET_USER_MEDICAL_CONTACTS,
    GET_USER_MEDICAL_CONDITIONS
} from "@/apollo/user/medical/user.medical.queries";
import { medicalContactFormatter, singleMedicalContactFormatter } from "@/store/formatters";
import { MEDICAL_CONTACT_MUTATION, CREATE_MEDICAL_CONDITION,EDIT_MEDICAL_CONDITION } from "@/apollo/user/medical/user.medical.mutations";

export default {
    async fetchUserMedicalContacts(state) {
        try {
            const res = await apolloClient.query({ query: GET_USER_MEDICAL_CONTACTS })
            console.log(res.data.me.profile)
            if (res.data.me.profile.profilemedicalinfo === null) return

            state.commit('setUserMedicalContacts', medicalContactFormatter(res.data.me.profile.profilemedicalinfo.medicalContacts.edges))
        } catch (e) {
            console.error(e)
        }
    },

    async fetchMedicalContactRelationshipTypes(state) {

        try {
            const res = await apolloClient.query({ query: GET_MEDICAL_CONTACT_RELATIONSHIP_TYPES })
            state.commit('setMedicalRelationshipTypes', res.data.medicalContactRelationshipType)
        } catch (e) {
            console.error(e)
        }
    },

    async editOrSaveMedicalContact(state, params) {
        // If params object has id then the object is edited
        // If params has no id, but has profileId, then the object is created

        const res = await apolloClient.mutate({
            mutation: MEDICAL_CONTACT_MUTATION,
            variables: { input: params }
        })
        if (res.data.profileMedicalContactMutation.contact !== null) {
            state.commit('pushUserMedicalContact', singleMedicalContactFormatter(res.data.profileMedicalContactMutation.contact))
        }
    },

    async deleteMedicalContact(state, id) {
        try {
            await apolloClient.mutate({
                mutation: MEDICAL_CONTACT_MUTATION,
                variables: { input: { id, delete: true } }
            })
            state.commit('removeUserMedicalContact', id);
        } catch (e) {
            console.error(e)
        }
    },
	async updateMedicalContact(state, input) {
        try {
            const res =  await apolloClient.mutate({
                mutation: EDIT_MEDICAL_CONDITION,
                variables: {   input }
            })
			console.log(res)
            state.commit('setUserMedicalConditions', medicalContactFormatter(res.data.profileMedicalConditionEditMutation.condition));
        } catch (e) {
            console.error(e)
        }
    },
    async fetchUserMedicalConditions(state) {
        try {
            const res = await apolloClient.query({ query: GET_USER_MEDICAL_CONDITIONS })

            if (res.data.me.profile.profilemedicalinfo === null) return
            state.commit('setUserMedicalConditions', medicalContactFormatter(res.data.me.profile.profilemedicalinfo.medicalCondition.edges))
        } catch (e) {
            console.error(e)
        }
    },
    async SaveMedicalCondition(state, params) {
        // If params object has id then the object is edited
        // If params has no id, but has profileId, then the object is created

        const res = await apolloClient.mutate({
            mutation: CREATE_MEDICAL_CONDITION,
            variables: { input: params }
        })
     console.log(res)
        if (res.data.profileMedicalConditionCreateMutation.condition !== null) {
            state.commit('pushUserMedicalCondition', singleMedicalContactFormatter(res.data.profileMedicalConditionCreateMutation.condition))
        }
    },
	async deleteMedicalCondition(state, id) {
        try {
            await apolloClient.mutate({
                mutation: EDIT_MEDICAL_CONDITION,
                variables: { input: { id, delete: true } }
            })
            state.commit('removeUserMedicalCondition', id);
        } catch (e) {
            console.error(e)
        }
    },
}
