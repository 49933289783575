import gql from 'graphql-tag';
import { MediaImageLargeFragment, MediaLogoFragment } from './image.fragments';

export const GET_PROFILE_MEDIA_IMAGES = gql`
    query getProfile($id: String){
        profile(id: $id){
            medialibrary {
                images {
                    edges {
                        node {
                        ...MediaImageLargeFragment
                        }
                    }
                }
            } 
        }
    }${MediaImageLargeFragment}
`

export const GET_IMAGE_BY_UUID = gql`
    query GET_IMAGE_BY_UUID($uuid: String!) {
        mediaImage(uuid: $uuid) {
        ...MediaImageLargeFragment
        }
    }${MediaImageLargeFragment}
`

export const GET_USER_MEDIA_IMAGES = gql`
    query GET_ME {
        me {
            profile {
                medialibrary {
                    images {
                        edges {
                            node {
                            ...MediaImageLargeFragment
                            }
                        }
                    }
                } 
            }
        }
    }${MediaImageLargeFragment}
`

export const GET_USER_ALBUM = gql`
    query GET_ME($albumTitle: String) {
        me {
            profile {
                medialibrary {
                    albums(title: $albumTitle){
                        edges {
                            node {
                                title
                                images(sortBy: "-created") {
                                    edges {
                                        node {
                                        ...MediaImageLargeFragment
                                        }
                                    }
                                }
                            }
                        }
                    }
                } 
            }
        }
    }${MediaImageLargeFragment}
`

export const GET_PROFILE_ALBUM = gql`
    query GET_PROFILE_ALBUM($albumTitle: String, $id: String) {
        profile(id: $id) {
            medialibrary {
                albums(title: $albumTitle){
                    edges {
                        node {
                            title
                            images(sortBy: "-created") {
                                edges {
                                    node {
                                    ...MediaImageLargeFragment
                                    }
                                }
                            }
                        }
                    }
                }
            } 
        }
    }${MediaImageLargeFragment}
`

export const GET_USER_PROFILE_PICTURE = gql`
    query GET_ME {
        me {
            profile {
                medialibrary {
                    placeholder
                    logo {
                    ...MediaLogoFragment
                    }
                }
            }
        }
    }${MediaLogoFragment}
`
