import gql from "graphql-tag";

export const PerformerInformationFragment = gql`
    fragment PerformerInformationFragment on PerformerInformationType {
        id
        hairColour
        hairLength
        hairType
        eyeColour
        voiceType
        bodyType
        piercings
        tattoos
        vocalRangeLowest
        vocalRangeHighest
        appearance
        tattoos
        piercings
        beardLength
        beardColour
        beardType
    }
`
