import { apolloClient } from "@/apollo/apolloClient";
import { GET_USER_AWARDS } from "@/apollo/user/award/user.award.queries";
import {CREATE_USER_AWARD, EDIT_USER_AWARD} from "@/apollo/user/award/user.award.mutations";
import { awardFormatter } from "@/store/formatters";

export default {
    async fetchUserAwards(state) {
        try {
            const res = await apolloClient.query({
                query: GET_USER_AWARDS,
                fetchPolicy: 'no-cache'
            })
			console.log(res)
            state.commit("setUserAwards", awardFormatter(res.data.me.profile.award))
        } catch (e) {
            console.error(e)
        }
    },

    async createUserAward(state, params) {
        try {
            await apolloClient.mutate({
                mutation: CREATE_USER_AWARD,
                variables: { input: params }
            })
        } catch (e) {
            console.error(e)
        }
    },

    async editUserAward(state, params) {
        try {
            await apolloClient.mutate({
                mutation: EDIT_USER_AWARD,
                variables: { input: params }
            })
        } catch (e) {
            console.error(e)
        }
    }
}