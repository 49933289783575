import gql from 'graphql-tag';
import { MediaLogoAndPlaceholderFragment } from '@/apollo/media/fragments'
import { ContactInfoFragment } from '@/apollo/contact_info/contact_info.fragments'

export const VenueFragment = gql`
    fragment VenueFragment on VenueType {
        id
        name
        built
        description
        country
        built
        commissioned
        venueType {
          name
        }
        medialibrary {
        ...MediaLogoAndPlaceholderFragment
        }
        contactinfo{
        ...ContactInfoFragment
        }
    }
    ${ContactInfoFragment}
    ${MediaLogoAndPlaceholderFragment}
`

export const MinimalStorageFragment = gql`
    fragment MinimalStorageFragment on StorageType {
        id
        name
    }
`

// Yes, I know this is not beautiful, this can be replaced with each node having a path list on the
// server side, this is just a temporary solution until that is implemented.
export const RecursiveParentStorageFragment = gql`
    fragment RecursiveParentStorageFragment on StorageType {
        parent {
            ...MinimalStorageFragment
            parent {
                ...MinimalStorageFragment
                parent {
                    ...MinimalStorageFragment
                    parent {
                        ...MinimalStorageFragment
                        parent {
                            ...MinimalStorageFragment
                            parent {
                                ...MinimalStorageFragment
                                parent {
                                    ...MinimalStorageFragment
                                    parent {
                                        ...MinimalStorageFragment
                                        parent {
                                            ...MinimalStorageFragment
                                            parent {
                                                ...MinimalStorageFragment
                                                parent {
                                                    ...MinimalStorageFragment
                                                    parent {
                                                        ...MinimalStorageFragment
                                                        parent {
                                                            ...MinimalStorageFragment
                                                            parent {
                                                                ...MinimalStorageFragment
                                                                parent {
                                                                    ...MinimalStorageFragment
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    ${MinimalStorageFragment}
`

export const StorageFragment = gql`
    fragment StorageFragment on StorageType {
        id
        name
        parent {
            id
            name
        }
        space {
            id
            name
        }
        children {
          edges {
            node {
              id
              name
            }
          }
        }
    }
`

export const SpaceFragment = gql`
    fragment SpaceFragment on SpaceType {
        id
        maxCapacity
        name
        roomNumber
        spaceType
        level
        description
        venue{
          name
        }
        medialibrary{
        ...MediaLogoAndPlaceholderFragment
        }
        storages{
        ...StorageFragment
        }
    }
    ${StorageFragment}
    ${MediaLogoAndPlaceholderFragment}
`


export const VenueDetailedFragment = gql`
    fragment VenueDetailedFragment on VenueType {
    ...VenueFragment
    createdBy{
        profile{
        id
        }
    }
    spaces{
        edges{
            node{
            ...SpaceFragment
          }
        }
      }
    contactinfo {
        ...ContactInfoFragment
    }
    }
    ${VenueFragment}
    ${SpaceFragment}
    ${ContactInfoFragment}
`
