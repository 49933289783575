import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
    state: () => ({
        ethnicity: "",
        religion: "",
        sex: "",
        gender: "",
        sexuality: "",
        caringResponsibilities: [],
        disabilities: []
    }),
    mutations: {
        ...mutations
    },
    actions: {
        ...actions
    },
    getters: {
        ...getters
    }
}
