export default {
    setThing: (state, thing) => state.thing = thing,
    setCategory: (state, category) => {
        const newCategories = { ...state.categories }
        newCategories[category.id] = category
        state.categories = newCategories
    },
    setCategories: (state, categories) => {
        const newCategoryCache = Object.fromEntries(categories.map(cat => [cat.id.toString(), cat]))
        state.categories = { ...state.categories, ...newCategoryCache }
    },
    setCategoryCustomProperties: (state, { id, properties }) => state.categoryCustomProperties[`${id}`] = properties,
    setTopLevelCategoryIds: (state, categoryIds) => state.topLevelCategoryIds = categoryIds,
    setThingStatusOptions: (state, options) => state.statusOptions = options,
    setThingAcquisitionOptions: (state, options) => state.acquisitionOptions = options,

    setImageTags: (state, { hash, tags }) => state.tagCache[hash] = tags,
}
