import {apolloClient} from "@/apollo/apolloClient";
import {CREATE_ADDRESS, EDIT_ADDRESS, SET_ADDRESS_PRIMARY} from "@/apollo/contact_info/contact_info.mutations";
import {singleAddressFormatter} from "@/store/formatters";
import ID_DECODER from '@/utils/ID_DECODER'

export default {
    async saveAddresses(state){
		const addresses = state.getters.getAddresses.filter(item =>item.addressId === undefined ) // Get new addresses with non empty fields
        const oldAddresses = state.getters.getAddresses.filter(item => item.addressId !== undefined)
		console.log(state.getters.getAddresses)
		console.log(addresses)
		console.log('=============')
		console.log(oldAddresses)
        try{
            for(let i=0; i < addresses.length; i++){
                await apolloClient.mutate({
                    mutation: CREATE_ADDRESS,
                    variables: {input: {...addresses[i], profileId:state.getters.getId}}
                })
            }
            for(let i=0; i < oldAddresses.length; i++){
                oldAddresses[i].addressId = oldAddresses[i].id
                delete oldAddresses[i].id
                await apolloClient.mutate({
                    mutation: EDIT_ADDRESS,
                    variables: {input: {...oldAddresses[i]}}
                })
            }
        }catch(err){
            console.log(err)
        }
    },

    async createAddress(state, params){
        try {
            const res = await apolloClient.mutate({
                mutation: CREATE_ADDRESS,
                variables: {input: {...params, profileId:state.getters.getId}}
            })
            state.commit("appendAddress", singleAddressFormatter(res.data.addressCreate.address))
        }  catch (e) {
            console.error(e)
        }
    },

    async deleteAddress(state, id){
        try{
            await apolloClient.mutate({
                mutation: EDIT_ADDRESS,
                variables: {input: {delete: true, addressId: id}}
            })
        } catch(err){
            console.log(err)
        }
    },

    async setAddressPrimary(state, addressIds){
        try {
            const res = await apolloClient.mutate({
                mutation: SET_ADDRESS_PRIMARY,
                variables: {input: { profileId: state.getters.getId, addressIds}}
            })
            const primaryIds = res.data.addressSetPrimaryMutation.addresses.map(addr => ID_DECODER(addr.id))
            state.commit("refreshAddressPrimaries", primaryIds)
        } catch (e) {
            console.error(e)
        }
    }
}
