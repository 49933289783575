export default {
    setPeople(state, data){
        state.people = data
    },
	setSearchPeople(state,data){
		state.searchPeople = data.people
	},
    setProfilePicture: (state, pic) => state.profile.profilePhoto = pic,
    setProfileAlbumTitles: (state, titles) => state.profile.albumTitles = titles,
    pushProfileAlbumByTitle(state, album) {
        state.profile.albums = state.profile.albums.filter(a => a.title !== album.title)
        state.profile.albums.push(album)
    },

    setProfile(state, data){
            state.profile.id = data.id
            state.profile.firstName = data.firstName
            state.profile.representation = data.representation
            state.profile.middleName = data.middleName
            state.profile.surname = data.surname
            state.profile.primaryJob = data.primaryJob
            state.profile.dateOfBirth = data.dateOfBirth
            state.profile.bio = data.bio
            state.profile.profilePhoto = data.profilePhoto
            state.profile.images = data.images
            state.profile.emails = data.emails
            state.profile.phoneNumbers = data.phoneNumbers
            state.profile.addresses = data.addresses
            state.profile.education = data.education
            state.profile.media = data.media
            state.profile.websites = data.websites
            state.profile.socials = data.socials
            state.profile.header = data.header
            state.profile.about = data.about
            state.profile.skills = data.skills
            state.profile.workExperience = data.workExperience
            state.profile.profileProductions = data.profileProductions
            state.profile.enableVcard = data.enableVcard
			state.profile.affiliateCompanies=data.affiliateCompanies
    },
    setPeopleFilters: (state, data) => state.filterOptions = data,
    sortProfileProductions(state, val="name"){
        state.profile.profileProductions.sort((prod1, prod2) => {
            if (prod1[val] === null) return 1
            if (prod2[val] === null) return -1

            if (prod1[val].toLowerCase() > prod2[val].toLowerCase()) return state.sortKey == val ? 1 : -1
            return state.sortKey == val ? -1 : 1
        })

        if (state.sortKey === val) {
            state.sortKey = null;
        } else {
            state.sortKey = val
        }
    },
    setPeopleSortBy(state, data){
        if (data === state.sortBy){
            state.sortBy = `-${data}.`
        } else {
            state.sortBy = data
        }
    },
    
    setProfileHeader: (state, header) => state.profile.header = header,
    setProfileWorkExperience: (state, experience) => state.profile.workExperience = experience,
    setProfileEducation: (state, education) => state.profile.education = education,
    setEnableVcard: (state, b) => state.profile.enableVcard = b,
    setProfileImages: (state, data) => state.profile.images = data,
    setProfileVideos: (state, data) => state.profile.videos = data,
}
