import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
    state: () => ({
        productions: [],
        production: {
            id: null,
            name: '',
            logo:'',
            productionType:'',
            company:'',
            companyType:'',
            role: '',
            director:'',
            venue:'',
            space:'',
            startDate:'',
            endDate:'',
        },
        // event: {
        //     id: null,
        //     title: '',
        //     startTime: '',
        //     endTime: '',
        //     notes: '',
        //     eventType: '',
        //     productionId: null,
        //     venueId: null,
        //     spaceId: null,
        //     public: '',
        // },
        workTypes: [],
       // eventTypes: [],
       // websites: [],
        filters: {
            type: {
                title: 'type',
                options: [],
                value: ''
            },
            country: {
                title: 'country',
                options: [],
                value: ''
            },
            city: {
                title: 'city',
                options: [],
                value: ''
            },
            company: {
                title: 'company',
                options: [],
                value: ''
            },
            venue: {
                title: 'venue',
                options: [],
                value: ''
            },
        },
        sortBy: 'name',

    }),
    mutations: {
        ...mutations
    },
    actions: {
        ...actions
    },
    getters: {
        ...getters
    }
}