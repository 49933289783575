import gql from 'graphql-tag'
import { AwardFragment } from './user.award.fragments'


export const GET_USER_AWARDS = gql`
    query GET_ME {
        me {
            profile {
                award {
                    edges {
                        node {
                        ...AwardFragment
                        }
                    }
                }
            }
        }
    }${AwardFragment}
`
