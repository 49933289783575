import gql from "graphql-tag";
import { UserFragment } from "./user.basic.fragments";

export const GET_USER_PROFILE = gql`
    query GET_ME {
        me {
            profile {
            ...UserFragment
            }
        }
    }${UserFragment}
`;
