export default {
       addUserProfileProduction(state, prod){
            state.profileProductions.push(prod)
       },
        setUserProfileProductions(state, data){
            state.profileProductions = data
        },
        replaceUserProfileProductionById(state, newProd){
             for(let i = 0; i < state.profileProductions.length; i++) {
                    let prod = state.profileProductions[i]
                    if (prod.id === newProd.id){
                        prod.name = newProd.name
                        prod.role = newProd.role
                        prod.productionType = newProd.productionType
                        prod.director = newProd.director
                        prod.company = newProd.company
                        prod.companyType = newProd.companyType
                        prod.venue = newProd.venue
                        prod.space = newProd.space
                        prod.startDate = newProd.startDate
                        prod.endDate = newProd.endDate
                        return;
                    }
             }
        },
        deleteUserProfileProductionById(state, id){
            state.profileProductions = state.profileProductions.filter(p => p.id !== id)
        },

        sortUserProfileProductions(state, val="name"){
            state.profileProductions.sort((prod1, prod2) => {
                if (prod1[val] === null) return 1
                if (prod2[val] === null) return -1

                if (prod1[val].toLowerCase() > prod2[val].toLowerCase()) return state.sortKey == val ? 1 : -1
                return state.sortKey == val ? -1 : 1
            })

            if (state.sortKey === val) {
                state.sortKey = null;
            } else {
                state.sortKey = val
            }
        }
}

