<template>
  <div class="flex flex-col relative h-auto my-6">

    <slot />

    <div v-if="!expanded" class="w-full min-h-60 grid grid-cols-5">
      <CircleCard v-for="(idx) in sliderCount" @click="$emit('onClick', items[idx-1].id)" :key="idx" :img="items[idx-1].image" :title="items[idx-1].title" :subtitle="items[idx-1].subtitle" />
    </div>
    <div v-else class="w-full grid grid-cols-5 min-h-60 flex justify-around flex-wrap items-center">
      <CircleCard v-for="(idx) in items.length" @click="$emit('onClick', items[idx-1].id)" :key="idx" :img="items[idx-1].image" :title="items[idx-1].title" :subtitle="items[idx-1].subtitle" />
    </div>
    <div v-if="expandAvailable" class="flex justify-end">
      <button @click="expanded=!expanded" class="text-xl  text-gray-400">See more</button>
    </div>
  </div>
</template>

<script>
import CircleCard from "@/components/widgets/globalSearchBar/components/CircleCard";
import {computed, ref} from "vue";
export default {
  name: "CardContainer",
  components: {CircleCard},
  props: {
    items: Array
  },
  setup(props){
    const expanded = ref(false)

    return {
      expanded,
      expandAvailable: computed(() => props.items.length > 5),
      sliderCount: computed(() => props.items.length >= 5 ? 5 : props.items.length)
    }
  }
}
</script>
