import {apolloClient} from "@/apollo/apolloClient";
import { ADD_USER_SKILL } from '@/apollo/user/skill/user.skill.mutations'

export default {
    
    async addUserSkill(state, title) {
        try {
            await apolloClient.mutate({
                mutation: ADD_USER_SKILL,
                variables: { input: { title, profileId: state.getters.getId }}
            })              
           state.commit('addUserSkill', title)

        } catch (e) {
            console.error(e)
        }
    },

    async removeUserSkill(state, title) {
            try {
                   await apolloClient.mutate({
                        mutation: ADD_USER_SKILL,
                        variables:  { input: { title, profileId: state.getters.getId, delete:true}}
                   })

                    state.commit('removeUserSkillByTitle', title)
            } catch (e) {
                    console.error(e)
            }
    }

}
