export default {
    getSocials(state){
        return state.socials
    },
    getSocialByIdx: state => idx => {
        return state.socials[idx]
    },
    getUserSocialPrimaryByNetwork: state => network => {
        for (let i = 0; i < state.socials.length; i++) {
            let social = state.socials[i]
            if (social.network === network && social.isPrimary) {
                return social
            }
        }
        return {url:''}
    }
}