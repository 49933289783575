import gql from "graphql-tag";
import { ProgrammeBiogFragment } from "./user.programmeBiog.fragments";

export const GET_USER_PROGRAMME_BIOGS = gql`
    query GET_ME {
        me {
            profile {
                profileprogrammebiog {
                ...ProgrammeBiogFragment
                }
            }
        }
    }${ProgrammeBiogFragment}
`
